import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Loader from "./Loader";
import LabelWithInputField from "./LabelWithInputField";
import ResultStatus from "../classes/ResultStatus";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import ActiveTwilioNumbers from "../classes/ActiveTwilioNumbers";
import PhoneNumberDetails from "../classes/PhoneNumberDetails";
import LeadUtils from "../classes/LeadUtils";
import Result from "../classes/Result";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import ToggleView from "./ToggleView";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import TwiloReleaseWarningView from "./TwiloReleaseWarningView";
import CollectionUtils from "../utils/CollectionUtils";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import ActiveTwilioNumbersRequest from "../models/ActiveTwilioNumbersRequest";
import AssignTwiloNumber from "./AssignTwiloNumber";
import ScrollView2 from "./ScrollView2";
import Company from "../models/Company";
import SubSideMenuButton from "./SubSideMenuButton";
import TwiloWarningView from "./TwiloWarningView";
import LabelDropdown from "./LabelDropdown";
import RPCServices from "../rpc/RPCServices";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ConfiureButtonOnPressed = (d3eState: SettingsPageRefs) => void;

type _SearchButtonOnPressed = (d3eState: SettingsPageRefs) => void;

type _BuyButtonOnPressed = (d3eState: _ForItemState0) => void;

type _ReleaseButtonOnPressed = (d3eState: _UserState) => void;

type _AssignedNumberOnPressed = (d3eState: _ForItemState2) => void;

type _SaveButtonOnPressed = (d3eState: SettingsPageRefs) => void;

type _LabelDropdownOnChanged = (
  text: string,
  d3eState: SettingsPageRefs
) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: SettingsPageRefs
) => void;

type _ConnectToggleOnChanged = (
  text: boolean,
  d3eState: SettingsPageRefs
) => void;

type _EnableMessagingToggleOnChanged = (
  text: boolean,
  d3eState: SettingsPageRefs
) => void;

type _MessageServiceIdOnChanged = (
  text: string,
  d3eState: SettingsPageRefs
) => void;

export interface SettingsPageProps extends BaseUIProps {
  key?: string;
  company: Company;
}
/// To store state data for SettingsPage
class SettingsPageRefs {
  column1ScrollController: ui.ScrollController = new ui.ScrollController();
  column2ScrollController: ui.ScrollController = new ui.ScrollController();
  column4ScrollController: ui.ScrollController = new ui.ScrollController();
  public confiureButton: ConfiureButtonState = new ConfiureButtonState();
  public forItemState0: Map<PhoneNumberDetails, _ForItemState0> = new Map();
  public forItemState2: Map<PhoneNumberDetails, _ForItemState2> = new Map();
  public saveButton: SaveButtonState = new SaveButtonState();
  public searchButton: SearchButtonState = new SearchButtonState();
  public userState: Map<User, _UserState> = new Map();
  public forForItem0(forItem: PhoneNumberDetails): _ForItemState0 {
    let res = this.forItemState0.get(forItem);

    if (res == null) {
      res = new _ForItemState0(this, forItem);

      this.forItemState0.set(forItem, res);
    }

    return res;
  }
  public forForItem2(forItem: PhoneNumberDetails): _ForItemState2 {
    let res = this.forItemState2.get(forItem);

    if (res == null) {
      res = new _ForItemState2(this, forItem);

      this.forItemState2.set(forItem, res);
    }

    return res;
  }
  public forUser(user: User): _UserState {
    let res = this.userState.get(user);

    if (res == null) {
      res = new _UserState(this, user);

      this.userState.set(user, res);
    }

    return res;
  }
}

interface SaveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SettingsPageRefs;
  _saveButtonHandle?: _SaveButtonOnPressed;
}

class SaveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SaveButtonWithState extends ObservableComponent<SaveButtonWithStateProps> {
  saveButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SaveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get saveButton(): SaveButtonState {
    return this.props.d3eState.saveButton;
  }
  public get d3eState(): SettingsPageRefs {
    return this.props.d3eState;
  }
  public get _saveButtonHandle(): _SaveButtonOnPressed {
    return this.props._saveButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("saveButton", null, this.saveButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["saveButton", "saveButton.", "saveButton.hover"], this.rebuild);
  }
  public saveButtonOnEnter(event): void {
    return this.saveButton.setHover(true);
  }
  public saveButtonOnExit(event): void {
    return this.saveButton.setHover(false);
  }
  public dispose(): void {
    this.saveButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 27.0, 0.0, 0.0, new Map()),
      width: 120,
      child: Button({
        padding: this.saveButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.saveButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.saveButton.disable,
        onPressed: () => {
          this._saveButtonHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Save" }),
        onEnter: (event) => {
          this.saveButtonOnEnter(event);
        },
        onExit: (event) => {
          this.saveButtonOnExit(event);
        },
      }),
      className: "xf7c hc",
    });
  }
}
function SaveButtonWithState(props: SaveButtonWithStateProps) {
  return React.createElement(_SaveButtonWithState, props);
}

interface AssignedNumberWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ForItemState2;
  _onAssignedHandle?: _AssignedNumberOnPressed;
}

class AssignedNumberState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AssignedNumberWithState extends ObservableComponent<AssignedNumberWithStateProps> {
  assignedNumberFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AssignedNumberWithStateProps) {
    super(props);

    this.initState();
  }
  public get assignedNumber(): AssignedNumberState {
    return this.props.d3eState.assignedNumber;
  }
  public get d3eState(): _ForItemState2 {
    return this.props.d3eState;
  }
  public get _onAssignedHandle(): _AssignedNumberOnPressed {
    return this.props._onAssignedHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("assignedNumber", null, this.assignedNumber);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["assignedNumber", "assignedNumber.", "assignedNumber.hover"],
      this.rebuild
    );
  }
  public assignedNumberOnEnter(event): void {
    return this.assignedNumber.setHover(true);
  }
  public assignedNumberOnExit(event): void {
    return this.assignedNumber.setHover(false);
  }
  public dispose(): void {
    this.assignedNumber.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.assignedNumber.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.assignedNumber.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.assignedNumber.disable,
        onPressed: () => {
          this._onAssignedHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Assign", className: "hc" }),
        onEnter: (event) => {
          this.assignedNumberOnEnter(event);
        },
        onExit: (event) => {
          this.assignedNumberOnExit(event);
        },
      }),
      className: "x931 hc",
    });
  }
}
function AssignedNumberWithState(props: AssignedNumberWithStateProps) {
  return React.createElement(_AssignedNumberWithState, props);
}

interface ReleaseButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _UserState;
  _releaseButtonHandle?: _ReleaseButtonOnPressed;
}

class ReleaseButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ReleaseButtonWithState extends ObservableComponent<ReleaseButtonWithStateProps> {
  releaseButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: ReleaseButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get releaseButton(): ReleaseButtonState {
    return this.props.d3eState.releaseButton;
  }
  public get d3eState(): _UserState {
    return this.props.d3eState;
  }
  public get _releaseButtonHandle(): _ReleaseButtonOnPressed {
    return this.props._releaseButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("releaseButton", null, this.releaseButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["releaseButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      padding: ui.EdgeInsets.zero,
      disable: this.releaseButton.disable,
      onPressed: () => {
        this._releaseButtonHandle(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "Release", className: "hc" }),
      className: "hc",
    });
  }
}
function ReleaseButtonWithState(props: ReleaseButtonWithStateProps) {
  return React.createElement(_ReleaseButtonWithState, props);
}

interface BuyButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ForItemState0;
  _buyButtonHandle?: _BuyButtonOnPressed;
}

class BuyButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _BuyButtonWithState extends ObservableComponent<BuyButtonWithStateProps> {
  buyButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: BuyButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get buyButton(): BuyButtonState {
    return this.props.d3eState.buyButton;
  }
  public get d3eState(): _ForItemState0 {
    return this.props.d3eState;
  }
  public get _buyButtonHandle(): _BuyButtonOnPressed {
    return this.props._buyButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("buyButton", null, this.buyButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["buyButton", "buyButton.", "buyButton.hover"], this.rebuild);
  }
  public buyButtonOnEnter(event): void {
    return this.buyButton.setHover(true);
  }
  public buyButtonOnExit(event): void {
    return this.buyButton.setHover(false);
  }
  public dispose(): void {
    this.buyButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.buyButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.buyButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.buyButton.disable,
        onPressed: () => {
          this._buyButtonHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Buy", className: "hc" }),
        onEnter: (event) => {
          this.buyButtonOnEnter(event);
        },
        onExit: (event) => {
          this.buyButtonOnExit(event);
        },
      }),
      className: "x28c6 hc",
    });
  }
}
function BuyButtonWithState(props: BuyButtonWithStateProps) {
  return React.createElement(_BuyButtonWithState, props);
}

interface SearchButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SettingsPageRefs;
  _searchButtonHandle?: _SearchButtonOnPressed;
}

class SearchButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SearchButtonWithState extends ObservableComponent<SearchButtonWithStateProps> {
  searchButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get searchButton(): SearchButtonState {
    return this.props.d3eState.searchButton;
  }
  public get d3eState(): SettingsPageRefs {
    return this.props.d3eState;
  }
  public get _searchButtonHandle(): _SearchButtonOnPressed {
    return this.props._searchButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("searchButton", null, this.searchButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["searchButton", "searchButton.", "searchButton.hover"],
      this.rebuild
    );
  }
  public searchButtonOnEnter(event): void {
    return this.searchButton.setHover(true);
  }
  public searchButtonOnExit(event): void {
    return this.searchButton.setHover(false);
  }
  public dispose(): void {
    this.searchButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      margin: ui.EdgeInsets.fromLTRB(5.0, 31.0, 0.0, 0.0, new Map()),
      width: 120,
      child: Button({
        padding: this.searchButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.searchButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.searchButton.disable,
        onPressed: () => {
          this._searchButtonHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Search", className: "hc" }),
        onEnter: (event) => {
          this.searchButtonOnEnter(event);
        },
        onExit: (event) => {
          this.searchButtonOnExit(event);
        },
      }),
      className: "x201 hc",
    });
  }
}
function SearchButtonWithState(props: SearchButtonWithStateProps) {
  return React.createElement(_SearchButtonWithState, props);
}

interface ConfiureButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SettingsPageRefs;
  _confiureButtonHandle?: _ConfiureButtonOnPressed;
  isConfigured: boolean;
}

class ConfiureButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ConfiureButtonWithState extends ObservableComponent<ConfiureButtonWithStateProps> {
  confiureButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ConfiureButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get confiureButton(): ConfiureButtonState {
    return this.props.d3eState.confiureButton;
  }
  public get isConfigured(): boolean {
    return this.props.isConfigured;
  }
  public get d3eState(): SettingsPageRefs {
    return this.props.d3eState;
  }
  public get _confiureButtonHandle(): _ConfiureButtonOnPressed {
    return this.props._confiureButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("confiureButton", null, this.confiureButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["confiureButton", "confiureButton.hover", "isConfigured"],
      this.rebuild
    );
  }
  public confiureButtonOnEnter(event): void {
    return this.confiureButton.setHover(true);
  }
  public confiureButtonOnExit(event): void {
    return this.confiureButton.setHover(false);
  }
  public dispose(): void {
    this.confiureButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 20.0, new Map()),
      width: Number.POSITIVE_INFINITY,
      child: Button({
        padding: this.confiureButton.hover
          ? ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 7.0,
              transitions: new Map(),
            })
          : this.isConfigured
          ? ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 7.0,
              transitions: new Map(),
            })
          : ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 7.0,
              transitions: new Map(),
            }),
        decoration: this.confiureButton.hover
          ? new ui.BoxDecoration({
              color: new ui.Color(0xff616161),
              borderRadius: ui.BorderRadius.circular(3.0),
            })
          : this.isConfigured
          ? new ui.BoxDecoration({
              color: cStyle.c1,
              borderRadius: ui.BorderRadius.circular(3.0),
            })
          : new ui.BoxDecoration({
              color: new ui.Color(0xff000000),
              borderRadius: ui.BorderRadius.circular(3.0),
            }),
        disable: this.confiureButton.disable,
        onPressed: () => {
          this._confiureButtonHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        states: ui.joinStates(
          {
            "data-c0": this.confiureButton.hover,
            "data-c1": this.isConfigured,
          },
          {}
        ),
        child: ui.Row({
          mainAxisSize: ui.MainAxisSize.max,
          children: [
            TextView({
              data: "Confiuration",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w500 }),
              className: "x353",
              key: "0",
            }),
          ],
          className: "xe06 hc h",
        }),
        onEnter: (event) => {
          this.confiureButtonOnEnter(event);
        },
        onExit: (event) => {
          this.confiureButtonOnExit(event);
        },
      }),
      className: "xe89 hc h",
      states: ui.joinStates(
        { "data-c0": this.confiureButton.hover, "data-c1": this.isConfigured },
        {}
      ),
    });
  }
}
function ConfiureButtonWithState(props: ConfiureButtonWithStateProps) {
  return React.createElement(_ConfiureButtonWithState, props);
}

class _ForItemState0 {
  parent: SettingsPageRefs;
  forItem: PhoneNumberDetails;
  buyButton: BuyButtonState = new BuyButtonState();
  public constructor(parent, forItem) {
    this.parent = parent;

    this.forItem = forItem;
  }
}

class _UserState {
  parent: SettingsPageRefs;
  user: User;
  releaseButton: ReleaseButtonState = new ReleaseButtonState();
  public constructor(parent, user) {
    this.parent = parent;

    this.user = user;
  }
}

class _ForItemState2 {
  parent: SettingsPageRefs;
  forItem: PhoneNumberDetails;
  assignedNumber: AssignedNumberState = new AssignedNumberState();
  public constructor(parent, forItem) {
    this.parent = parent;

    this.forItem = forItem;
  }
}

class _SettingsPageState extends ObservableComponent<SettingsPageProps> {
  static defaultProps = { company: null };
  d3eState: SettingsPageRefs = new SettingsPageRefs();
  showBuyNumber: boolean = true;
  showActiveNumber: boolean = false;
  showUnConfiguredNumber: boolean = false;
  searchNumbers: Array<PhoneNumberDetails> = ListWrapper.widget(
    this,
    "searchNumbers"
  );
  countryWithCode: string = "";
  activeNumbers: Array<PhoneNumberDetails> = ListWrapper.widget(
    this,
    "activeNumbers"
  );
  unconfiguredNumbers: Array<PhoneNumberDetails> = ListWrapper.widget(
    this,
    "unconfiguredNumbers"
  );
  selectedMenu: string = "Buy Number";
  request: ActiveTwilioNumbersRequest = null;
  query: ActiveTwilioNumbers = null;
  activeNumbersUsers: Array<User> = ListWrapper.widget(
    this,
    "activeNumbersUsers"
  );
  isConfigured: boolean = false;
  uiConfiguration: boolean = false;
  phoneNumberObj: PhoneNumberDetails = null;
  phoneNumberDetailsObj: PhoneNumberDetails = null;
  showLoading: boolean = false;
  showListEmpty: boolean = false;
  releasingUser: User = null;
  searchBy: string = "";
  releaseWarningViewPopup: Popup;
  twiloWarningViewPopup: Popup;
  assignTwiloNumberPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SettingsPageProps) {
    super(props);

    this.initState();
  }
  public get company(): Company {
    return this.props.company;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("company", this.props.company);

    this.on(
      [
        "request",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
      ],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computeActiveNumbersUsers);

    this.computeActiveNumbersUsers();

    this.on(
      [
        "activeNumbersUsers",
        "activeNumbersUsers.firstName",
        "activeNumbersUsers.lastName",
        "activeNumbersUsers.twilioNumber",
        "company",
        "company.enableMessaging",
        "company.messageServiceId",
        "countryWithCode",
        "isConfigured",
        "searchBy",
        "searchNumbers",
        "searchNumbers.areaCode",
        "searchNumbers.countryCode",
        "searchNumbers.locality",
        "searchNumbers.monthlyPrice",
        "searchNumbers.phoneNumber",
        "selectedMenu",
        "showActiveNumber",
        "showBuyNumber",
        "showListEmpty",
        "showLoading",
        "showUnConfiguredNumber",
        "uiConfiguration",
        "unconfiguredNumbers",
        "unconfiguredNumbers.phoneNumber",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SettingsPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.company !== this.props.company) {
      this.updateObservable("company", prevProps.company, this.props.company);

      this.fire("company", this);
    }
  }
  public setShowBuyNumber(val: boolean): void {
    let isValChanged: boolean = this.showBuyNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.showBuyNumber = val;

    this.fire("showBuyNumber", this);
  }
  public setShowActiveNumber(val: boolean): void {
    let isValChanged: boolean = this.showActiveNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.showActiveNumber = val;

    this.fire("showActiveNumber", this);
  }
  public setShowUnConfiguredNumber(val: boolean): void {
    let isValChanged: boolean = this.showUnConfiguredNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.showUnConfiguredNumber = val;

    this.fire("showUnConfiguredNumber", this);
  }
  public setSearchNumbers(val: Array<PhoneNumberDetails>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.searchNumbers,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("searchNumbers", this.searchNumbers, val);

    this.searchNumbers.clear();

    this.searchNumbers.addAll(val);

    this.fire("searchNumbers", this);
  }
  public addToSearchNumbers(val: PhoneNumberDetails, index: number = -1): void {
    if (index === -1) {
      if (!this.searchNumbers.contains(val)) this.searchNumbers.add(val);
    } else {
      this.searchNumbers.remove(this.searchNumbers.elementAt(index));

      this.searchNumbers.add(val);
    }

    this.fire("searchNumbers", this, val, true);

    this.updateObservable("searchNumbers", null, val);
  }
  public removeFromSearchNumbers(val: PhoneNumberDetails): void {
    this.searchNumbers.remove(val);

    this.fire("searchNumbers", this, val, false);

    this.removeObservable("searchNumbers", val);
  }
  public setCountryWithCode(val: string): void {
    let isValChanged: boolean = this.countryWithCode !== val;

    if (!isValChanged) {
      return;
    }

    this.countryWithCode = val;

    this.fire("countryWithCode", this);
  }
  public setActiveNumbers(val: Array<PhoneNumberDetails>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.activeNumbers,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("activeNumbers", this.activeNumbers, val);

    this.activeNumbers.clear();

    this.activeNumbers.addAll(val);

    this.fire("activeNumbers", this);
  }
  public addToActiveNumbers(val: PhoneNumberDetails, index: number = -1): void {
    if (index === -1) {
      if (!this.activeNumbers.contains(val)) this.activeNumbers.add(val);
    } else {
      this.activeNumbers.remove(this.activeNumbers.elementAt(index));

      this.activeNumbers.add(val);
    }

    this.fire("activeNumbers", this, val, true);

    this.updateObservable("activeNumbers", null, val);
  }
  public removeFromActiveNumbers(val: PhoneNumberDetails): void {
    this.activeNumbers.remove(val);

    this.fire("activeNumbers", this, val, false);

    this.removeObservable("activeNumbers", val);
  }
  public setUnconfiguredNumbers(val: Array<PhoneNumberDetails>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.unconfiguredNumbers,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl(
      "unconfiguredNumbers",
      this.unconfiguredNumbers,
      val
    );

    this.unconfiguredNumbers.clear();

    this.unconfiguredNumbers.addAll(val);

    this.fire("unconfiguredNumbers", this);
  }
  public addToUnconfiguredNumbers(
    val: PhoneNumberDetails,
    index: number = -1
  ): void {
    if (index === -1) {
      if (!this.unconfiguredNumbers.contains(val))
        this.unconfiguredNumbers.add(val);
    } else {
      this.unconfiguredNumbers.remove(
        this.unconfiguredNumbers.elementAt(index)
      );

      this.unconfiguredNumbers.add(val);
    }

    this.fire("unconfiguredNumbers", this, val, true);

    this.updateObservable("unconfiguredNumbers", null, val);
  }
  public removeFromUnconfiguredNumbers(val: PhoneNumberDetails): void {
    this.unconfiguredNumbers.remove(val);

    this.fire("unconfiguredNumbers", this, val, false);

    this.removeObservable("unconfiguredNumbers", val);
  }
  public setSelectedMenu(val: string): void {
    let isValChanged: boolean = this.selectedMenu !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedMenu = val;

    this.fire("selectedMenu", this);
  }
  public setRequest(val: ActiveTwilioNumbersRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setQuery(val: ActiveTwilioNumbers): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getActiveTwilioNumbers(
          UsageConstants.QUERY_GETACTIVETWILIONUMBERS_SETTINGSPAGE_PROPERTIES_QUERY_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setActiveNumbersUsers(val: Array<User>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.activeNumbersUsers,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl(
      "activeNumbersUsers",
      this.activeNumbersUsers,
      val
    );

    this.activeNumbersUsers.clear();

    this.activeNumbersUsers.addAll(val);

    this.fire("activeNumbersUsers", this);
  }
  public addToActiveNumbersUsers(val: User, index: number = -1): void {
    if (index === -1) {
      if (!this.activeNumbersUsers.contains(val))
        this.activeNumbersUsers.add(val);
    } else {
      this.activeNumbersUsers.remove(this.activeNumbersUsers.elementAt(index));

      this.activeNumbersUsers.add(val);
    }

    this.fire("activeNumbersUsers", this, val, true);

    this.updateObservable("activeNumbersUsers", null, val);
  }
  public removeFromActiveNumbersUsers(val: User): void {
    this.activeNumbersUsers.remove(val);

    this.fire("activeNumbersUsers", this, val, false);

    this.removeObservable("activeNumbersUsers", val);
  }
  public computeActiveNumbersUsers = (): void => {
    try {
      this.setActiveNumbersUsers(Array.from(this.query?.items ?? []));
    } catch (exception) {
      console.log(
        " exception in computeActiveNumbersUsers : " + exception.toString()
      );

      this.setActiveNumbersUsers([]);
    }
  };
  public setIsConfigured(val: boolean): void {
    let isValChanged: boolean = this.isConfigured !== val;

    if (!isValChanged) {
      return;
    }

    this.isConfigured = val;

    this.fire("isConfigured", this);
  }
  public setUiConfiguration(val: boolean): void {
    let isValChanged: boolean = this.uiConfiguration !== val;

    if (!isValChanged) {
      return;
    }

    this.uiConfiguration = val;

    this.fire("uiConfiguration", this);
  }
  public setPhoneNumberObj(val: PhoneNumberDetails): void {
    let isValChanged: boolean = this.phoneNumberObj !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("phoneNumberObj", this.phoneNumberObj, val);

    this.phoneNumberObj = val;

    this.fire("phoneNumberObj", this);
  }
  public setPhoneNumberDetailsObj(val: PhoneNumberDetails): void {
    let isValChanged: boolean = this.phoneNumberDetailsObj !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable(
      "phoneNumberDetailsObj",
      this.phoneNumberDetailsObj,
      val
    );

    this.phoneNumberDetailsObj = val;

    this.fire("phoneNumberDetailsObj", this);
  }
  public setShowLoading(val: boolean): void {
    let isValChanged: boolean = this.showLoading !== val;

    if (!isValChanged) {
      return;
    }

    this.showLoading = val;

    this.fire("showLoading", this);
  }
  public setShowListEmpty(val: boolean): void {
    let isValChanged: boolean = this.showListEmpty !== val;

    if (!isValChanged) {
      return;
    }

    this.showListEmpty = val;

    this.fire("showListEmpty", this);
  }
  public setReleasingUser(val: User): void {
    let isValChanged: boolean = this.releasingUser !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("releasingUser", this.releasingUser, val);

    this.releasingUser = val;

    this.fire("releasingUser", this);
  }
  public setSearchBy(val: string): void {
    let isValChanged: boolean = this.searchBy !== val;

    if (!isValChanged) {
      return;
    }

    this.searchBy = val;

    this.fire("searchBy", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    TextView({
                      data: "Twilo Settings",
                      style: new ui.TextStyle({
                        color: cStyle.c14,
                        fontSize: cStyle.tTextViewHeadlineSixFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineSixFontWeightOn,
                      }),
                      className: "x4c35",
                      key: "0",
                    }),
                    IconView({
                      icon: MaterialIcons.settings,
                      color: cStyle.c14,
                      size: 20,
                      className: "x7604",
                      key: "1",
                    }),
                  ],
                  className: "xe8e hc h",
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 5.0, new Map()),
                  child: SubSideMenuButton({
                    title: "Buy Number",
                    selectedMenu: this.selectedMenu,
                    onPressed: () => {
                      this.buyNumberButtonHandle(this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "x365 hc h",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 0.0,
                    vertical: 5.0,
                    transitions: new Map(),
                  }),
                  child: SubSideMenuButton({
                    title: "Active Numbers",
                    selectedMenu: this.selectedMenu,
                    onPressed: () => {
                      this.activePhoneButtonHandle(this.d3eState);
                    },
                  }),
                  key: "2",
                  className: "x383 hc h",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 0.0,
                    vertical: 5.0,
                    transitions: new Map(),
                  }),
                  child: SubSideMenuButton({
                    title: "UnConfigure Numbers",
                    selectedMenu: this.selectedMenu,
                    onPressed: () => {
                      this.unActivePhoneButtonHandle(this.d3eState);
                    },
                  }),
                  key: "3",
                  className: "xc1c hc h",
                }),
              ],
              className: "xad2 hc vc h v",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                ConfiureButtonWithState({
                  d3eState: this.d3eState,
                  _confiureButtonHandle: this.confiureButtonHandle,
                  isConfigured: this.isConfigured,
                  key: "0",
                }),
              ],
              className: "x8b5 hc h",
              key: "1",
            }),
          ],
          className: "xf8e hc vc v",
          key: "0",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            this.showBuyNumber
              ? ScrollView2({
                  child: ui.Column({
                    mainAxisAlignment: ui.MainAxisAlignment.start,
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.center,
                        crossAxisAlignment: ui.CrossAxisAlignment.center,
                        children: [
                          ui.Container({
                            expand: true,
                            child: LabelDropdown<string>({
                              name: "Country",
                              placeHolder: "---Select Country---",
                              value: this.countryWithCode,
                              items: LeadUtils.getMobileCodeWithCountry(),
                              onChanged: (text) => {
                                this.labelDropdownonChanged(
                                  text,
                                  this.d3eState
                                );
                              },
                              builder: (context, item) => {
                                return TextView({ data: item });
                              },
                            }),
                            key: "0",
                            className: "x833 hc h",
                          }),
                          ui.Container({
                            expand: true,
                            margin: ui.EdgeInsets.fromLTRB(
                              5.0,
                              0.0,
                              0.0,
                              0.0,
                              new Map()
                            ),
                            child: LabelWithInputField({
                              name: "Search by phrases",
                              placeHolder: "Search by digits or phrases",
                              value: this.searchBy,
                              onChanged: (text) => {
                                this.labelWithInputFieldonChanged(
                                  text,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "x4bd hc h",
                          }),
                          SearchButtonWithState({
                            d3eState: this.d3eState,
                            _searchButtonHandle: this.searchButtonHandle,
                            key: "2",
                          }),
                        ],
                        className: "x839 hc",
                        key: "0",
                      }),
                      ui.Table({
                        defaultColumnWidth: new ui.FlexColumnWidth(1),
                        columnWidths: {
                          0: new ui.FlexColumnWidth(1),
                          1: new ui.FlexColumnWidth(1),
                          2: new ui.FlexColumnWidth(1),
                          3: new ui.FlexColumnWidth(1),
                        },
                        children: [
                          ui.TableRow({
                            children: [
                              ui.TableCell({
                                child: TextView({
                                  data: "Number",
                                  className: "hc",
                                }),
                                className: "x581 hc",
                                key: "0",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Locality",
                                  className: "hc",
                                }),
                                className: "x9b3 hc",
                                key: "1",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Amount",
                                  className: "hc",
                                }),
                                className: "xea2 hc",
                                key: "2",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Buy",
                                  className: "xdd0a hc",
                                }),
                                className: "x054 hc",
                                key: "3",
                              }),
                            ],
                            className: "x3235 hc",
                            key: "0",
                          }),
                          this.searchNumbers !== null &&
                          this.searchNumbers.isNotEmpty
                            ? this.searchNumbers.expand((forItem) => [
                                ui.TableRow({
                                  children: [
                                    ui.TableCell({
                                      verticalAlignment:
                                        ui.TableCellVerticalAlignment.middle,
                                      child: TextView({
                                        data: forItem.phoneNumber,
                                        className: "hc",
                                      }),
                                      className: "hc",
                                      key: "0",
                                    }),
                                    ui.TableCell({
                                      verticalAlignment:
                                        ui.TableCellVerticalAlignment.middle,
                                      child: TextView({
                                        data:
                                          (forItem?.locality ?? "") +
                                          ", " +
                                          (forItem?.areaCode ?? "") +
                                          " " +
                                          (forItem?.countryCode ?? ""),
                                        className: "hc",
                                      }),
                                      className: "hc",
                                      key: "1",
                                    }),
                                    ui.TableCell({
                                      verticalAlignment:
                                        ui.TableCellVerticalAlignment.middle,
                                      child: TextView({
                                        data: "$ " + forItem.monthlyPrice,
                                        className: "hc",
                                      }),
                                      className: "hc",
                                      key: "2",
                                    }),
                                    ui.TableCell({
                                      verticalAlignment:
                                        ui.TableCellVerticalAlignment.middle,
                                      child: BuyButtonWithState({
                                        d3eState:
                                          this.d3eState.forForItem0(forItem),
                                        _buyButtonHandle: this.buyButtonHandle,
                                      }),
                                      className: "hc",
                                      key: "3",
                                    }),
                                  ],
                                  className: "xab70 hc",
                                  key: forItem?.toString(),
                                }),
                              ])
                            : [],
                        ],
                        className: "x8904 hc h",
                        key: "1",
                      }),
                      this.showListEmpty && !this.showLoading
                        ? ui.Center({
                            child: TextView({
                              data: "No Numbers Found",
                              textAlign: ui.TextAlign.center,
                              style: new ui.TextStyle({
                                color: new ui.Color(0xff343434),
                              }),
                              className: "xdcd hc vc",
                            }),
                            className: "x98e hc vc v",
                          })
                        : [],
                      this.showLoading
                        ? ui.Center({
                            child: Loader({ className: "hc vc" }),
                            className: "x7fa hc vc v",
                          })
                        : [],
                    ],
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "xd95 hc vc h v",
                  controller: this.d3eState.column1ScrollController,
                })
              : [],
            this.showActiveNumber
              ? ScrollView2({
                  child: ui.Column({
                    mainAxisAlignment: ui.MainAxisAlignment.start,
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      ui.Table({
                        defaultColumnWidth: new ui.FlexColumnWidth(1),
                        columnWidths: {
                          0: new ui.FlexColumnWidth(1),
                          1: new ui.FlexColumnWidth(1),
                          2: new ui.FlexColumnWidth(1),
                          3: new ui.FlexColumnWidth(1),
                        },
                        children: [
                          ui.TableRow({
                            children: [
                              ui.TableCell({
                                child: TextView({
                                  data: "Name",
                                  className: "hc",
                                }),
                                className: "x86b hc",
                                key: "0",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Twilo Number",
                                  className: "hc",
                                }),
                                className: "xfa2 hc",
                                key: "1",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Release Number",
                                  className: "hc",
                                }),
                                className: "xcc22 hc",
                                key: "2",
                              }),
                            ],
                            className: "x4cf hc",
                            key: "0",
                          }),
                          this.activeNumbersUsers
                            .where(
                              (u) =>
                                u.twilioNumber !== null && u.twilioNumber !== ""
                            )
                            .expand((user) => [
                              ui.TableRow({
                                children: [
                                  ui.TableCell({
                                    verticalAlignment:
                                      ui.TableCellVerticalAlignment.middle,
                                    child: TextView({
                                      data:
                                        user.firstName +
                                        " " +
                                        (user?.lastName ?? ""),
                                      className: "hc",
                                    }),
                                    className: "hc",
                                    key: "0",
                                  }),
                                  ui.TableCell({
                                    verticalAlignment:
                                      ui.TableCellVerticalAlignment.middle,
                                    child: TextView({
                                      data: user.twilioNumber,
                                      className: "hc",
                                    }),
                                    className: "hc",
                                    key: "1",
                                  }),
                                  ui.TableCell({
                                    verticalAlignment:
                                      ui.TableCellVerticalAlignment.middle,
                                    child: ReleaseButtonWithState({
                                      d3eState: this.d3eState.forUser(user),
                                      _releaseButtonHandle:
                                        this.releaseButtonHandle,
                                    }),
                                    className: "hc",
                                    key: "2",
                                  }),
                                ],
                                className: "x8c0 hc",
                                key: user?.ident,
                              }),
                            ]),
                        ],
                        className: "x0010 hc h",
                        key: "0",
                      }),
                    ],
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "x396e hc vc h v",
                  controller: this.d3eState.column2ScrollController,
                })
              : [],
            this.showUnConfiguredNumber
              ? ScrollView2({
                  child: ui.Column({
                    mainAxisAlignment: ui.MainAxisAlignment.start,
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      ui.Table({
                        defaultColumnWidth: new ui.FlexColumnWidth(1),
                        columnWidths: {
                          0: new ui.FlexColumnWidth(1),
                          1: new ui.FlexColumnWidth(1),
                        },
                        children: [
                          ui.TableRow({
                            children: [
                              ui.TableCell({
                                child: TextView({
                                  data: "Number",
                                  className: "hc",
                                }),
                                className: "x6d7a hc",
                                key: "0",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Assign Number",
                                  className: "hc",
                                }),
                                className: "x1e5 hc",
                                key: "1",
                              }),
                            ],
                            className: "x5cd hc",
                            key: "0",
                          }),
                          this.unconfiguredNumbers.expand((forItem) => [
                            ui.TableRow({
                              children: [
                                ui.TableCell({
                                  verticalAlignment:
                                    ui.TableCellVerticalAlignment.middle,
                                  child: TextView({
                                    data: forItem.phoneNumber,
                                    className: "hc",
                                  }),
                                  className: "hc",
                                  key: "0",
                                }),
                                ui.TableCell({
                                  verticalAlignment:
                                    ui.TableCellVerticalAlignment.middle,
                                  child: AssignedNumberWithState({
                                    d3eState:
                                      this.d3eState.forForItem2(forItem),
                                    _onAssignedHandle: this.onAssignedHandle,
                                  }),
                                  className: "hc",
                                  key: "1",
                                }),
                              ],
                              className: "xaa4 hc",
                              key: forItem?.toString(),
                            }),
                          ]),
                        ],
                        className: "x0b05 hc h",
                        key: "0",
                      }),
                    ],
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "x0fa0 hc vc h v",
                  controller: this.d3eState.column4ScrollController,
                })
              : [],
            this.isConfigured
              ? ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      children: [
                        TextView({
                          data: "Do you want handle like lead service?",
                          key: "0",
                        }),
                        ToggleView({
                          value: this.uiConfiguration,
                          onChanged: (text) => {
                            this.connectToggleonChanged(text, this.d3eState);
                          },
                          key: "1",
                        }),
                      ],
                      className: "xc6d hc",
                      key: "0",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      children: [
                        TextView({
                          data: "Do you want Enable Messaging Service?",
                          key: "0",
                        }),
                        ToggleView({
                          value: this.company.enableMessaging,
                          onChanged: (text) => {
                            this.enableMessagingToggleonChanged(
                              text,
                              this.d3eState
                            );
                          },
                          key: "1",
                        }),
                      ],
                      className: "x4b3 hc",
                      key: "1",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      children: [
                        ui.Container({
                          expand: true,
                          child: LabelWithInputField({
                            name: "Message Service Id",
                            placeHolder: "Enter Email Address",
                            value: this.company.messageServiceId,
                            onChanged: (text) => {
                              this.messageServiceIdonChanged(
                                text,
                                this.d3eState
                              );
                            },
                          }),
                          key: "0",
                          className: "x71e hc h",
                        }),
                      ],
                      className: "x3e8b hc",
                      key: "2",
                    }),
                    TextView({
                      data: "Note: If you remove the message service id, then the messaging service will be disabled.",
                      style: new ui.TextStyle({ fontSize: 11 }),
                      className: "xd27 hc",
                      key: "3",
                    }),
                    ui.Row({
                      children: [
                        SaveButtonWithState({
                          d3eState: this.d3eState,
                          _saveButtonHandle: this.saveButtonHandle,
                          key: "0",
                        }),
                      ],
                      className: "x2734 hc h",
                      key: "4",
                    }),
                  ],
                  className: "xe33a hc h",
                })
              : [],
          ],
          className: "x8a17 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "SettingsPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setUiConfiguration(this.company.isLeadService);

    this.setRequest(
      new ActiveTwilioNumbersRequest({
        pageSize: 100,
        offset: 0,
        ascending: true,
      })
    );
  };
  public buyNumberButtonHandle = (d3eState: SettingsPageRefs): void => {
    this.setShowBuyNumber(true);

    this.setShowActiveNumber(false);

    this.setShowUnConfiguredNumber(false);

    this.setIsConfigured(false);

    this.setSelectedMenu("Buy Number");
  };
  public activePhoneButtonHandle = async (
    d3eState: SettingsPageRefs
  ): Promise<void> => {
    this.setShowBuyNumber(false);

    this.setShowActiveNumber(true);

    this.setShowUnConfiguredNumber(false);

    this.setIsConfigured(false);

    this.setSelectedMenu("Active Numbers");

    this.setActiveNumbers(
      await RPCServices.getCallService().getUnconfiguredNumbers()
    );
  };
  public confiureButtonHandle = (d3eState: SettingsPageRefs): void => {
    this.setShowBuyNumber(false);

    this.setShowActiveNumber(false);

    this.setShowUnConfiguredNumber(false);

    this.setIsConfigured(true);

    this.setSelectedMenu("Configure");
  };
  public unActivePhoneButtonHandle = async (
    d3eState: SettingsPageRefs
  ): Promise<void> => {
    this.setShowBuyNumber(false);

    this.setShowActiveNumber(false);

    this.setShowUnConfiguredNumber(true);

    this.setIsConfigured(false);

    this.setSelectedMenu("UnConfigure Numbers");

    this.setUnconfiguredNumbers(
      await RPCServices.getCallService().getUnconfiguredNumbers()
    );
  };
  public buyButtonHandle = (d3eState: _ForItemState0): void => {
    this.setPhoneNumberObj(d3eState.forItem);

    this.showTwiloWarningView();
  };
  public onAssignedHandle = (d3eState: _ForItemState2): void => {
    this.setPhoneNumberDetailsObj(d3eState.forItem);

    this.showAssignTwiloNumber();
  };
  public searchButtonHandle = async (
    d3eState: SettingsPageRefs
  ): Promise<void> => {
    this.setShowLoading(true);

    this.setSearchNumbers(
      await RPCServices.getCallService().searchAvailableNumbers(
        this.countryWithCode,
        this.searchBy
      )
    );

    if (this.searchNumbers.isNotEmpty) {
      this.setShowListEmpty(false);
    } else {
      this.setShowListEmpty(true);
    }

    this.setShowLoading(false);
  };
  public releaseButtonHandle = (d3eState: _UserState): void => {
    this.setReleasingUser(d3eState.user);

    this.showReleaseWarningView();
  };
  public saveButtonHandle = async (
    d3eState: SettingsPageRefs
  ): Promise<void> => {
    this.company.setIsLeadService(this.uiConfiguration);

    let result: Result<Company> = await this.company.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: " Updated successfully" })
      );
    } else {
      this.company.setIsLeadService(!this.uiConfiguration);

      EventBus.get().fire(new FailureMessage({ message: "Updated failed" }));
    }
  };
  public onNumberAssignSuccess = async (): Promise<void> => {
    this.setUnconfiguredNumbers(
      await RPCServices.getCallService().getUnconfiguredNumbers()
    );
  };
  public onAssignNumberToOtherUser = async (): Promise<void> => {
    let releaseUser: boolean =
      await RPCServices.getCallService().removeCallBacks(
        this.releasingUser.twilioNumber
      );

    if (releaseUser) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Removed Number  successfully" })
      );
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Remove Number failed" })
      );
    }

    this.hideReleaseWarningView();
  };
  public onReleaseNumber = async (): Promise<void> => {
    let releaseNumber: boolean =
      await RPCServices.getCallService().releaseNumber(
        this.releasingUser.twilioNumber
      );

    if (releaseNumber) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Number released successfully" })
      );
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Number release failed" })
      );
    }

    this.hideReleaseWarningView();
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    this.releaseWarningViewPopup?.dispose();

    this.twiloWarningViewPopup?.dispose();

    this.assignTwiloNumberPopup?.dispose();

    super.dispose();
  }
  public showReleaseWarningView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.releaseWarningViewPopup?.dispose();

    this.releaseWarningViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 500,
        child: TwiloReleaseWarningView({
          assignToAnotherUser: () => {
            this.onAssignNumberToOtherUser();
          },
          releaseTwilioNumber: () => {
            this.onReleaseNumber();
          },
        }),
        className: "xfd7 hc vc",
      }),
    });

    this.releaseWarningViewPopup.showPopup(this.context);
  }
  public showTwiloWarningView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.twiloWarningViewPopup?.dispose();

    this.twiloWarningViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 500,
        child: TwiloWarningView({ obj: this.phoneNumberObj }),
        className: "x6ff hc vc",
      }),
    });

    this.twiloWarningViewPopup.showPopup(this.context);
  }
  public showAssignTwiloNumber(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.assignTwiloNumberPopup?.dispose();

    this.assignTwiloNumberPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 400,
        child: AssignTwiloNumber({
          activeNumbersUsers: this.activeNumbersUsers,
          obj: this.phoneNumberDetailsObj,
          onReload: () => {
            this.onNumberAssignSuccess();
          },
        }),
        className: "x2c39 hc vc",
      }),
    });

    this.assignTwiloNumberPopup.showPopup(this.context);
  }
  public hideReleaseWarningView(): void {
    this.releaseWarningViewPopup?.dispose();
  }
  public hideTwiloWarningView(): void {
    this.twiloWarningViewPopup?.dispose();
  }
  public hideAssignTwiloNumber(): void {
    this.assignTwiloNumberPopup?.dispose();
  }
  public labelDropdownonChanged = (
    val: string,
    d3eState: SettingsPageRefs
  ): void => {
    this.setCountryWithCode(val);
  };
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: SettingsPageRefs
  ): void => {
    this.setSearchBy(val);
  };
  public connectToggleonChanged = (
    val: boolean,
    d3eState: SettingsPageRefs
  ): void => {
    this.setUiConfiguration(val);
  };
  public enableMessagingToggleonChanged = (
    val: boolean,
    d3eState: SettingsPageRefs
  ): void => {
    this.company.setEnableMessaging(val);
  };
  public messageServiceIdonChanged = (
    val: string,
    d3eState: SettingsPageRefs
  ): void => {
    this.company.setMessageServiceId(val);
  };
  public get confiureButton() {
    return this.d3eState.confiureButton;
  }
  public get saveButton() {
    return this.d3eState.saveButton;
  }
  public get searchButton() {
    return this.d3eState.searchButton;
  }
}
export default function SettingsPage(props: SettingsPageProps) {
  return React.createElement(_SettingsPageState, {
    ..._SettingsPageState.defaultProps,
    ...props,
  });
}
