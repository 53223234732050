import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FileUploadResult from "../classes/FileUploadResult";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import SuccessMessage from "../classes/SuccessMessage";
import FileToUpload from "../classes/FileToUpload";
import EventBus from "../utils/EventBus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import UserRole from "../classes/UserRole";
import Result from "../classes/Result";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import PlatformClient from "../classes/PlatformClient";
import Browser from "../classes/Browser";
import Company from "../models/Company";
import TextView from "./TextView";
import RPCServices from "../rpc/RPCServices";
import CollectionUtils from "../utils/CollectionUtils";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: EditProfileViewRefs) => void;

type _ProfileButtonOnPressed = (d3eState: EditProfileViewRefs) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: EditProfileViewRefs
) => void;

type _LabelWithInputField1OnChanged = (
  text: string,
  d3eState: EditProfileViewRefs
) => void;

type _LabelWithInputField2OnChanged = (
  text: string,
  d3eState: EditProfileViewRefs
) => void;

export interface EditProfileViewProps extends BaseUIProps {
  key?: string;
  user: User;
}
/// To store state data for EditProfileView
class EditProfileViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public profileButton: ProfileButtonState = new ProfileButtonState();
}

interface ProfileButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EditProfileViewRefs;
  _onProfileButtonPressed?: _ProfileButtonOnPressed;
}

class ProfileButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ProfileButtonWithState extends ObservableComponent<ProfileButtonWithStateProps> {
  profileButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ProfileButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get profileButton(): ProfileButtonState {
    return this.props.d3eState.profileButton;
  }
  public get d3eState(): EditProfileViewRefs {
    return this.props.d3eState;
  }
  public get _onProfileButtonPressed(): _ProfileButtonOnPressed {
    return this.props._onProfileButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("profileButton", null, this.profileButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["profileButton", "profileButton.", "profileButton.hover"],
      this.rebuild
    );
  }
  public profileButtonOnEnter(event): void {
    return this.profileButton.setHover(true);
  }
  public profileButtonOnExit(event): void {
    return this.profileButton.setHover(false);
  }
  public dispose(): void {
    this.profileButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.profileButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.profileButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.profileButton.disable,
        onPressed: () => {
          this._onProfileButtonPressed(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Save" }),
        onEnter: (event) => {
          this.profileButtonOnEnter(event);
        },
        onExit: (event) => {
          this.profileButtonOnExit(event);
        },
      }),
      className: "xa3b hc",
    });
  }
}
function ProfileButtonWithState(props: ProfileButtonWithStateProps) {
  return React.createElement(_ProfileButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EditProfileViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): EditProfileViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "xe0e hc",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _EditProfileViewState extends ObservableComponent<EditProfileViewProps> {
  static defaultProps = { user: null };
  d3eState: EditProfileViewRefs = new EditProfileViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _firstNameErrors: Array<string> = [];
  private _lastNameErrors: Array<string> = [];
  private _emailErrors: Array<string> = [];
  public emailUniqueError: string;
  private _passwordErrors: Array<string> = [];
  private _roleErrors: Array<string> = [];
  private _companyErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: EditProfileViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "emailErrors",
        "firstNameErrors",
        "lastNameErrors",
        "user",
        "user.email",
        "user.firstName",
        "user.lastName",
        "user.profile",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: EditProfileViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get firstNameErrors(): Array<string> {
    return this._firstNameErrors ?? [];
  }
  public set firstNameErrors(val: Array<string>) {
    this._firstNameErrors.clear();

    this._firstNameErrors.addAll(val);
  }
  public async computeFirstNameErrors(): Promise<void> {
    this.firstNameErrors = [];

    let it: string = this.user.firstName;

    if (it == null || it === "") {
      this.firstNameErrors.add("First Name cannot be empty.");
    }

    this.fire("firstNameErrors", this);
  }
  public get lastNameErrors(): Array<string> {
    return this._lastNameErrors ?? [];
  }
  public set lastNameErrors(val: Array<string>) {
    this._lastNameErrors.clear();

    this._lastNameErrors.addAll(val);
  }
  public async computeLastNameErrors(): Promise<void> {
    this.lastNameErrors = [];

    let it: string = this.user.lastName;

    if (it == null || it === "") {
      this.lastNameErrors.add("Last Name cannot be empty.");
    }

    this.fire("lastNameErrors", this);
  }
  public get emailErrors(): Array<string> {
    return this._emailErrors ?? [];
  }
  public set emailErrors(val: Array<string>) {
    this._emailErrors.clear();

    this._emailErrors.addAll(val);
  }
  public async computeEmailErrors(): Promise<void> {
    this.emailErrors = [];

    let it: string = this.user.email;

    if (it == null || it === "") {
      this.emailErrors.add("Email cannot be empty.");
    }

    this.fire("emailErrors", this);
  }
  public async checkUserEmailUniqueInCompany(): Promise<void> {
    if (this.user == null || this.user.company == null) {
      return;
    }

    let isUnique: boolean =
      await RPCServices.getUniqueChecker().checkUserEmailUniqueInCompany(
        this.user.company,
        this.user,
        this.user.email
      );

    if (!isUnique) {
      this.emailUniqueError = "Duplicate email found with this value.";
    }
  }
  public get passwordErrors(): Array<string> {
    return this._passwordErrors ?? [];
  }
  public set passwordErrors(val: Array<string>) {
    this._passwordErrors.clear();

    this._passwordErrors.addAll(val);
  }
  public async computePasswordErrors(): Promise<void> {
    this.passwordErrors = [];

    let it: string = this.user.password;

    if (it == null || it === "") {
      this.passwordErrors.add("Password cannot be empty.");
    }

    this.fire("passwordErrors", this);
  }
  public get roleErrors(): Array<string> {
    return this._roleErrors ?? [];
  }
  public set roleErrors(val: Array<string>) {
    this._roleErrors.clear();

    this._roleErrors.addAll(val);
  }
  public async computeRoleErrors(): Promise<void> {
    this.roleErrors = [];

    let it: UserRole = this.user.role;

    this.fire("roleErrors", this);
  }
  public get companyErrors(): Array<string> {
    return this._companyErrors ?? [];
  }
  public set companyErrors(val: Array<string>) {
    this._companyErrors.clear();

    this._companyErrors.addAll(val);
  }
  public async computeCompanyErrors(): Promise<void> {
    this.companyErrors = [];

    let it: Company = this.user.company;

    if (it == null) {
      this.companyErrors.add("Company cannot be null.");
    }

    this.fire("companyErrors", this);
  }
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.firstNameErrors.isNotEmpty) {
      return false;
    }

    if (this.lastNameErrors.isNotEmpty) {
      return false;
    }

    if (this.emailErrors.isNotEmpty) {
      return false;
    }

    if (this.passwordErrors.isNotEmpty) {
      return false;
    }

    if (this.roleErrors.isNotEmpty) {
      return false;
    }

    if (this.companyErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeFirstNameErrors();

    errors.addAll(this.firstNameErrors);

    this.computeLastNameErrors();

    errors.addAll(this.lastNameErrors);

    this.computeEmailErrors();

    errors.addAll(this.emailErrors);

    this.computePasswordErrors();

    errors.addAll(this.passwordErrors);

    this.computeRoleErrors();

    errors.addAll(this.roleErrors);

    this.computeCompanyErrors();

    errors.addAll(this.companyErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Edit Profile",
      content: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.center,
        mainAxisAlignment: ui.MainAxisAlignment.center,
        children: [
          ui.Container({
            alignment: ui.Alignment.center,
            child:
              this.user.profile === null ||
              this.user.profile.downloadUrl === null
                ? ui.ClipRRect({
                    borderRadius: ui.BorderRadius.circular(50),
                    child: ui.AssetImage({
                      path: "images/profile1.png",
                      width: 100,
                      height: 100,
                      fit: ui.BoxFit.fill,
                      className: "xcfc hc vc",
                    }),
                  })
                : ui.ClipRRect({
                    borderRadius: ui.BorderRadius.circular(50),
                    child: ui.NetworkImage({
                      url:
                        this.user.profile.downloadUrl +
                        "?width=100&height=100&inline=true",
                      width: 100,
                      height: 100,
                      fit: ui.BoxFit.fill,
                      className: "x161 hc vc",
                    }),
                  }),
            onTap: (e) => {
              e.stopPropagation();

              this.onPressedProfileButtonHandler(this.d3eState);
            },
            className: "xcf9 hc vc",
            key: "0",
          }),
          LabelWithInputField({
            name: "User Name",
            placeHolder: "Enter User Name",
            value: this.user.firstName,
            isRequired: true,
            errors: this.firstNameErrors,
            onChanged: (text) => {
              this.labelWithInputFieldonChanged(text, this.d3eState);
            },
            className: "x32e7 hc h",
            key: "1",
          }),
          LabelWithInputField({
            name: "Last Name",
            placeHolder: "Enter Last Name",
            value: this.user.lastName,
            isRequired: true,
            errors: this.lastNameErrors,
            onChanged: (text) => {
              this.labelWithInputField1onChanged(text, this.d3eState);
            },
            className: "x5fe hc h",
            key: "2",
          }),
          LabelWithInputField({
            name: "Email",
            placeHolder: "Enter Email",
            value: this.user.email,
            isRequired: true,
            errors: this.emailErrors,
            onChanged: (text) => {
              this.labelWithInputField2onChanged(text, this.d3eState);
            },
            className: "xd6d hc h",
            key: "3",
          }),
        ],
        className: "xe56 hc h",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        ProfileButtonWithState({
          d3eState: this.d3eState,
          _onProfileButtonPressed: this.onProfileButtonPressed,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "EditProfileView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedProfileButtonHandler = async (
    d3eState: EditProfileViewRefs
  ): Promise<void> => {
    let fileToUpload: FileToUpload = await Browser.selectFile(
      ["jpg", "jpeg", "png"],
      () => {}
    );

    if (fileToUpload !== null) {
      let res: FileUploadResult = await PlatformClient.upload(fileToUpload);

      if (res.success) {
        this.user.setProfile(res.file);
      }
    }
  };
  public cancelButtonHandler = (d3eState: EditProfileViewRefs): void => {
    this.user.restore();

    this.navigator.close();
  };
  public onProfileButtonPressed = async (
    d3eState: EditProfileViewRefs
  ): Promise<void> => {
    if (this.validate().isEmpty) {
      this.user.setEmail(this.user.email.toLowerCase());

      this.user.setEmail(this.user.email.replaceAll(" ", ""));

      let result: Result<User> = await this.user.save();

      if (result.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({ message: "Successfully Saved" })
        );

        this.navigator.close();
      } else {
        this.setErrors(result.errors);
      }
    }
  };
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: EditProfileViewRefs
  ): void => {
    this.user.setFirstName(val);
  };
  public labelWithInputField1onChanged = (
    val: string,
    d3eState: EditProfileViewRefs
  ): void => {
    this.user.setLastName(val);
  };
  public labelWithInputField2onChanged = (
    val: string,
    d3eState: EditProfileViewRefs
  ): void => {
    this.user.setEmail(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get profileButton() {
    return this.d3eState.profileButton;
  }
}
export default function EditProfileView(props: EditProfileViewProps) {
  return React.createElement(_EditProfileViewState, {
    ..._EditProfileViewState.defaultProps,
    ...props,
  });
}
