import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Device from "../utils/Device";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import UserRole from "../classes/UserRole";
import Query from "../classes/Query";
import Button from "./Button";
import EditProfileView from "./EditProfileView";
import SideMenuButton from "./SideMenuButton";
import Company from "../models/Company";
import TextView from "./TextView";
import IconView from "./IconView";
import CollapseSideMenuButton from "./CollapseSideMenuButton";
import { BuildContext } from "../classes/BuildContext";

type _SideMenuViewOnMenuChanged = (menu: string) => void;

type _LogoutButtonOnPressed = (d3eState: SideMenuViewRefs) => void;

type _ProfileButtonOnPressed = (d3eState: SideMenuViewRefs) => void;

export interface SideMenuViewProps extends BaseUIProps {
  key?: string;
  user: User;
  company: Company;
  onMenuChanged?: _SideMenuViewOnMenuChanged;
}
/// To store state data for SideMenuView
class SideMenuViewRefs {
  public logoutButton: LogoutButtonState = new LogoutButtonState();
  public profileButton: ProfileButtonState = new ProfileButtonState();
}

interface ProfileButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SideMenuViewRefs;
  _onProfileButtonHandler?: _ProfileButtonOnPressed;
  user: User;
}

class ProfileButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ProfileButtonWithState extends ObservableComponent<ProfileButtonWithStateProps> {
  profileButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ProfileButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get profileButton(): ProfileButtonState {
    return this.props.d3eState.profileButton;
  }
  public get user(): User {
    return this.props.user;
  }
  public get d3eState(): SideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onProfileButtonHandler(): _ProfileButtonOnPressed {
    return this.props._onProfileButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("profileButton", null, this.profileButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "profileButton",
        "user",
        "user.firstName",
        "user.lastName",
        "user.profile",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ProfileButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 15.0, 0.0, 0.0, new Map()),
      width: Number.POSITIVE_INFINITY,
      child: Button({
        decoration: new ui.BoxDecoration({
          color: new ui.Color(0xff262626),
          border: ui.Border.all({ color: new ui.Color(0xff0000), width: 0.0 }),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.symmetric({
          horizontal: 12.0,
          vertical: 8.0,
          transitions: new Map(),
        }),
        disable: this.profileButton.disable,
        onPressed: () => {
          this._onProfileButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            this.user.profile !== null && this.user.profile.downloadUrl !== null
              ? ui.ClipRRect({
                  borderRadius: ui.BorderRadius.circular(16),
                  child: ui.NetworkImage({
                    url:
                      this.user.profile.downloadUrl +
                      "?width=25&height=25&inline=true",
                    width: 25,
                    height: 25,
                    fit: ui.BoxFit.fill,
                    className: "xfea8 hc vc",
                  }),
                })
              : ui.ClipRRect({
                  borderRadius: ui.BorderRadius.circular(16),
                  child: ui.AssetImage({
                    path: "images/profile1.png",
                    width: 25,
                    height: 25,
                    fit: ui.BoxFit.fill,
                    className: "x3a26 hc vc",
                  }),
                }),
            TextView({
              data: this.user.firstName + " " + this.user.lastName,
              softWrap: true,
              style: new ui.TextStyle({ fontSize: 13 }),
              className: "x07fb hc h",
              key: "1",
            }),
          ],
          className: "xbeb h",
        }),
      }),
      className: "x724 h",
    });
  }
}
function ProfileButtonWithState(props: ProfileButtonWithStateProps) {
  return React.createElement(_ProfileButtonWithState, props);
}

interface LogoutButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SideMenuViewRefs;
  _onLogoutButtonHandler?: _LogoutButtonOnPressed;
}

class LogoutButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _LogoutButtonWithState extends ObservableComponent<LogoutButtonWithStateProps> {
  logoutButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LogoutButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get logoutButton(): LogoutButtonState {
    return this.props.d3eState.logoutButton;
  }
  public get d3eState(): SideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onLogoutButtonHandler(): _LogoutButtonOnPressed {
    return this.props._onLogoutButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("logoutButton", null, this.logoutButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["logoutButton", "logoutButton.hover"], this.rebuild);
  }
  public logoutButtonOnEnter(event): void {
    return this.logoutButton.setHover(true);
  }
  public logoutButtonOnExit(event): void {
    return this.logoutButton.setHover(false);
  }
  public dispose(): void {
    this.logoutButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 20.0, new Map()),
      width: this.logoutButton.hover
        ? Number.POSITIVE_INFINITY
        : Number.POSITIVE_INFINITY,
      child: Button({
        decoration: this.logoutButton.hover
          ? new ui.BoxDecoration({
              color: new ui.Color(0xff2b2b2b),
              border: ui.Border.all({
                color: new ui.Color(0xff2b2b2b),
                width: 0.0,
              }),
              borderRadius: ui.BorderRadius.circular(3.0),
            })
          : new ui.BoxDecoration({
              color: new ui.Color(0xff262626),
              border: ui.Border.all({
                color: new ui.Color(0xff0000),
                width: 0.0,
              }),
              borderRadius: ui.BorderRadius.circular(3.0),
            }),
        padding: this.logoutButton.hover
          ? ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 8.0,
              transitions: new Map(),
            })
          : ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 8.0,
              transitions: new Map(),
            }),
        disable: this.logoutButton.disable,
        onPressed: () => {
          this._onLogoutButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        states: ui.joinStates({ "data-c0": this.logoutButton.hover }, {}),
        child: ui.Row({
          mainAxisSize: ui.MainAxisSize.max,
          children: [
            IconView({
              size: 20,
              icon: MaterialIcons.exit_to_app,
              className: "x61c4",
              key: "0",
            }),
            TextView({
              data: "Logout",
              style: new ui.TextStyle({
                fontSize: 13,
                fontWeight: ui.FontWeight.w500,
              }),
              className: "xa6a4",
              key: "1",
            }),
          ],
        }),
        onEnter: (event) => {
          this.logoutButtonOnEnter(event);
        },
        onExit: (event) => {
          this.logoutButtonOnExit(event);
        },
      }),
      className: "x73a7",
      states: ui.joinStates({ "data-c0": this.logoutButton.hover }, {}),
    });
  }
}
function LogoutButtonWithState(props: LogoutButtonWithStateProps) {
  return React.createElement(_LogoutButtonWithState, props);
}

class _SideMenuViewState extends ObservableComponent<SideMenuViewProps> {
  static defaultProps = { user: null, company: null, onMenuChanged: null };
  d3eState: SideMenuViewRefs = new SideMenuViewRefs();
  selectedMenu: string = "";
  private _selectedMenuDefaultValue: string = "";
  editProfilePopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SideMenuViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get company(): Company {
    return this.props.company;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.setDefaults();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("company", this.props.company);

    this.on(["user", "user.role"], this.setSelectedMenuDefaultValue);

    this.on(
      ["company", "company.isLeadService", "selectedMenu", "user", "user.role"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SideMenuViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.company !== this.props.company) {
      this.updateObservable("company", prevProps.company, this.props.company);

      this.fire("company", this);
    }
  }
  public setSelectedMenu(val: string): void {
    let isValChanged: boolean = this.selectedMenu !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedMenu = val;

    this.fire("selectedMenu", this);
  }
  public setSelectedMenuDefaultValue = (force?: boolean): void => {
    try {
      let oldDefaultValue: string = this._selectedMenuDefaultValue;

      this._selectedMenuDefaultValue =
        this.user.role === UserRole.Manager ? "Dashboard" : "Home";

      if (force || oldDefaultValue === this.selectedMenu)
        this.setSelectedMenu(this._selectedMenuDefaultValue);
    } catch (e) {
      this.setSelectedMenu("");
    }
  };
  public setDefaults(): void {
    this.setSelectedMenuDefaultValue();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            this.company.isLeadService
              ? TextView({
                  data: "Lead Management",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                  }),
                  className: "xf11",
                })
              : ui.AssetImage({
                  path: "images/apple-touch-icon.png",
                  width: 40,
                  height: 29,
                  className: "xdbb hc vc",
                }),
            ui.Container({ className: "x1ca vc", key: "1" }),
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Dashboard",
                  icon: MaterialIcons.dashboard,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onDashboardButtonHandler(this.d3eState);
                  },
                  className: "xd71",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Leads",
                  icon: MaterialIcons.leaderboard,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onLeadManagementButtonHandler(this.d3eState);
                  },
                  className: "x657",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Team",
                  icon: MaterialIcons.group,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onTeamButtonHandler(this.d3eState);
                  },
                  className: "x9fb6",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Search Leads",
                  icon: MaterialIcons.hdr_auto,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onApolloLeadsButtonHandler(this.d3eState);
                  },
                  className: "x710",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Connections",
                  icon: MaterialIcons.connect_without_contact,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onConnectionsButtonHandler(this.d3eState);
                  },
                  className: "x958",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Mails",
                  icon: MaterialIcons.all_inbox,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onMailBoxButtonHandler(this.d3eState);
                  },
                  className: "xdc6f7",
                })
              : [],
            this.user.role === UserRole.Manager
              ? SideMenuButton({
                  title: "Settings",
                  icon: MaterialIcons.settings,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onSettingButtonHandler(this.d3eState);
                  },
                  className: "x15e",
                })
              : [],
            this.user.role === UserRole.SalesPerson
              ? SideMenuButton({
                  title: "Home",
                  icon: MaterialIcons.dashboard,
                  selectedMenu: this.selectedMenu,
                  company: this.company,
                  onPressed: () => {
                    this.onSalePersonDashboardButtonHandler(this.d3eState);
                  },
                  className: "x69b",
                })
              : [],
          ],
          className: "xfe4 vc v",
          key: "0",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            this.company.isLeadService
              ? LogoutButtonWithState({
                  d3eState: this.d3eState,
                  _onLogoutButtonHandler: this.onLogoutButtonHandler,
                })
              : CollapseSideMenuButton({
                  title: "Logout",
                  icon: MaterialIcons.exit_to_app,
                  selectedMenu: this.selectedMenu,
                  onPressed: () => {
                    this.onCollapseLogoutButtonHandler(this.d3eState);
                  },
                  className: "x022",
                }),
            ui.Row({ className: "xa3f vc", key: "1" }),
            this.company.isLeadService
              ? ProfileButtonWithState({
                  d3eState: this.d3eState,
                  _onProfileButtonHandler: this.onProfileButtonHandler,
                  user: this.user,
                })
              : CollapseSideMenuButton({
                  title: "Profile",
                  icon: MaterialIcons.person,
                  selectedMenu: this.selectedMenu,
                  onPressed: () => {
                    this.onCollapseProfileButtonHandler(this.d3eState);
                  },
                  className: "x94a",
                }),
          ],
          className: "x872 h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "SideMenuView x62e2 vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    if (this.user.role === UserRole.Manager) {
      this.setSelectedMenu("Dashboard");
    } else {
      this.setSelectedMenu("Home");
    }
  };
  public onDashboardButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Dashboard");

    this.setSelectedMenu("Dashboard");
  };
  public onLeadManagementButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Leads");

    this.setSelectedMenu("Leads");
  };
  public onTeamButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Team");

    this.setSelectedMenu("Team");
  };
  public onApolloLeadsButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Search Leads");

    this.setSelectedMenu("Search Leads");
  };
  public onConnectionsButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Connections");

    this.setSelectedMenu("Connections");
  };
  public onSettingButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Settings");

    this.setSelectedMenu("Settings");
  };
  public onMailBoxButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.onMenuChanged("Mails");

    this.setSelectedMenu("Mails");
  };
  public onSalePersonDashboardButtonHandler = (
    d3eState: SideMenuViewRefs
  ): void => {
    this.onMenuChanged("Home");

    this.setSelectedMenu("Home");
  };
  public onLogoutButtonHandler = async (
    d3eState: SideMenuViewRefs
  ): Promise<void> => {
    Device.put("email", null);

    Device.put("password", null);

    let result: boolean = await Query.get().logout();

    this.navigator.pushLoginPage();
  };
  public onCollapseLogoutButtonHandler = async (
    d3eState: SideMenuViewRefs
  ): Promise<void> => {
    Device.put("email", null);

    Device.put("password", null);

    let result: boolean = await Query.get().logout();

    this.navigator.pushLoginPage();
  };
  public onCollapseProfileButtonHandler = (
    d3eState: SideMenuViewRefs
  ): void => {
    this.showEditProfilePopup();
  };
  public onProfileButtonHandler = (d3eState: SideMenuViewRefs): void => {
    this.showEditProfilePopup();
  };
  public get onMenuChanged(): _SideMenuViewOnMenuChanged {
    return this.props.onMenuChanged;
  }
  public dispose(): void {
    this.editProfilePopupPopup?.dispose();

    super.dispose();
  }
  public showEditProfilePopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.editProfilePopupPopup?.dispose();

    this.editProfilePopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 500,
        child: EditProfileView({ user: this.user }),
        className: "x6b3 hc vc",
      }),
    });

    this.editProfilePopupPopup.showPopup(this.context);
  }
  public hideEditProfilePopup(): void {
    this.editProfilePopupPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get logoutButton() {
    return this.d3eState.logoutButton;
  }
  public get profileButton() {
    return this.d3eState.profileButton;
  }
}
export default function SideMenuView(props: SideMenuViewProps) {
  return React.createElement(_SideMenuViewState, {
    ..._SideMenuViewState.defaultProps,
    ...props,
  });
}
