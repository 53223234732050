import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Loader from "./Loader";
import ResultStatus from "../classes/ResultStatus";
import D3EDate from "../classes/D3EDate";
import LeadStatus from "../classes/LeadStatus";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Timer from "../utils/Timer";
import AllLeadsRequest from "../models/AllLeadsRequest";
import DialPadButton from "./DialPadButton";
import IconButton from "./IconButton";
import KeyBoardListnerHelper from "../classes/KeyBoardListnerHelper";
import Result from "../classes/Result";
import Query from "../classes/Query";
import LeadAssignment from "../models/LeadAssignment";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import Duration from "../core/Duration";
import FailureMessage from "../classes/FailureMessage";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _DialPadViewOnCallConnect = (callingLead: Lead, user: User) => void;

type _CallContainerOnTap = (d3eState: DialPadViewRefs) => void;

type _CloseContainerOnTap = (d3eState: DialPadViewRefs) => void;

export interface DialPadViewProps extends BaseUIProps {
  key?: string;
  client: TwilioClient;
  user: User;
  onCallConnect: _DialPadViewOnCallConnect;
}
/// To store state data for DialPadView
class DialPadViewRefs {
  public callContainer: CallContainerState = new CallContainerState();
  public closeContainer: CloseContainerState = new CloseContainerState();
  internalNumberInputFieldController: ui.TextEditingController =
    new ui.TextEditingController();
}

interface CloseContainerWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DialPadViewRefs;
  _closeContainerHandler?: _CloseContainerOnTap;
}

class CloseContainerState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CloseContainerWithState extends ObservableComponent<CloseContainerWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CloseContainerWithStateProps) {
    super(props);

    this.initState();
  }
  public get closeContainer(): CloseContainerState {
    return this.props.d3eState.closeContainer;
  }
  public get d3eState(): DialPadViewRefs {
    return this.props.d3eState;
  }
  public get _closeContainerHandler(): _CloseContainerOnTap {
    return this.props._closeContainerHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("closeContainer", null, this.closeContainer);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["closeContainer", "closeContainer.hover"], this.rebuild);
  }
  public closeContainerOnEnter(event): void {
    return this.closeContainer.setHover(true);
  }
  public closeContainerOnExit(event): void {
    return this.closeContainer.setHover(false);
  }
  public dispose(): void {
    this.closeContainer.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      alignment: ui.Alignment.center,
      states: ui.joinStates({ "data-c0": this.closeContainer.hover }, {}),
      decoration: this.closeContainer.hover
        ? new ui.BoxDecoration({
            color: new ui.Color(0xfff0465c),
            borderRadius: ui.BorderRadius.circular(25.0),
          })
        : new ui.BoxDecoration({}),
      child: IconView({
        icon: MaterialIcons.close,
        size: 20,
        color: cStyle.c14,
        className: "x29d hc vc",
      }),
      onEnter: (event) => {
        this.closeContainerOnEnter(event);
      },
      onExit: (event) => {
        this.closeContainerOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._closeContainerHandler(this.d3eState);
      },
      className: "xfa3 hc vc",
    });
  }
}
function CloseContainerWithState(props: CloseContainerWithStateProps) {
  return React.createElement(_CloseContainerWithState, props);
}

interface CallContainerWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DialPadViewRefs;
  _callButtonHandler?: _CallContainerOnTap;
}

class CallContainerState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CallContainerWithState extends ObservableComponent<CallContainerWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CallContainerWithStateProps) {
    super(props);

    this.initState();
  }
  public get callContainer(): CallContainerState {
    return this.props.d3eState.callContainer;
  }
  public get d3eState(): DialPadViewRefs {
    return this.props.d3eState;
  }
  public get _callButtonHandler(): _CallContainerOnTap {
    return this.props._callButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("callContainer", null, this.callContainer);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["callContainer", "callContainer.hover"], this.rebuild);
  }
  public callContainerOnEnter(event): void {
    return this.callContainer.setHover(true);
  }
  public callContainerOnExit(event): void {
    return this.callContainer.setHover(false);
  }
  public dispose(): void {
    this.callContainer.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      alignment: ui.Alignment.center,
      states: ui.joinStates({ "data-c0": this.callContainer.hover }, {}),
      decoration: this.callContainer.hover
        ? new ui.BoxDecoration({
            color: new ui.Color(0xff108075),
            borderRadius: ui.BorderRadius.circular(25.0),
          })
        : new ui.BoxDecoration({}),
      child: IconView({
        icon: MaterialIcons.call,
        size: 20,
        color: cStyle.c14,
        className: "x80d hc vc",
      }),
      onEnter: (event) => {
        this.callContainerOnEnter(event);
      },
      onExit: (event) => {
        this.callContainerOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._callButtonHandler(this.d3eState);
      },
      className: "xe73 hc vc",
    });
  }
}
function CallContainerWithState(props: CallContainerWithStateProps) {
  return React.createElement(_CallContainerWithState, props);
}

class _DialPadViewState extends ObservableComponent<DialPadViewProps> {
  static defaultProps = { client: null, user: null, onCallConnect: null };
  d3eState: DialPadViewRefs = new DialPadViewRefs();
  internalNumber: string = "";
  lead: Lead = null;
  focusNode: ui.FocusNode = null;
  digit1: boolean = false;
  digit2: boolean = false;
  digit3: boolean = false;
  digit4: boolean = false;
  digit5: boolean = false;
  digit6: boolean = false;
  digit7: boolean = false;
  digit8: boolean = false;
  digit9: boolean = false;
  digit0: boolean = false;
  plush: boolean = false;
  hash: boolean = false;
  showLoader: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DialPadViewProps) {
    super(props);

    this.initState();
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "digit0",
        "digit1",
        "digit2",
        "digit3",
        "digit4",
        "digit5",
        "digit6",
        "digit7",
        "digit8",
        "digit9",
        "focusNode",
        "hash",
        "internalNumber",
        "plush",
        "showLoader",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DialPadViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setInternalNumber(val: string): void {
    let isValChanged: boolean = this.internalNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.internalNumber = val;

    this.fire("internalNumber", this);
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this.lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lead", this.lead, val);

    this.lead = val;

    this.fire("lead", this);
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setDigit1(val: boolean): void {
    let isValChanged: boolean = this.digit1 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit1 = val;

    this.fire("digit1", this);
  }
  public setDigit2(val: boolean): void {
    let isValChanged: boolean = this.digit2 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit2 = val;

    this.fire("digit2", this);
  }
  public setDigit3(val: boolean): void {
    let isValChanged: boolean = this.digit3 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit3 = val;

    this.fire("digit3", this);
  }
  public setDigit4(val: boolean): void {
    let isValChanged: boolean = this.digit4 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit4 = val;

    this.fire("digit4", this);
  }
  public setDigit5(val: boolean): void {
    let isValChanged: boolean = this.digit5 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit5 = val;

    this.fire("digit5", this);
  }
  public setDigit6(val: boolean): void {
    let isValChanged: boolean = this.digit6 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit6 = val;

    this.fire("digit6", this);
  }
  public setDigit7(val: boolean): void {
    let isValChanged: boolean = this.digit7 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit7 = val;

    this.fire("digit7", this);
  }
  public setDigit8(val: boolean): void {
    let isValChanged: boolean = this.digit8 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit8 = val;

    this.fire("digit8", this);
  }
  public setDigit9(val: boolean): void {
    let isValChanged: boolean = this.digit9 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit9 = val;

    this.fire("digit9", this);
  }
  public setDigit0(val: boolean): void {
    let isValChanged: boolean = this.digit0 !== val;

    if (!isValChanged) {
      return;
    }

    this.digit0 = val;

    this.fire("digit0", this);
  }
  public setPlush(val: boolean): void {
    let isValChanged: boolean = this.plush !== val;

    if (!isValChanged) {
      return;
    }

    this.plush = val;

    this.fire("plush", this);
  }
  public setHash(val: boolean): void {
    let isValChanged: boolean = this.hash !== val;

    if (!isValChanged) {
      return;
    }

    this.hash = val;

    this.fire("hash", this);
  }
  public setShowLoader(val: boolean): void {
    let isValChanged: boolean = this.showLoader !== val;

    if (!isValChanged) {
      return;
    }

    this.showLoader = val;

    this.fire("showLoader", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisSize: ui.MainAxisSize.min,
      children: [
        this.showLoader
          ? ui.Center({
              child: Loader({ className: "hc vc" }),
              className: "x7d5 hc vc",
            })
          : [],
        ui.Row({
          children: [
            ui.InputField({
              value: this.internalNumber,
              inActiveColor: cStyle.c14,
              activeColor: cStyle.c14,
              textAlign: ui.TextAlign.center,
              inputFormatter: "^[0-9+#]+$",
              textDirection: ui.TextDirection.ltr,
              focusNode: this.focusNode,
              controller: this.d3eState.internalNumberInputFieldController,
              onChanged: (text) => {
                this.internalNumberInputFieldHandler(text, this.d3eState);
              },
              onFocusChange: (val) => {},
              onKey: (event) => {
                this.onEnterPressed(event, this.d3eState);
              },
              className: "xbe92 hc",
              key: "0",
            }),
            this.internalNumber !== null && this.internalNumber.isNotEmpty
              ? IconButton({
                  icon: MaterialIcons.backspace,
                  size: 20,
                  onPressed: () => {
                    this.backspaceIconButtonHandler(this.d3eState);
                  },
                })
              : [],
          ],
          className: "x950 hc h",
          key: "1",
        }),
        ui.Container({ className: "x2a4 hc vc", key: "2" }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          crossAxisAlignment: ui.CrossAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                DialPadButton({
                  digit: "1",
                  isSelected: this.digit1,
                  onTap: () => {
                    this.firstButtonHandler(this.d3eState);
                  },
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 20.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: DialPadButton({
                    digit: "2",
                    isSelected: this.digit2,
                    onTap: () => {
                      this.secoundButtonHandler(this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "x84",
                }),
                DialPadButton({
                  digit: "3",
                  isSelected: this.digit3,
                  onTap: () => {
                    this.button3Handler(this.d3eState);
                  },
                  key: "2",
                }),
              ],
              className: "x72f hc h",
              key: "0",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                DialPadButton({
                  digit: "4",
                  isSelected: this.digit4,
                  onTap: () => {
                    this.button4Handler(this.d3eState);
                  },
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 20.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: DialPadButton({
                    digit: "5",
                    isSelected: this.digit5,
                    onTap: () => {
                      this.button5Handler(this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "xf72",
                }),
                DialPadButton({
                  digit: "6",
                  isSelected: this.digit6,
                  onTap: () => {
                    this.button6Handler(this.d3eState);
                  },
                  key: "2",
                }),
              ],
              className: "xb1a hc h",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                DialPadButton({
                  digit: "7",
                  isSelected: this.digit7,
                  onTap: () => {
                    this.button7Handler(this.d3eState);
                  },
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 20.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: DialPadButton({
                    digit: "8",
                    isSelected: this.digit8,
                    onTap: () => {
                      this.button8Handler(this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "xbe8",
                }),
                DialPadButton({
                  digit: "9",
                  isSelected: this.digit9,
                  onTap: () => {
                    this.button9Handler(this.d3eState);
                  },
                  key: "2",
                }),
              ],
              className: "x2e6 hc h",
              key: "2",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                DialPadButton({
                  digit: "+",
                  isSelected: this.plush,
                  onTap: () => {
                    this.buttonStarHandler(this.d3eState);
                  },
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 20.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: DialPadButton({
                    digit: "0",
                    isSelected: this.digit0,
                    onTap: () => {
                      this.button0Handler(this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "xbe82",
                }),
                DialPadButton({
                  digit: "#",
                  isSelected: this.hash,
                  onTap: () => {
                    this.buttonHashHandler(this.d3eState);
                  },
                  key: "2",
                }),
              ],
              className: "xb15 hc h",
              key: "3",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                CallContainerWithState({
                  d3eState: this.d3eState,
                  _callButtonHandler: this.callButtonHandler,
                  key: "0",
                }),
                CloseContainerWithState({
                  d3eState: this.d3eState,
                  _closeContainerHandler: this.closeContainerHandler,
                  key: "1",
                }),
              ],
              className: "x2de hc h",
              key: "4",
            }),
          ],
          className: "x3d1 hc h",
          key: "3",
        }),
      ],
      className: ui.join(this.props.className, "DialPadView x63 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setInternalNumber("");

    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    let active: boolean = this.focusNode.hasFocus;
  };
  public closeContainerHandler = (d3eState: DialPadViewRefs): void => {
    this.navigator.close();
  };
  public backspaceIconButtonHandler = (d3eState: DialPadViewRefs): void => {
    if (this.internalNumber !== null && this.internalNumber.isNotEmpty) {
      this.setInternalNumber(
        this.internalNumber.substring(0, this.internalNumber.length - 1)
      );

      d3eState.internalNumberInputFieldController.text = this.internalNumber;
    }
  };
  public firstButtonHandler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "1");
  };
  public secoundButtonHandler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "2");
  };
  public button3Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "3");
  };
  public button4Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "4");
  };
  public button5Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "5");
  };
  public button6Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "6");
  };
  public button7Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "7");
  };
  public button8Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "8");
  };
  public button9Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "9");
  };
  public buttonStarHandler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "+");
  };
  public button0Handler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "0");
  };
  public buttonHashHandler = (d3eState: DialPadViewRefs): void => {
    this.setInternalNumber(this.internalNumber + "#");
  };
  public internalNumberInputFieldHandler = (
    text: string,
    d3eState: DialPadViewRefs
  ): void => {
    this.setInternalNumber(text);
  };
  public callButtonHandler = async (
    d3eState: DialPadViewRefs
  ): Promise<void> => {
    if (this.internalNumber === null || this.internalNumber.isEmpty) {
      EventBus.get().fire(
        new FailureMessage({ message: "Please enter a valid phone number." })
      );

      return;
    }

    let hasMicPermission: boolean = await this.client.checkMicPermission();

    if (!hasMicPermission) {
      EventBus.get().fire(
        new FailureMessage({
          message: "Please allow microphone permission to make calls.",
        })
      );

      return;
    }

    //  check the internalNumber start with +  or not if not then add + in the start of the number

    if (!this.internalNumber.startsWith("+")) {
      this.setInternalNumber("+" + this.internalNumber);
    }

    this.onCallChange();
  };
  public onCallChange = async (): Promise<void> => {
    this.setShowLoader(true);

    if (this.internalNumber !== null || this.internalNumber.isNotEmpty) {
      this.setInternalNumber(this.internalNumber.replaceAll(" ", ""));
    }

    let leadsList: Array<Lead> = (
      await Query.get().getAllLeads(
        UsageConstants.QUERY_GETALLLEADS_DIALPADVIEW_EVENTHANDLERS_ONCALLCHANGE_BLOCK,
        new AllLeadsRequest({
          user: this.user,
          numberValue: this.internalNumber,
        })
      )
    ).items;

    if (leadsList.isNotEmpty) {
      this.setLead(leadsList.first);

      this.onCallConnect(this.lead, this.user);
    } else {
      let internalLead: Lead = new Lead({
        name: this.internalNumber,
        phone: this.internalNumber,
        status: LeadStatus.New,
        createdBy: this.user,
      });

      this.setLead(internalLead);

      let result: Result<Lead> = await this.lead.save();

      if (result.status === ResultStatus.Success) {
        let leadAssignment: LeadAssignment = new LeadAssignment({
          lead: this.lead,
          assignedTo: this.user,
          assignedBy: this.user,
        });

        leadAssignment.setAssignedDate(D3EDate.now());

        let result2: Result<LeadAssignment> = await leadAssignment.save();

        if (result2.status === ResultStatus.Success) {
          this.onCallConnect(this.lead, this.user);
        }
      }
    }

    this.setShowLoader(false);
  };
  public onEnterPressed = (
    event: ui.RawKeyEvent,
    d3eState: DialPadViewRefs
  ): void => {
    let key: string = KeyBoardListnerHelper.getkey(event);

    switch (key) {
      case "Enter": {
        this.onCallChange();
        break;
      }

      case "1": {
        this.setDigit1(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit1(false);
        });
        break;
      }

      case "2": {
        this.setDigit2(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit2(false);
        });
        break;
      }

      case "3": {
        this.setDigit3(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit3(false);
        });
        break;
      }

      case "4": {
        this.setDigit4(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit4(false);
        });
        break;
      }

      case "5": {
        this.setDigit5(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit5(false);
        });
        break;
      }

      case "6": {
        this.setDigit6(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit6(false);
        });
        break;
      }

      case "7": {
        this.setDigit7(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit7(false);
        });
        break;
      }

      case "8": {
        this.setDigit8(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit8(false);
        });
        break;
      }

      case "9": {
        this.setDigit9(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit9(false);
        });
        break;
      }

      case "0": {
        this.setDigit0(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setDigit0(false);
        });
        break;
      }

      case "+": {
        this.setPlush(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setPlush(false);
        });
        break;
      }

      case "#": {
        this.setHash(true);

        new Timer(new Duration({ milliseconds: 100 }), () => {
          this.setHash(false);
        });
        break;
      }
      default: {
      }
    }

    if (event.logicalKey === ui.LogicalKeyboardKey.backspace) {
      if (this.internalNumber !== null && this.internalNumber.isNotEmpty) {
        this.setInternalNumber(
          this.internalNumber.substring(0, this.internalNumber.length - 1)
        );
      }
    }
  };
  public get onCallConnect(): _DialPadViewOnCallConnect {
    return this.props.onCallConnect;
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get callContainer() {
    return this.d3eState.callContainer;
  }
  public get closeContainer() {
    return this.d3eState.closeContainer;
  }
}
export default function DialPadView(props: DialPadViewProps) {
  return React.createElement(_DialPadViewState, {
    ..._DialPadViewState.defaultProps,
    ...props,
  });
}
