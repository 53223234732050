import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import ResultStatus from "../classes/ResultStatus";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import IconButton from "./IconButton";
import Result from "../classes/Result";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import SearchComponent from "./SearchComponent";
import ListWrapper from "../utils/ListWrapper";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import LeadMailBox from "../classes/LeadMailBox";
import FailureMessage from "../classes/FailureMessage";
import CollectionUtils from "../utils/CollectionUtils";
import TabButton from "./TabButton";
import D3EDisposable from "../rocket/D3EDisposable";
import MailStatusView from "./MailStatusView";
import SuccessMessage from "../classes/SuccessMessage";
import MicroSoftAcc from "../models/MicroSoftAcc";
import EventBus from "../utils/EventBus";
import MailPreviewView from "./MailPreviewView";
import Browser from "../classes/Browser";
import ScrollView2 from "./ScrollView2";
import LeadMailBoxRequest from "../models/LeadMailBoxRequest";
import DateTime from "../core/DateTime";
import RPCServices from "../rpc/RPCServices";
import MailMessage from "../models/MailMessage";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface LeadMailsPageProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _LeadMailsPageState extends ObservableComponent<LeadMailsPageProps> {
  static defaultProps = { lead: null, user: null, client: null, data: null };
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  query: LeadMailBox = null;
  request: LeadMailBoxRequest = null;
  mails: Array<MailMessage> = ListWrapper.widget(this, "mails");
  selectedMail: MailMessage = null;
  searchMail: string = "";
  selectedTab: string = "All";
  showLoader: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public userDisposable: D3EDisposable;
  public constructor(props: LeadMailsPageProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.userDisposable = MessageDispatch.get().syncObject(
      this.props.user,
      UsageConstants.SUBSCRIPTION_ONUSERCHANGE_LEADMAILSPAGE_USER_SYNCHRONISE
    );

    this.runFetchDataQueryForUser();

    this.userDisposable = MessageDispatch.get().syncObject(
      this.props.user,
      UsageConstants.QUERY_GETUSERBYID_LEADMAILSPAGE_USER_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      [
        "request",
        "request.lead",
        "request.offset",
        "request.pageSize",
        "request.searchMail",
      ],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computeMails);

    this.computeMails();

    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      ["mails", "mails.inbound", "selectedMail", "selectedTab"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LeadMailsPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.runFetchDataQueryForUser();

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setQuery(val: LeadMailBox): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getLeadMailBox(
          UsageConstants.QUERY_GETLEADMAILBOX_LEADMAILSPAGE_PROPERTIES_QUERY_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setRequest(val: LeadMailBoxRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setMails(val: Array<MailMessage>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.mails, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("mails", this.mails, val);

    this.mails.clear();

    this.mails.addAll(val);

    this.fire("mails", this);
  }
  public addToMails(val: MailMessage, index: number = -1): void {
    if (index === -1) {
      if (!this.mails.contains(val)) this.mails.add(val);
    } else {
      this.mails.remove(this.mails.elementAt(index));

      this.mails.add(val);
    }

    this.fire("mails", this, val, true);

    this.updateObservable("mails", null, val);
  }
  public removeFromMails(val: MailMessage): void {
    this.mails.remove(val);

    this.fire("mails", this, val, false);

    this.removeObservable("mails", val);
  }
  public computeMails = (): void => {
    try {
      this.setMails(Array.from(this.query?.items ?? []));
    } catch (exception) {
      console.log(" exception in computeMails : " + exception.toString());

      this.setMails([]);
    }
  };
  public setSelectedMail(val: MailMessage): void {
    let isValChanged: boolean = this.selectedMail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedMail", this.selectedMail, val);

    this.selectedMail = val;

    this.fire("selectedMail", this);
  }
  public runFetchDataQueryForUser = (): void => {
    if (this.user == null) {
      return;
    }

    Query.get().getUserById(
      UsageConstants.QUERY_GETUSERBYID_LEADMAILSPAGE_USER_FETCHDATA,
      this.user.id
    );
  };
  public setSearchMail(val: string): void {
    let isValChanged: boolean = this.searchMail !== val;

    if (!isValChanged) {
      return;
    }

    this.searchMail = val;

    this.fire("searchMail", this);
  }
  public setSelectedTab(val: string): void {
    let isValChanged: boolean = this.selectedTab !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedTab = val;

    this.fire("selectedTab", this);
  }
  public setShowLoader(val: boolean): void {
    let isValChanged: boolean = this.showLoader !== val;

    if (!isValChanged) {
      return;
    }

    this.showLoader = val;

    this.fire("showLoader", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            ui.Column({
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  crossAxisAlignment: ui.CrossAxisAlignment.center,
                  children: [
                    ui.Row({
                      children: [
                        IconButton({
                          icon: MaterialIcons.arrow_back,
                          tooltip: "Back",
                          onPressed: () => {
                            this.onBack();
                          },
                          key: "0",
                        }),
                        TextView({
                          data: "Mails",
                          style: new ui.TextStyle({
                            fontSize: 15,
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "x085",
                          key: "1",
                        }),
                      ],
                      key: "0",
                    }),
                    ui.Row({
                      children: [
                        IconButton({
                          icon: MaterialIcons.mail,
                          tooltip: "Send Mail",
                          onPressed: () => {
                            this.onEmailButtonHandler();
                          },
                          key: "0",
                        }),
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            10.0,
                            0.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: IconButton({
                            icon: MaterialIcons.sync,
                            tooltip: "Sync",
                            onPressed: () => {
                              this.onFetchEmail();
                            },
                          }),
                          key: "1",
                          className: "xadd",
                        }),
                      ],
                      key: "1",
                    }),
                  ],
                  className: "xd8f",
                  key: "0",
                }),
                ui.Container({
                  width: 380,
                  child: SearchComponent({
                    placeholder: "Search Mail",
                    onChanged: (value) => {
                      this.onSearchTapHandler(value);
                    },
                  }),
                  key: "1",
                  className: "xcce hc",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  crossAxisAlignment: ui.CrossAxisAlignment.center,
                  children: [
                    ["All", "Inbox", "Sent"].expand((tabItem) => [
                      TabButton({
                        title: tabItem,
                        selectedTab: this.selectedTab,
                        onClick: (selectedTabItem) => {
                          this.onPressedTabHandler(selectedTabItem, tabItem);
                        },
                        key: tabItem?.toString(),
                      }),
                    ]),
                  ],
                  className: "xc6e",
                  key: "2",
                }),
                ScrollView2({
                  child: ui.Column({
                    children: [
                      this.selectedTab === "All"
                        ? this.mails.expand((mail) => [
                            ui.Container({
                              margin: ui.EdgeInsets.fromLTRB(
                                0.0,
                                0.0,
                                0.0,
                                12.0,
                                new Map()
                              ),
                              width: 380,
                              child: MailStatusView({
                                mail: mail,
                                selectedEmail: this.selectedMail,
                                onMailClick: (email) => {
                                  this.onAllMessageClick(email, mail);
                                },
                              }),
                              key: mail?.ident,
                              className: "x46c hc",
                            }),
                          ])
                        : this.selectedTab === "Inbox"
                        ? this.mails
                            .where((m) => m.inbound)
                            .expand((mail) => [
                              ui.Container({
                                margin: ui.EdgeInsets.fromLTRB(
                                  0.0,
                                  0.0,
                                  0.0,
                                  12.0,
                                  new Map()
                                ),
                                width: 380,
                                child: MailStatusView({
                                  mail: mail,
                                  selectedEmail: this.selectedMail,
                                  onMailClick: (email) => {
                                    this.onInboxMessageClick(email, mail);
                                  },
                                }),
                                key: mail?.ident,
                                className: "xc0c hc",
                              }),
                            ])
                        : this.mails
                            .where((m) => !m.inbound)
                            .expand((mail) => [
                              ui.Container({
                                margin: ui.EdgeInsets.fromLTRB(
                                  0.0,
                                  0.0,
                                  0.0,
                                  12.0,
                                  new Map()
                                ),
                                width: 380,
                                child: MailStatusView({
                                  mail: mail,
                                  selectedEmail: this.selectedMail,
                                  onMailClick: (email) => {
                                    this.onSentMailButton(email, mail);
                                  },
                                }),
                                key: mail?.ident,
                                className: "xb97 hc",
                              }),
                            ]),
                    ],
                    key: "3",
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "xce82 vc v",
                  controller: this.columnScrollController,
                }),
              ],
              className: "xeb0 vc v",
              key: "0",
            }),
            ui.Container({
              child:
                this.selectedMail !== null
                  ? MailPreviewView({
                      message: this.selectedMail,
                      className: "x038b hc vc h v",
                    })
                  : ui.Column({
                      mainAxisAlignment: ui.MainAxisAlignment.center,
                      crossAxisAlignment: ui.CrossAxisAlignment.center,
                      children: [
                        ui.AssetImage({
                          path: "images/NoMail.png",
                          width: 200,
                          height: 200,
                          fit: ui.BoxFit.fill,
                          className: "xe1e7 hc vc",
                          key: "0",
                        }),
                        TextView({
                          data: "No Mail Selected",
                          className: "x1a4 hc",
                          key: "1",
                        }),
                      ],
                      className: "xc23 hc vc v",
                    }),
              className: "xa450 hc vc h v",
              key: "1",
            }),
          ],
          className: "x906 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "LeadMailsPage x8a11 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new LeadMailBoxRequest({ lead: this.lead, pageSize: 50, offset: 0 })
    );
  };
  public onSearchTapHandler = (value: string): void => {
    this.setSearchMail(value);

    this.request.setSearchMail(value);

    this.setRequest(this.request.deepClone());
  };
  public onAllMessageClick = (email: MailMessage, mail: MailMessage): void => {
    this.setSelectedMail(mail);
  };
  public onInboxMessageClick = (
    email: MailMessage,
    mail: MailMessage
  ): void => {
    this.setSelectedMail(mail);
  };
  public onSentMailButton = (email: MailMessage, mail: MailMessage): void => {
    this.setSelectedMail(mail);
  };
  public onFetchEmail = async (): Promise<void> => {
    this.setShowLoader(true);

    if (this.user.mAccount === null) {
      this.user.setMAccount(new MicroSoftAcc({ user: this.user }));

      let result: Result<MicroSoftAcc> = await this.user.mAccount.save();

      if (result.status === ResultStatus.Success) {
        this.openLink(this.user);
      } else {
        this.setShowLoader(false);

        EventBus.get().fire(
          new FailureMessage({
            message: "Something went wrong please try again",
          })
        );
      }
    } else {
      if (
        this.user.mAccount.accessToken === null ||
        this.user.mAccount.accessToken.isEmpty
      ) {
        this.openLink(this.user);
      } else if (
        this.user.mAccount.tokenExpersAt !== null &&
        this.user.mAccount.tokenExpersAt.isAfter(DateTime.now())
      ) {
        let result: boolean =
          await RPCServices.getLeadService().readEmailsFromLead(
            this.user.mAccount,
            this.lead
          );

        if (result) {
          EventBus.get().fire(
            new SuccessMessage({ message: "Successfully Fetched" })
          );
        } else {
          EventBus.get().fire(
            new FailureMessage({
              message: "Something went wrong please try again",
            })
          );
        }
      } else {
        let refreshAccess: boolean =
          await RPCServices.getLeadService().refreshAccessToken(
            this.user.mAccount
          );

        if (refreshAccess) {
          let result: boolean =
            await RPCServices.getLeadService().readEmailsFromLead(
              this.user.mAccount,
              this.lead
            );

          if (result) {
            EventBus.get().fire(
              new SuccessMessage({ message: "Successfully Fetched" })
            );
          } else {
            EventBus.get().fire(
              new FailureMessage({
                message: "Something went wrong please try again",
              })
            );
          }
        } else {
          this.openLink(this.user);
        }
      }
    }

    this.setRequest(this.request.deepClone());

    this.setShowLoader(false);
  };
  public openLink = async (user: User): Promise<void> => {
    let link: string = await RPCServices.getLeadService().createLink(
      user.mAccount
    );

    if (link !== null) {
      Browser.open(link);
    }
  };
  public onPressedTabHandler = (
    selectedTabItem: string,
    tabItem: string
  ): void => {
    this.setSelectedTab(tabItem);
  };
  public onBack = (): void => {
    this.navigator.pushConnectionsPage({
      user: this.user,
      target: "main",
      replace: false,
      client: this.client,
      data: this.data,
    });
  };
  public onEmailButtonHandler = (): void => {
    let subject: string = "Hi " + this.lead.name;

    let body: string = "Hi " + this.lead.name + ",\n\n";

    Browser.sendMailTo(this.lead.email, { subject: subject, body: body });
  };
  public dispose(): void {
    this.userDisposable?.dispose();

    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function LeadMailsPage(props: LeadMailsPageProps) {
  return React.createElement(_LeadMailsPageState, {
    ..._LeadMailsPageState.defaultProps,
    ...props,
  });
}
