import User from "./User";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class AllLeadsRequest extends DBObject {
  private static readonly _NUMBER: number = 0;
  private static readonly _USER: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _user: User = null;
  private _number: string = "";
  public constructor(d3eParams?: Partial<{ numberValue: string; user: User }>) {
    super();

    this.setNumberValue(d3eParams?.numberValue ?? "");

    this.setUser(d3eParams?.user ?? null);
  }
  public get d3eType(): string {
    return "AllLeadsRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get user(): User {
    return this._user;
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this._user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(AllLeadsRequest._USER, this._user);

    this.updateObservable("user", this._user, val);

    this._user = val;

    this.fire("user", this);
  }
  public get numberValue(): string {
    return this._number;
  }
  public setNumberValue(val: string): void {
    let isValChanged: boolean = this._number !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(AllLeadsRequest._NUMBER, this._number);

    this._number = val;

    this.fire("number", this);
  }
  public get(field: number): any {
    switch (field) {
      case AllLeadsRequest._USER: {
        return this._user;
      }

      case AllLeadsRequest._NUMBER: {
        return this._number;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): AllLeadsRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: AllLeadsRequest = dbObj as AllLeadsRequest;

    obj.id = this.id;

    obj.setUser(this._user);

    obj.setNumberValue(this._number);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case AllLeadsRequest._USER: {
        this.setUser(value as User);
        break;
      }

      case AllLeadsRequest._NUMBER: {
        this.setNumberValue(value as string);
        break;
      }
    }
  }
}
