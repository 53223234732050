import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import AddNoteButtonWidget from "./AddNoteButtonWidget";
import LabelWithInputField from "./LabelWithInputField";
import ResultStatus from "../classes/ResultStatus";
import D3EDate from "../classes/D3EDate";
import LabelWithDateField from "./LabelWithDateField";
import MaterialIcons from "../icons/MaterialIcons";
import EmailStatus from "../classes/EmailStatus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Education from "../models/Education";
import Result from "../classes/Result";
import Query from "../classes/Query";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import LeadAssignment from "../models/LeadAssignment";
import CheckServerErrors from "./CheckServerErrors";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import CancelSaveButtonsWidget from "./CancelSaveButtonsWidget";
import LeadStatus from "../classes/LeadStatus";
import TimeZoneUtil from "../classes/TimeZoneUtil";
import UsersListRequest from "../models/UsersListRequest";
import LabelDropdown from "./LabelDropdown";
import IconView from "./IconView";
import Divider from "./Divider";
import { Runnable } from "../classes/core";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _BasicViewOnSubmit = () => void;

type _RemoveButtonOnPressed = (d3eState: _EducationState) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: BasicViewRefs
) => void;

type _LabelDropdown2OnChanged = (text: User, d3eState: BasicViewRefs) => void;

type _LabelWithInputField3OnChanged = (
  text: string,
  d3eState: BasicViewRefs
) => void;

type _LabelWithInputField4OnChanged = (
  text: string,
  d3eState: BasicViewRefs
) => void;

type _LabelDropdown3OnChanged = (text: string, d3eState: BasicViewRefs) => void;

type _LabelWithInputField2OnChanged = (
  text: string,
  d3eState: _EducationState
) => void;

type _LabelWithInputField5OnChanged = (
  text: string,
  d3eState: _EducationState
) => void;

type _LabelWithInputField7OnChanged = (
  text: string,
  d3eState: _EducationState
) => void;

type _LabelWithDateField2OnChanged = (
  text: D3EDate,
  d3eState: _EducationState
) => void;

type _LabelWithDateField3OnChanged = (
  text: D3EDate,
  d3eState: _EducationState
) => void;

export interface BasicViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  leadAssignment: LeadAssignment;
  user: User;
  client: TwilioClient;
  data: CallData;
  isNewObj: boolean;
  onSubmit?: _BasicViewOnSubmit;
}
/// To store state data for BasicView
class BasicViewRefs {
  public educationState: Map<Education, _EducationState> = new Map();
  public forEducation(education: Education): _EducationState {
    let res = this.educationState.get(education);

    if (res == null) {
      res = new _EducationState(this, education);

      this.educationState.set(education, res);
    }

    return res;
  }
}

interface RemoveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _EducationState;
  _removeButtonHandler?: _RemoveButtonOnPressed;
}

class RemoveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _RemoveButtonWithState extends ObservableComponent<RemoveButtonWithStateProps> {
  removeButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RemoveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get removeButton(): RemoveButtonState {
    return this.props.d3eState.removeButton;
  }
  public get d3eState(): _EducationState {
    return this.props.d3eState;
  }
  public get _removeButtonHandler(): _RemoveButtonOnPressed {
    return this.props._removeButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("removeButton", null, this.removeButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["removeButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: ui.EdgeInsets.zero,
      disable: this.removeButton.disable,
      onPressed: () => {
        this._removeButtonHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        size: 24,
        icon: MaterialIcons.remove_circle_outline,
        color: cStyle.c1,
        className: "xd97",
      }),
    });
  }
}
function RemoveButtonWithState(props: RemoveButtonWithStateProps) {
  return React.createElement(_RemoveButtonWithState, props);
}

class _EducationState {
  parent: BasicViewRefs;
  education: Education;
  removeButton: RemoveButtonState = new RemoveButtonState();
  public constructor(parent, education) {
    this.parent = parent;

    this.education = education;
  }
}

class _BasicViewState extends ObservableComponent<BasicViewProps> {
  static defaultProps = {
    lead: null,
    leadAssignment: null,
    user: null,
    client: null,
    data: null,
    isNewObj: false,
    onSubmit: null,
  };
  d3eState: BasicViewRefs = new BasicViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  users: Array<User> = ListWrapper.widget(this, "users");
  isEducationDetails: boolean = false;
  isAssignedTo: boolean = false;
  isAdded: boolean = false;
  isNext: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _nameErrors: Array<string> = [];
  private _statusErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: BasicViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get leadAssignment(): LeadAssignment {
    return this.props.leadAssignment;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get isNewObj(): boolean {
    return this.props.isNewObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.computeUsers();

    this.updateSyncProperty("leadAssignment", this.props.leadAssignment);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      ["lead.leadAssignment", "lead.leadAssignment.assignedTo", "lead"],
      this.computeHandlingUser
    );

    this.computeHandlingUser();

    this.on(
      [
        "errors",
        "isAssignedTo",
        "isEducationDetails",
        "isNewObj",
        "lead",
        "lead.educationHistory",
        "lead.educationHistory.degree",
        "lead.educationHistory.endDate",
        "lead.educationHistory.fieldOfStudy",
        "lead.educationHistory.institution",
        "lead.educationHistory.startDate",
        "lead.email",
        "lead.name",
        "lead.phone",
        "lead.timeZone",
        "leadAssignment",
        "leadAssignment.assignedTo",
        "nameErrors",
        "statusErrors",
        "users",
        "users.firstName",
        "users.lastName",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: BasicViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.leadAssignment !== this.props.leadAssignment) {
      this.updateObservable(
        "leadAssignment",
        prevProps.leadAssignment,
        this.props.leadAssignment
      );

      this.fire("leadAssignment", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.isNewObj !== this.props.isNewObj) {
      this.fire("isNewObj", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setUsers(val: Array<User>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.users, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("users", this.users, val);

    this.users.clear();

    this.users.addAll(val);

    this.fire("users", this);
  }
  public addToUsers(val: User, index: number = -1): void {
    if (index === -1) {
      if (!this.users.contains(val)) this.users.add(val);
    } else {
      this.users.remove(this.users.elementAt(index));

      this.users.add(val);
    }

    this.fire("users", this, val, true);

    this.updateObservable("users", null, val);
  }
  public removeFromUsers(val: User): void {
    this.users.remove(val);

    this.fire("users", this, val, false);

    this.removeObservable("users", val);
  }
  public computeUsers = async (): Promise<void> => {
    try {
      this.setUsers(
        Array.from(
          (
            await Query.get().getUsersList(
              UsageConstants.QUERY_GETUSERSLIST_BASICVIEW_PROPERTIES_USERS_COMPUTATION,
              new UsersListRequest()
            )
          ).items
        )
      );
    } catch (exception) {
      console.log(" exception in computeUsers : " + exception.toString());

      this.setUsers([]);
    }
  };
  public setIsEducationDetails(val: boolean): void {
    let isValChanged: boolean = this.isEducationDetails !== val;

    if (!isValChanged) {
      return;
    }

    this.isEducationDetails = val;

    this.fire("isEducationDetails", this);
  }
  public setIsAssignedTo(val: boolean): void {
    let isValChanged: boolean = this.isAssignedTo !== val;

    if (!isValChanged) {
      return;
    }

    this.isAssignedTo = val;

    this.fire("isAssignedTo", this);
  }
  public setIsAdded(val: boolean): void {
    let isValChanged: boolean = this.isAdded !== val;

    if (!isValChanged) {
      return;
    }

    this.isAdded = val;

    this.fire("isAdded", this);
  }
  public setIsNext(val: boolean): void {
    let isValChanged: boolean = this.isNext !== val;

    if (!isValChanged) {
      return;
    }

    this.isNext = val;

    this.fire("isNext", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get nameErrors(): Array<string> {
    return this._nameErrors ?? [];
  }
  public set nameErrors(val: Array<string>) {
    this._nameErrors.clear();

    this._nameErrors.addAll(val);
  }
  public async computeNameErrors(): Promise<void> {
    this.nameErrors = [];

    let it: string = this.lead.name;

    if (it == null || it === "") {
      this.nameErrors.add("Name cannot be empty.");
    }

    this.fire("nameErrors", this);
  }
  public get statusErrors(): Array<string> {
    return this._statusErrors ?? [];
  }
  public set statusErrors(val: Array<string>) {
    this._statusErrors.clear();

    this._statusErrors.addAll(val);
  }
  public async computeStatusErrors(): Promise<void> {
    this.statusErrors = [];

    let it: LeadStatus = this.lead.status;

    this.fire("statusErrors", this);
  }
  public computeHandlingUser = (): void => {
    try {
      this.lead.setHandlingUser(
        this.lead.leadAssignment !== null
          ? this.lead.leadAssignment.assignedTo
          : null
      );
    } catch (exception) {
      console.log(
        " exception in computeHandlingUser : " + exception.toString()
      );

      this.lead.setHandlingUser(null);
    }
  };
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.nameErrors.isNotEmpty) {
      return false;
    }

    if (this.statusErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeNameErrors();

    errors.addAll(this.nameErrors);

    this.computeStatusErrors();

    errors.addAll(this.statusErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Lead Name",
                    placeHolder: "Enter Name",
                    value: this.lead.name,
                    isRequired: true,
                    errors: this.nameErrors,
                    onChanged: (text) => {
                      this.labelWithInputFieldonChanged(text, this.d3eState);
                    },
                  }),
                  key: "0",
                  className: "x78 hc h",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelDropdown<User>({
                    name: "Assigned To",
                    placeHolder: "-- Select--",
                    value: this.leadAssignment.assignedTo,
                    items: this.users,
                    width: 400,
                    isRequired: true,
                    errors: this.isAssignedTo
                      ? ["Assigned To is required"]
                      : [],
                    onChanged: (text) => {
                      this.labelDropdown2onChanged(text, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({
                        data: item.firstName + " " + item.lastName,
                      });
                    },
                  }),
                  key: "1",
                  className: "x5e8",
                }),
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Email Address",
                    placeHolder: "Enter Email Address",
                    value: this.lead.email,
                    onChanged: (text) => {
                      this.labelWithInputField3onChanged(text, this.d3eState);
                    },
                  }),
                  key: "2",
                  className: "x35 hc h",
                }),
              ],
              className: "xe10 hc h",
              key: "0",
            }),
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Phone Number ( Enter with country code )",
                    placeHolder: "Enter Phone Number",
                    value: this.lead.phone,
                    onChanged: (text) => {
                      this.labelWithInputField4onChanged(text, this.d3eState);
                    },
                  }),
                  key: "0",
                  className: "xdb0 hc h",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelDropdown<string>({
                    name: "Time Zone",
                    placeHolder: "-- Select--",
                    value: this.lead.timeZone,
                    items: TimeZoneUtil.allTimeZones(),
                    width: 400,
                    errors: this.statusErrors,
                    onChanged: (text) => {
                      this.labelDropdown3onChanged(text, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item });
                    },
                  }),
                  key: "1",
                  className: "xe3",
                }),
              ],
              className: "xcf hc h",
              key: "1",
            }),
            ui.Row({
              children: [
                TextView({
                  data: "Education Information",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                  }),
                  className: "x156",
                  key: "0",
                }),
                AddNoteButtonWidget({
                  isAdded: false,
                  onAddNote: (isAdded) => {
                    this.addButtonHandler(isAdded, this.d3eState);
                  },
                  key: "1",
                }),
                ui.Container({
                  expand: true,
                  child: Divider({}),
                  key: "2",
                  className: "xdbaa hc h",
                }),
              ],
              className: "x126 hc h",
              key: "2",
            }),
            this.isEducationDetails
              ? this.lead.educationHistory.expand((education) => [
                  ui.Column({
                    children: [
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.end,
                        crossAxisAlignment: ui.CrossAxisAlignment.start,
                        children: [
                          ToolTipWrapper({
                            message: "Remove",
                            backgroundColor: cStyle.tooltipBackgroundColor,
                            textColor: cStyle.tooltipTextColor,
                            child: RemoveButtonWithState({
                              d3eState: this.d3eState.forEducation(education),
                              _removeButtonHandler: this.removeButtonHandler,
                              key: "0",
                            }),
                          }),
                        ],
                        className: "xc0 hc h",
                        key: "0",
                      }),
                      ui.Row({
                        children: [
                          ui.Container({
                            expand: true,
                            child: LabelWithInputField({
                              name: "Institution",
                              placeHolder: "Enter Name",
                              value: education.institution,
                              onChanged: (text) => {
                                this.labelWithInputField2onChanged(
                                  text,
                                  this.d3eState.forEducation(education)
                                );
                              },
                            }),
                            key: "0",
                            className: "xc0a hc h",
                          }),
                          ui.Container({
                            expand: true,
                            margin: ui.EdgeInsets.symmetric({
                              horizontal: 15.0,
                              vertical: 0.0,
                              transitions: new Map(),
                            }),
                            child: LabelWithInputField({
                              name: "Qualification Name",
                              placeHolder: "Enter Qualification Name",
                              value: education.degree,
                              onChanged: (text) => {
                                this.labelWithInputField5onChanged(
                                  text,
                                  this.d3eState.forEducation(education)
                                );
                              },
                            }),
                            key: "1",
                            className: "x005 hc h",
                          }),
                          ui.Container({
                            expand: true,
                            child: LabelWithInputField({
                              name: "Field of Study",
                              placeHolder: "Enter Field of Study",
                              value: education.fieldOfStudy,
                              onChanged: (text) => {
                                this.labelWithInputField7onChanged(
                                  text,
                                  this.d3eState.forEducation(education)
                                );
                              },
                            }),
                            key: "2",
                            className: "xc6 hc h",
                          }),
                        ],
                        className: "x7b hc h",
                        key: "1",
                      }),
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.start,
                        crossAxisAlignment: ui.CrossAxisAlignment.start,
                        children: [
                          ui.Container({
                            expand: true,
                            child: LabelWithDateField({
                              name: "Start Date",
                              placeHolder: "-- Select Date --",
                              value: education.startDate,
                              onChanged: (text) => {
                                this.labelWithDateField2onChanged(
                                  text,
                                  this.d3eState.forEducation(education)
                                );
                              },
                            }),
                            key: "0",
                            className: "x1c3 hc h",
                          }),
                          ui.Container({
                            expand: true,
                            margin: ui.EdgeInsets.fromLTRB(
                              15.0,
                              0.0,
                              0.0,
                              0.0,
                              new Map()
                            ),
                            child: LabelWithDateField({
                              name: "End Date",
                              placeHolder: "-- Select Date --",
                              value: education.endDate,
                              onChanged: (text) => {
                                this.labelWithDateField3onChanged(
                                  text,
                                  this.d3eState.forEducation(education)
                                );
                              },
                            }),
                            key: "1",
                            className: "x696 hc h",
                          }),
                        ],
                        className: "x971 hc h",
                        key: "2",
                      }),
                      ui.Container({ className: "x8a4 hc vc", key: "3" }),
                    ],
                    className: "x947 hc h",
                    key: education?.ident,
                  }),
                ])
              : [],
          ],
          className: "xae1b hc h",
          key: "0",
        }),
        this.errors.isNotEmpty
          ? CheckServerErrors({ errors: this.errors, className: "x0d3 hc" })
          : [],
        CancelSaveButtonsWidget({
          title: "Next",
          isNewObj: this.isNewObj,
          onCancel: () => {
            this.onCancelHandler(this.d3eState);
          },
          onSave: () => {
            this.onSaveHandler(this.d3eState);
          },
          onSaveAndCancel: () => {
            this.saveAndCancelHandler(this.d3eState);
          },
          className: "x0c hc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "BasicView xb6cd hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    if (this.lead.educationHistory.isNotEmpty) {
      this.setIsEducationDetails(true);
    }
  };
  public onCancelHandler = (d3eState: BasicViewRefs): void => {
    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: false,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public saveAndCancelHandler = (d3eState: BasicViewRefs): void => {
    this.onSave();
  };
  public addButtonHandler = (
    isAdded: boolean,
    d3eState: BasicViewRefs
  ): void => {
    this.setIsAdded(true);

    isAdded = this.isAdded;

    let education: Education = new Education();

    this.lead.educationHistory.add(education);

    this.setIsEducationDetails(true);
  };
  public removeButtonHandler = (d3eState: _EducationState): void => {
    this.lead.educationHistory.remove(d3eState.education);

    if (this.lead.educationHistory.isEmpty) {
      this.setIsEducationDetails(false);
    }
  };
  public onSave = async (): Promise<void> => {
    this.setErrors([]);

    if (this.lead.email !== null || this.lead.email.isNotEmpty) {
      this.lead.setEmail(this.lead.email.toLowerCase());

      this.lead.setEmail(this.lead.email.replaceAll(" ", ""));
    }

    if (this.lead.phone !== null || this.lead.phone.isNotEmpty) {
      this.lead.setPhone(this.lead.phone.replaceAll(" ", ""));
    }

    this.lead.setEmailStatus(EmailStatus.Verified);

    let result: Result<Lead> = await this.lead.save();

    if (result.status === ResultStatus.Success) {
      this.leadAssignment.setLead(this.lead);

      this.leadAssignment.setAssignedDate(D3EDate.now());

      this.leadAssignment.setAssignedBy(this.user);

      if (this.leadAssignment.assignedTo === null) {
        this.setIsAssignedTo(true);
      } else {
        this.setIsAssignedTo(false);
      }

      let result2: Result<LeadAssignment> = await this.leadAssignment.save();

      if (result2.status === ResultStatus.Success) {
        if (this.isNext) {
          if (this.onSubmit !== null) {
            this.onSubmit();
          }
        } else {
          this.navigator.pushLeadManagementPage({
            user: this.user,
            replace: false,
            client: this.client,
            target: "main",
            data: this.data,
          });
        }
      } else {
        this.setErrors(result2.errors);
      }
    } else {
      this.setErrors(result.errors);
    }
  };
  public onSaveHandler = (d3eState: BasicViewRefs): void => {
    this.setIsNext(true);

    this.onSave();
  };
  public get onSubmit(): _BasicViewOnSubmit {
    return this.props.onSubmit;
  }
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: BasicViewRefs
  ): void => {
    this.lead.setName(val);
  };
  public labelDropdown2onChanged = (
    val: User,
    d3eState: BasicViewRefs
  ): void => {
    this.leadAssignment.setAssignedTo(val);
  };
  public labelWithInputField3onChanged = (
    val: string,
    d3eState: BasicViewRefs
  ): void => {
    this.lead.setEmail(val);
  };
  public labelWithInputField4onChanged = (
    val: string,
    d3eState: BasicViewRefs
  ): void => {
    this.lead.setPhone(val);
  };
  public labelDropdown3onChanged = (
    val: string,
    d3eState: BasicViewRefs
  ): void => {
    this.lead.setTimeZone(val);
  };
  public labelWithInputField2onChanged = (
    val: string,
    d3eState: _EducationState
  ): void => {
    d3eState.education.setInstitution(val);
  };
  public labelWithInputField5onChanged = (
    val: string,
    d3eState: _EducationState
  ): void => {
    d3eState.education.setDegree(val);
  };
  public labelWithInputField7onChanged = (
    val: string,
    d3eState: _EducationState
  ): void => {
    d3eState.education.setFieldOfStudy(val);
  };
  public labelWithDateField2onChanged = (
    val: D3EDate,
    d3eState: _EducationState
  ): void => {
    d3eState.education.setStartDate(val);
  };
  public labelWithDateField3onChanged = (
    val: D3EDate,
    d3eState: _EducationState
  ): void => {
    d3eState.education.setEndDate(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function BasicView(props: BasicViewProps) {
  return React.createElement(_BasicViewState, {
    ..._BasicViewState.defaultProps,
    ...props,
  });
}
