import * as ui from "../native";
import React, { ReactNode } from "react";
import { DomUtils } from "../utils/DomUtils";
import { BuildContext } from "../classes/BuildContext";

export class StyleThemeData {
  public readonly themeName: string;
  public readonly color: ui.Color;
  public readonly textStyle: ui.TextStyle;
  public readonly tooltipBackgroundColor: ui.Color;
  public readonly tooltipTextColor: ui.Color;
  public static LeadMgtTheme: StyleThemeData;
  public readonly c1: ui.Color;
  public readonly c2: ui.Color;
  public readonly c3: ui.Color;
  public readonly c4: ui.Color;
  public readonly c5: ui.Color;
  public readonly c6: ui.Color;
  public readonly c7: ui.Color;
  public readonly c8: ui.Color;
  public readonly c9: ui.Color;
  public readonly c10: ui.Color;
  public readonly c11: ui.Color;
  public readonly c12: ui.Color;
  public readonly c13: ui.Color;
  public readonly c14: ui.Color;
  public readonly c15: ui.Color;
  public readonly c16: ui.Color;
  public readonly c17: ui.Color;
  public readonly c18: ui.Color;
  public readonly c19: ui.Color;
  public readonly c21: ui.Color;
  public readonly c22: ui.Color;
  public readonly c23: ui.Color;
  public readonly tButtonAttachmentButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonAttachmentButtonPaddingOn: ui.EdgeInsets;
  public readonly tButtonAttachmentButtonConstraintsOn: ui.BoxConstraints;
  public readonly tButtonAttachmentButtonDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonAttachmentButtonPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonAttachmentButtonConstraintsOnHover: ui.BoxConstraints;
  public readonly tBaseBaseViewStyleDecorationOn: ui.BoxDecoration;
  public readonly tBaseBaseViewStylePaddingOn: ui.EdgeInsets;
  public readonly tTextViewBlueColorOn: ui.Color;
  public readonly tTextViewBlueFontSizeOn: number;
  public readonly tBaseBorderShadowStyleDecorationOn: ui.BoxDecoration;
  public readonly tButtonCallPrimaryDecorationOn: ui.BoxDecoration;
  public readonly tButtonCallPrimaryPaddingOn: ui.EdgeInsets;
  public readonly tButtonCallPrimaryTextStyleOn: ui.TextStyle;
  public readonly tButtonCallPrimaryConstraintsOn: ui.BoxConstraints;
  public readonly tButtonCallPrimaryDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonCallPrimaryPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonCallPrimaryConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonCallPrimaryTextStyleOnHover: ui.TextStyle;
  public readonly tBaseCardMarginMarginOn: ui.EdgeInsets;
  public readonly tBaseCardPaddingPaddingOn: ui.EdgeInsets;
  public readonly tBaseCardShadowDecorationOn: ui.BoxDecoration;
  public readonly tButtonDangerDecorationOn: ui.BoxDecoration;
  public readonly tButtonDangerPaddingOn: ui.EdgeInsets;
  public readonly tButtonDangerConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDangerTextStyleOn: ui.TextStyle;
  public readonly tButtonDangerDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDangerPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDangerConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDangerTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDangerOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonDangerOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonDangerOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonDangerOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDangerOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDangerOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDangerOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDarkDecorationOn: ui.BoxDecoration;
  public readonly tButtonDarkPaddingOn: ui.EdgeInsets;
  public readonly tButtonDarkConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDarkTextStyleOn: ui.TextStyle;
  public readonly tButtonDarkDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDarkPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDarkConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDarkTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDarkOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonDarkOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonDarkOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonDarkOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDarkOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDarkOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDarkOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tCardDefaultCardColorOn: ui.Color;
  public readonly tCardDefaultCardDecorationOn: ui.BoxDecoration;
  public readonly tDateFieldDefaultDateInputFieldHeightOn: number;
  public readonly tDateFieldDefaultDateInputFieldCornerRadiusOn: number;
  public readonly tDateTimeFieldDefaultDateTimeInputFieldHeightOn: number;
  public readonly tDateTimeFieldDefaultDateTimeInputFieldCornerRadiusOn: number;
  public readonly tDoubleInputFieldDefaultDoubleInputFieldHeightOn: number;
  public readonly tDoubleInputFieldDefaultDoubleInputFieldBackgroundColorOn: ui.Color;
  public readonly tDoubleInputFieldDefaultDoubleInputFieldCornerRadiusOn: number;
  public readonly tDoubleInputFieldDefaultDoubleInputFieldPaddingOn: ui.EdgeInsets;
  public readonly tDropDownDefaultDropdownDropDownDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDefaultDropdownPlaceHolderColorOn: ui.Color;
  public readonly tInputFieldDefaultInputFieldHeightOn: number;
  public readonly tInputFieldDefaultInputFieldBackgroundColorOn: ui.Color;
  public readonly tInputFieldDefaultInputFieldPlaceHolderColorOn: ui.Color;
  public readonly tInputFieldDefaultInputFieldInActiveColorOn: ui.Color;
  public readonly tInputFieldDefaultInputFieldActiveColorOn: ui.Color;
  public readonly tInputFieldDefaultInputFieldPaddingOn: ui.EdgeInsets;
  public readonly tInputFieldDefaultInputFieldCornerRadiusOn: number;
  public readonly tInputFieldDefaultInputFieldStyleOn: ui.TextStyle;
  public readonly tIntegerInputFieldDefaultIntegerInputFieldHeightOn: number;
  public readonly tIntegerInputFieldDefaultIntegerInputFieldBackgroundColorOn: ui.Color;
  public readonly tIntegerInputFieldDefaultIntegerInputFieldCornerRadiusOn: number;
  public readonly tIntegerInputFieldDefaultIntegerInputFieldPaddingOn: ui.EdgeInsets;
  public readonly tListTileDefaultListTileDecorationOnHover: ui.BoxDecoration;
  public readonly tListTileDefaultListTileDecorationOnFocused: ui.BoxDecoration;
  public readonly tListTileDefaultListTileDecorationOnSelected: ui.BoxDecoration;
  public readonly tBaseDefaultPopUpDecorationOn: ui.BoxDecoration;
  public readonly tTabBarDefaultTabBarBackgroundColorOn: ui.Color;
  public readonly tTabBarDefaultTabBarActiveColorOn: ui.Color;
  public readonly tTimeFieldDefaultTimeInputFieldHeightOn: number;
  public readonly tTimeFieldDefaultTimeInputFieldCornerRadiusOn: number;
  public readonly tButtonDialNumberDecorationOn: ui.BoxDecoration;
  public readonly tButtonDialNumberTextStyleOn: ui.TextStyle;
  public readonly tButtonDialNumberWidthOn: number;
  public readonly tButtonDialNumberHeightOn: number;
  public readonly tInputFieldDisabledHeightOn: number;
  public readonly tInputFieldDisabledBackgroundColorOn: ui.Color;
  public readonly tInputFieldDisabledCornerRadiusOn: number;
  public readonly tInputFieldDisabledStyleOn: ui.TextStyle;
  public readonly tInputFieldDisabledDisableOn: boolean;
  public readonly tTextViewDisplayHeadingFourFontSizeOn: number;
  public readonly tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingOneFontSizeOn: number;
  public readonly tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingThreeFontSizeOn: number;
  public readonly tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingTwoFontSizeOn: number;
  public readonly tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets;
  public readonly tDropDownDropdownWithAllBordersHeightOn: number;
  public readonly tDropDownDropdownWithAllBordersViewDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDropdownWithAllBordersDropDownDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDropdownWithAllBordersPlaceHolderColorOn: ui.Color;
  public readonly tTextViewErrorColorOn: ui.Color;
  public readonly tTextViewErrorTextAlignOn: ui.TextAlign;
  public readonly tTextViewErrorMarginOn: ui.EdgeInsets;
  public readonly tTextViewErrorLabelColorOn: ui.Color;
  public readonly tTextViewErrorLabelFontSizeOn: number;
  public readonly tTextViewErrorLabelMarginOn: ui.EdgeInsets;
  public readonly tTextViewErrorTextColorOn: ui.Color;
  public readonly tTextViewErrorTextTextAlignOn: ui.TextAlign;
  public readonly tTextViewErrorTextMarginOn: ui.EdgeInsets;
  public readonly tColumnFieldStyleCrossAxisAlignmentOn: ui.CrossAxisAlignment;
  public readonly tColumnFieldStyleMarginOn: ui.EdgeInsets;
  public readonly tTextViewHeadlineFiveFontSizeOn: number;
  public readonly tTextViewHeadlineFiveFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineFourFontSizeOn: number;
  public readonly tTextViewHeadlineFourFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineOneFontSizeOn: number;
  public readonly tTextViewHeadlineOneFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineSixFontSizeOn: number;
  public readonly tTextViewHeadlineSixFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineThreeFontSizeOn: number;
  public readonly tTextViewHeadlineThreeFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineTwoFontSizeOn: number;
  public readonly tTextViewHeadlineTwoFontWeightOn: ui.FontWeight;
  public readonly tTextViewLabelColorOn: ui.Color;
  public readonly tTextViewLabelTextAlignOn: ui.TextAlign;
  public readonly tTextViewLabelMarginOn: ui.EdgeInsets;
  public readonly tTextViewLabelTextFontSizeOn: number;
  public readonly tTextViewLabelTextFontWeightOn: ui.FontWeight;
  public readonly tTextViewLabelTextColorOn: ui.Color;
  public readonly tInputFieldLargeHeightOn: number;
  public readonly tInputFieldLargeBackgroundColorOn: ui.Color;
  public readonly tInputFieldLargeCornerRadiusOn: number;
  public readonly tInputFieldLargeStyleOn: ui.TextStyle;
  public readonly tButtonLightDecorationOn: ui.BoxDecoration;
  public readonly tButtonLightPaddingOn: ui.EdgeInsets;
  public readonly tButtonLightConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLightTextStyleOn: ui.TextStyle;
  public readonly tButtonLightDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLightPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLightConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLightTextStyleOnHover: ui.TextStyle;
  public readonly tBaseLightColorWithMarginDecorationOn: ui.BoxDecoration;
  public readonly tBaseLightColorWithMarginMarginOn: ui.EdgeInsets;
  public readonly tButtonLightOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonLightOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonLightOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonLightOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLightOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLightOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLightOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLightOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonLinkTextStyleOn: ui.TextStyle;
  public readonly tButtonLinkPaddingOn: ui.EdgeInsets;
  public readonly tButtonLinkConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLinkDecorationOn: ui.BoxDecoration;
  public readonly tButtonLinkPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLinkTextStyleOnHover: ui.TextStyle;
  public readonly tButtonLinkDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLinkConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLinkButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonLinkButtonTextStyleOnHover: ui.TextStyle;
  public readonly tListTileListTileDecorationOnHover: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOnFocused: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOnSelected: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOn: ui.BoxDecoration;
  public readonly tBaseListViewBgColorDecorationOn: ui.BoxDecoration;
  public readonly tBaseListViewBgColorMarginOn: ui.EdgeInsets;
  public readonly tButtonPrimaryDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryPaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOrangeDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOrangeConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOrangeOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tBaseScreenBgColorMarginOn: ui.EdgeInsets;
  public readonly tBaseScreenBgColorDecorationOn: ui.BoxDecoration;
  public readonly tBaseScreenBgColorPaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryDecorationOn: ui.BoxDecoration;
  public readonly tButtonSecondaryPaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSecondaryTextStyleOn: ui.TextStyle;
  public readonly tButtonSecondaryDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSecondaryPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSecondaryConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSecondaryTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSecondaryOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonSecondaryOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSecondaryOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSecondaryOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSecondaryOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tBaseSelectedTabContainerHeightOn: number;
  public readonly tBaseSelectedTabContainerWidthOn: number;
  public readonly tBaseSelectedTabContainerDecorationOn: ui.BoxDecoration;
  public readonly tBaseSelectedTabContainerMarginOn: ui.EdgeInsets;
  public readonly tBaseSidemenuBgDecorationOn: ui.BoxDecoration;
  public readonly tBaseSidemenuBgTextStyleOn: ui.TextStyle;
  public readonly tBaseSidemenuBgPaddingOn: ui.EdgeInsets;
  public readonly tInputFieldSmallHeightOn: number;
  public readonly tInputFieldSmallBackgroundColorOn: ui.Color;
  public readonly tInputFieldSmallCornerRadiusOn: number;
  public readonly tInputFieldSmallStyleOn: ui.TextStyle;
  public readonly tButtonSubSidemenuStylePaddingOn: ui.EdgeInsets;
  public readonly tButtonSubSidemenuStyleMarginOn: ui.EdgeInsets;
  public readonly tButtonSubSidemenuStyleDecorationOn: ui.BoxDecoration;
  public readonly tButtonSubSidemenuStyleTextStyleOn: ui.TextStyle;
  public readonly tButtonSubSidemenuStylePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSubSidemenuStyleDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSubSidemenuStyleTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSubSidemenuStyleDecorationOnSelected: ui.BoxDecoration;
  public readonly tButtonSubSidemenuStyleTextStyleOnSelected: ui.TextStyle;
  public readonly tButtonSubSidemenuStylePaddingOnSelected: ui.EdgeInsets;
  public readonly tButtonSuccessDecorationOn: ui.BoxDecoration;
  public readonly tButtonSuccessPaddingOn: ui.EdgeInsets;
  public readonly tButtonSuccessConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSuccessTextStyleOn: ui.TextStyle;
  public readonly tButtonSuccessDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSuccessPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSuccessConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSuccessTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSuccessOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonSuccessOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonSuccessOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonSuccessOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSuccessOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSuccessOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSuccessOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tBaseTable1DecorationOn: ui.BoxDecoration;
  public readonly tTableCellTableCellHeaderTextStyleOn: ui.TextStyle;
  public readonly tTableRowTableHeaderTextStyleOn: ui.TextStyle;
  public readonly tTableRowTableHeaderPaddingOn: ui.EdgeInsets;
  public readonly tTableRowTableRow1TextStyleOn: ui.TextStyle;
  public readonly tTableRowTableRow1PaddingOn: ui.EdgeInsets;
  public readonly tTableRowTableRow1DecorationOn: ui.BoxDecoration;
  public readonly tTableRowTableRow1PaddingOnHover: ui.EdgeInsets;
  public readonly tTableRowTableRow1DecorationOnHover: ui.BoxDecoration;
  public readonly tTableRowTableRow1TextStyleOnHover: ui.TextStyle;
  public readonly tBaseTopContainerPaddingOn: ui.EdgeInsets;
  public readonly tBaseUnselectedTabContainerHeightOn: number;
  public readonly tBaseUnselectedTabContainerWidthOn: number;
  public readonly tBaseUnselectedTabContainerDecorationOn: ui.BoxDecoration;
  public readonly tBaseUnselectedTabContainerMarginOn: ui.EdgeInsets;
  public readonly tButtonWarningDecorationOn: ui.BoxDecoration;
  public readonly tButtonWarningConstraintsOn: ui.BoxConstraints;
  public readonly tButtonWarningTextStyleOn: ui.TextStyle;
  public readonly tButtonWarningDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonWarningTextStyleOnHover: ui.TextStyle;
  public readonly tButtonWarningOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonWarningOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonWarningOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonWarningOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonWarningOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tInputFieldSearchInputFieldInActiveColorOn: ui.Color;
  public readonly tInputFieldSearchInputFieldPlaceHolderColorOn: ui.Color;
  public readonly tInputFieldSearchInputFieldActiveColorOn: ui.Color;
  public readonly tInputFieldSearchInputFieldPaddingOn: ui.EdgeInsets;
  public readonly tInputFieldSearchInputFieldCornerRadiusOn: number;
  public static current: StyleThemeData;
  public constructor(
    d3eParams?: Partial<{
      themeName: string;
      color: ui.Color;
      textStyle: ui.TextStyle;
      tooltipBackgroundColor: ui.Color;
      tooltipTextColor: ui.Color;
      tButtonAttachmentButtonDecorationOn: ui.BoxDecoration;
      tButtonAttachmentButtonPaddingOn: ui.EdgeInsets;
      tButtonAttachmentButtonConstraintsOn: ui.BoxConstraints;
      tButtonAttachmentButtonDecorationOnHover: ui.BoxDecoration;
      tButtonAttachmentButtonPaddingOnHover: ui.EdgeInsets;
      tButtonAttachmentButtonConstraintsOnHover: ui.BoxConstraints;
      tBaseBaseViewStyleDecorationOn: ui.BoxDecoration;
      tBaseBaseViewStylePaddingOn: ui.EdgeInsets;
      tTextViewBlueColorOn: ui.Color;
      tTextViewBlueFontSizeOn: number;
      tBaseBorderShadowStyleDecorationOn: ui.BoxDecoration;
      tButtonCallPrimaryDecorationOn: ui.BoxDecoration;
      tButtonCallPrimaryPaddingOn: ui.EdgeInsets;
      tButtonCallPrimaryTextStyleOn: ui.TextStyle;
      tButtonCallPrimaryConstraintsOn: ui.BoxConstraints;
      tButtonCallPrimaryDecorationOnHover: ui.BoxDecoration;
      tButtonCallPrimaryPaddingOnHover: ui.EdgeInsets;
      tButtonCallPrimaryConstraintsOnHover: ui.BoxConstraints;
      tButtonCallPrimaryTextStyleOnHover: ui.TextStyle;
      tBaseCardMarginMarginOn: ui.EdgeInsets;
      tBaseCardPaddingPaddingOn: ui.EdgeInsets;
      tBaseCardShadowDecorationOn: ui.BoxDecoration;
      tButtonDangerDecorationOn: ui.BoxDecoration;
      tButtonDangerPaddingOn: ui.EdgeInsets;
      tButtonDangerConstraintsOn: ui.BoxConstraints;
      tButtonDangerTextStyleOn: ui.TextStyle;
      tButtonDangerDecorationOnHover: ui.BoxDecoration;
      tButtonDangerPaddingOnHover: ui.EdgeInsets;
      tButtonDangerConstraintsOnHover: ui.BoxConstraints;
      tButtonDangerTextStyleOnHover: ui.TextStyle;
      tButtonDangerOutlineDecorationOn: ui.BoxDecoration;
      tButtonDangerOutlinePaddingOn: ui.EdgeInsets;
      tButtonDangerOutlineTextStyleOn: ui.TextStyle;
      tButtonDangerOutlineConstraintsOn: ui.BoxConstraints;
      tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonDangerOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonDangerOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonDangerOutlineTextStyleOnHover: ui.TextStyle;
      tButtonDarkDecorationOn: ui.BoxDecoration;
      tButtonDarkPaddingOn: ui.EdgeInsets;
      tButtonDarkConstraintsOn: ui.BoxConstraints;
      tButtonDarkTextStyleOn: ui.TextStyle;
      tButtonDarkDecorationOnHover: ui.BoxDecoration;
      tButtonDarkPaddingOnHover: ui.EdgeInsets;
      tButtonDarkConstraintsOnHover: ui.BoxConstraints;
      tButtonDarkTextStyleOnHover: ui.TextStyle;
      tButtonDarkOutlineDecorationOn: ui.BoxDecoration;
      tButtonDarkOutlinePaddingOn: ui.EdgeInsets;
      tButtonDarkOutlineTextStyleOn: ui.TextStyle;
      tButtonDarkOutlineConstraintsOn: ui.BoxConstraints;
      tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonDarkOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonDarkOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonDarkOutlineTextStyleOnHover: ui.TextStyle;
      tCardDefaultCardColorOn: ui.Color;
      tCardDefaultCardDecorationOn: ui.BoxDecoration;
      tDateFieldDefaultDateInputFieldHeightOn: number;
      tDateFieldDefaultDateInputFieldCornerRadiusOn: number;
      tDateTimeFieldDefaultDateTimeInputFieldHeightOn: number;
      tDateTimeFieldDefaultDateTimeInputFieldCornerRadiusOn: number;
      tDoubleInputFieldDefaultDoubleInputFieldHeightOn: number;
      tDoubleInputFieldDefaultDoubleInputFieldBackgroundColorOn: ui.Color;
      tDoubleInputFieldDefaultDoubleInputFieldCornerRadiusOn: number;
      tDoubleInputFieldDefaultDoubleInputFieldPaddingOn: ui.EdgeInsets;
      tDropDownDefaultDropdownDropDownDecorationOn: ui.BoxDecoration;
      tDropDownDefaultDropdownPlaceHolderColorOn: ui.Color;
      tInputFieldDefaultInputFieldHeightOn: number;
      tInputFieldDefaultInputFieldBackgroundColorOn: ui.Color;
      tInputFieldDefaultInputFieldPlaceHolderColorOn: ui.Color;
      tInputFieldDefaultInputFieldInActiveColorOn: ui.Color;
      tInputFieldDefaultInputFieldActiveColorOn: ui.Color;
      tInputFieldDefaultInputFieldPaddingOn: ui.EdgeInsets;
      tInputFieldDefaultInputFieldCornerRadiusOn: number;
      tInputFieldDefaultInputFieldStyleOn: ui.TextStyle;
      tIntegerInputFieldDefaultIntegerInputFieldHeightOn: number;
      tIntegerInputFieldDefaultIntegerInputFieldBackgroundColorOn: ui.Color;
      tIntegerInputFieldDefaultIntegerInputFieldCornerRadiusOn: number;
      tIntegerInputFieldDefaultIntegerInputFieldPaddingOn: ui.EdgeInsets;
      tListTileDefaultListTileDecorationOnHover: ui.BoxDecoration;
      tListTileDefaultListTileDecorationOnFocused: ui.BoxDecoration;
      tListTileDefaultListTileDecorationOnSelected: ui.BoxDecoration;
      tBaseDefaultPopUpDecorationOn: ui.BoxDecoration;
      tTabBarDefaultTabBarBackgroundColorOn: ui.Color;
      tTabBarDefaultTabBarActiveColorOn: ui.Color;
      tTimeFieldDefaultTimeInputFieldHeightOn: number;
      tTimeFieldDefaultTimeInputFieldCornerRadiusOn: number;
      tButtonDialNumberDecorationOn: ui.BoxDecoration;
      tButtonDialNumberTextStyleOn: ui.TextStyle;
      tButtonDialNumberWidthOn: number;
      tButtonDialNumberHeightOn: number;
      tInputFieldDisabledHeightOn: number;
      tInputFieldDisabledBackgroundColorOn: ui.Color;
      tInputFieldDisabledCornerRadiusOn: number;
      tInputFieldDisabledStyleOn: ui.TextStyle;
      tInputFieldDisabledDisableOn: boolean;
      tTextViewDisplayHeadingFourFontSizeOn: number;
      tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingOneFontSizeOn: number;
      tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingThreeFontSizeOn: number;
      tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingTwoFontSizeOn: number;
      tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets;
      tDropDownDropdownWithAllBordersHeightOn: number;
      tDropDownDropdownWithAllBordersViewDecorationOn: ui.BoxDecoration;
      tDropDownDropdownWithAllBordersDropDownDecorationOn: ui.BoxDecoration;
      tDropDownDropdownWithAllBordersPlaceHolderColorOn: ui.Color;
      tTextViewErrorColorOn: ui.Color;
      tTextViewErrorTextAlignOn: ui.TextAlign;
      tTextViewErrorMarginOn: ui.EdgeInsets;
      tTextViewErrorLabelColorOn: ui.Color;
      tTextViewErrorLabelFontSizeOn: number;
      tTextViewErrorLabelMarginOn: ui.EdgeInsets;
      tTextViewErrorTextColorOn: ui.Color;
      tTextViewErrorTextTextAlignOn: ui.TextAlign;
      tTextViewErrorTextMarginOn: ui.EdgeInsets;
      tColumnFieldStyleCrossAxisAlignmentOn: ui.CrossAxisAlignment;
      tColumnFieldStyleMarginOn: ui.EdgeInsets;
      tTextViewHeadlineFiveFontSizeOn: number;
      tTextViewHeadlineFiveFontWeightOn: ui.FontWeight;
      tTextViewHeadlineFourFontSizeOn: number;
      tTextViewHeadlineFourFontWeightOn: ui.FontWeight;
      tTextViewHeadlineOneFontSizeOn: number;
      tTextViewHeadlineOneFontWeightOn: ui.FontWeight;
      tTextViewHeadlineSixFontSizeOn: number;
      tTextViewHeadlineSixFontWeightOn: ui.FontWeight;
      tTextViewHeadlineThreeFontSizeOn: number;
      tTextViewHeadlineThreeFontWeightOn: ui.FontWeight;
      tTextViewHeadlineTwoFontSizeOn: number;
      tTextViewHeadlineTwoFontWeightOn: ui.FontWeight;
      tTextViewLabelColorOn: ui.Color;
      tTextViewLabelTextAlignOn: ui.TextAlign;
      tTextViewLabelMarginOn: ui.EdgeInsets;
      tTextViewLabelTextFontSizeOn: number;
      tTextViewLabelTextFontWeightOn: ui.FontWeight;
      tTextViewLabelTextColorOn: ui.Color;
      tInputFieldLargeHeightOn: number;
      tInputFieldLargeBackgroundColorOn: ui.Color;
      tInputFieldLargeCornerRadiusOn: number;
      tInputFieldLargeStyleOn: ui.TextStyle;
      tButtonLightDecorationOn: ui.BoxDecoration;
      tButtonLightPaddingOn: ui.EdgeInsets;
      tButtonLightConstraintsOn: ui.BoxConstraints;
      tButtonLightTextStyleOn: ui.TextStyle;
      tButtonLightDecorationOnHover: ui.BoxDecoration;
      tButtonLightPaddingOnHover: ui.EdgeInsets;
      tButtonLightConstraintsOnHover: ui.BoxConstraints;
      tButtonLightTextStyleOnHover: ui.TextStyle;
      tBaseLightColorWithMarginDecorationOn: ui.BoxDecoration;
      tBaseLightColorWithMarginMarginOn: ui.EdgeInsets;
      tButtonLightOutlineDecorationOn: ui.BoxDecoration;
      tButtonLightOutlinePaddingOn: ui.EdgeInsets;
      tButtonLightOutlineTextStyleOn: ui.TextStyle;
      tButtonLightOutlineConstraintsOn: ui.BoxConstraints;
      tButtonLightOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonLightOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonLightOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonLightOutlineTextStyleOnHover: ui.TextStyle;
      tButtonLinkTextStyleOn: ui.TextStyle;
      tButtonLinkPaddingOn: ui.EdgeInsets;
      tButtonLinkConstraintsOn: ui.BoxConstraints;
      tButtonLinkDecorationOn: ui.BoxDecoration;
      tButtonLinkPaddingOnHover: ui.EdgeInsets;
      tButtonLinkTextStyleOnHover: ui.TextStyle;
      tButtonLinkDecorationOnHover: ui.BoxDecoration;
      tButtonLinkConstraintsOnHover: ui.BoxConstraints;
      tButtonLinkButtonTextStyleOn: ui.TextStyle;
      tButtonLinkButtonTextStyleOnHover: ui.TextStyle;
      tListTileListTileDecorationOnHover: ui.BoxDecoration;
      tListTileListTileDecorationOnFocused: ui.BoxDecoration;
      tListTileListTileDecorationOnSelected: ui.BoxDecoration;
      tListTileListTileDecorationOn: ui.BoxDecoration;
      tBaseListViewBgColorDecorationOn: ui.BoxDecoration;
      tBaseListViewBgColorMarginOn: ui.EdgeInsets;
      tButtonPrimaryDecorationOn: ui.BoxDecoration;
      tButtonPrimaryPaddingOn: ui.EdgeInsets;
      tButtonPrimaryTextStyleOn: ui.TextStyle;
      tButtonPrimaryConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryPaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOrangeDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOrangePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOrangeConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOrangeTextStyleOn: ui.TextStyle;
      tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOrangeDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOrangeTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOrangeConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlineDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOrangeOutlineTextStyleOn: ui.TextStyle;
      tButtonPrimaryOrangeOutlineConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOrangeOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOrangeOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlineTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOutlineDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOutlineTextStyleOn: ui.TextStyle;
      tButtonPrimaryOutlineConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOutlineTextStyleOnHover: ui.TextStyle;
      tBaseScreenBgColorMarginOn: ui.EdgeInsets;
      tBaseScreenBgColorDecorationOn: ui.BoxDecoration;
      tBaseScreenBgColorPaddingOn: ui.EdgeInsets;
      tButtonSecondaryDecorationOn: ui.BoxDecoration;
      tButtonSecondaryPaddingOn: ui.EdgeInsets;
      tButtonSecondaryConstraintsOn: ui.BoxConstraints;
      tButtonSecondaryTextStyleOn: ui.TextStyle;
      tButtonSecondaryDecorationOnHover: ui.BoxDecoration;
      tButtonSecondaryPaddingOnHover: ui.EdgeInsets;
      tButtonSecondaryConstraintsOnHover: ui.BoxConstraints;
      tButtonSecondaryTextStyleOnHover: ui.TextStyle;
      tButtonSecondaryOutlineDecorationOn: ui.BoxDecoration;
      tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets;
      tButtonSecondaryOutlineTextStyleOn: ui.TextStyle;
      tButtonSecondaryOutlineConstraintsOn: ui.BoxConstraints;
      tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonSecondaryOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonSecondaryOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonSecondaryOutlineTextStyleOnHover: ui.TextStyle;
      tBaseSelectedTabContainerHeightOn: number;
      tBaseSelectedTabContainerWidthOn: number;
      tBaseSelectedTabContainerDecorationOn: ui.BoxDecoration;
      tBaseSelectedTabContainerMarginOn: ui.EdgeInsets;
      tBaseSidemenuBgDecorationOn: ui.BoxDecoration;
      tBaseSidemenuBgTextStyleOn: ui.TextStyle;
      tBaseSidemenuBgPaddingOn: ui.EdgeInsets;
      tInputFieldSmallHeightOn: number;
      tInputFieldSmallBackgroundColorOn: ui.Color;
      tInputFieldSmallCornerRadiusOn: number;
      tInputFieldSmallStyleOn: ui.TextStyle;
      tButtonSubSidemenuStylePaddingOn: ui.EdgeInsets;
      tButtonSubSidemenuStyleMarginOn: ui.EdgeInsets;
      tButtonSubSidemenuStyleDecorationOn: ui.BoxDecoration;
      tButtonSubSidemenuStyleTextStyleOn: ui.TextStyle;
      tButtonSubSidemenuStylePaddingOnHover: ui.EdgeInsets;
      tButtonSubSidemenuStyleDecorationOnHover: ui.BoxDecoration;
      tButtonSubSidemenuStyleTextStyleOnHover: ui.TextStyle;
      tButtonSubSidemenuStyleDecorationOnSelected: ui.BoxDecoration;
      tButtonSubSidemenuStyleTextStyleOnSelected: ui.TextStyle;
      tButtonSubSidemenuStylePaddingOnSelected: ui.EdgeInsets;
      tButtonSuccessDecorationOn: ui.BoxDecoration;
      tButtonSuccessPaddingOn: ui.EdgeInsets;
      tButtonSuccessConstraintsOn: ui.BoxConstraints;
      tButtonSuccessTextStyleOn: ui.TextStyle;
      tButtonSuccessDecorationOnHover: ui.BoxDecoration;
      tButtonSuccessPaddingOnHover: ui.EdgeInsets;
      tButtonSuccessConstraintsOnHover: ui.BoxConstraints;
      tButtonSuccessTextStyleOnHover: ui.TextStyle;
      tButtonSuccessOutlineDecorationOn: ui.BoxDecoration;
      tButtonSuccessOutlinePaddingOn: ui.EdgeInsets;
      tButtonSuccessOutlineTextStyleOn: ui.TextStyle;
      tButtonSuccessOutlineConstraintsOn: ui.BoxConstraints;
      tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonSuccessOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonSuccessOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonSuccessOutlineTextStyleOnHover: ui.TextStyle;
      tBaseTable1DecorationOn: ui.BoxDecoration;
      tTableCellTableCellHeaderTextStyleOn: ui.TextStyle;
      tTableRowTableHeaderTextStyleOn: ui.TextStyle;
      tTableRowTableHeaderPaddingOn: ui.EdgeInsets;
      tTableRowTableRow1TextStyleOn: ui.TextStyle;
      tTableRowTableRow1PaddingOn: ui.EdgeInsets;
      tTableRowTableRow1DecorationOn: ui.BoxDecoration;
      tTableRowTableRow1PaddingOnHover: ui.EdgeInsets;
      tTableRowTableRow1DecorationOnHover: ui.BoxDecoration;
      tTableRowTableRow1TextStyleOnHover: ui.TextStyle;
      tBaseTopContainerPaddingOn: ui.EdgeInsets;
      tBaseUnselectedTabContainerHeightOn: number;
      tBaseUnselectedTabContainerWidthOn: number;
      tBaseUnselectedTabContainerDecorationOn: ui.BoxDecoration;
      tBaseUnselectedTabContainerMarginOn: ui.EdgeInsets;
      tButtonWarningDecorationOn: ui.BoxDecoration;
      tButtonWarningConstraintsOn: ui.BoxConstraints;
      tButtonWarningTextStyleOn: ui.TextStyle;
      tButtonWarningDecorationOnHover: ui.BoxDecoration;
      tButtonWarningTextStyleOnHover: ui.TextStyle;
      tButtonWarningOutlineDecorationOn: ui.BoxDecoration;
      tButtonWarningOutlineTextStyleOn: ui.TextStyle;
      tButtonWarningOutlineConstraintsOn: ui.BoxConstraints;
      tButtonWarningOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonWarningOutlineTextStyleOnHover: ui.TextStyle;
      tInputFieldSearchInputFieldInActiveColorOn: ui.Color;
      tInputFieldSearchInputFieldPlaceHolderColorOn: ui.Color;
      tInputFieldSearchInputFieldActiveColorOn: ui.Color;
      tInputFieldSearchInputFieldPaddingOn: ui.EdgeInsets;
      tInputFieldSearchInputFieldCornerRadiusOn: number;
      c1: ui.Color;
      c2: ui.Color;
      c3: ui.Color;
      c4: ui.Color;
      c5: ui.Color;
      c6: ui.Color;
      c7: ui.Color;
      c8: ui.Color;
      c9: ui.Color;
      c10: ui.Color;
      c11: ui.Color;
      c12: ui.Color;
      c13: ui.Color;
      c14: ui.Color;
      c15: ui.Color;
      c16: ui.Color;
      c17: ui.Color;
      c18: ui.Color;
      c19: ui.Color;
      c21: ui.Color;
      c22: ui.Color;
      c23: ui.Color;
    }>
  ) {
    this.themeName = d3eParams?.themeName;

    this.color = d3eParams?.color;

    this.textStyle = d3eParams?.textStyle;

    this.tooltipBackgroundColor = d3eParams?.tooltipBackgroundColor;

    this.tooltipTextColor = d3eParams?.tooltipTextColor;

    this.tButtonAttachmentButtonDecorationOn =
      d3eParams?.tButtonAttachmentButtonDecorationOn;

    this.tButtonAttachmentButtonPaddingOn =
      d3eParams?.tButtonAttachmentButtonPaddingOn;

    this.tButtonAttachmentButtonConstraintsOn =
      d3eParams?.tButtonAttachmentButtonConstraintsOn;

    this.tButtonAttachmentButtonDecorationOnHover =
      d3eParams?.tButtonAttachmentButtonDecorationOnHover;

    this.tButtonAttachmentButtonPaddingOnHover =
      d3eParams?.tButtonAttachmentButtonPaddingOnHover;

    this.tButtonAttachmentButtonConstraintsOnHover =
      d3eParams?.tButtonAttachmentButtonConstraintsOnHover;

    this.tBaseBaseViewStyleDecorationOn =
      d3eParams?.tBaseBaseViewStyleDecorationOn;

    this.tBaseBaseViewStylePaddingOn = d3eParams?.tBaseBaseViewStylePaddingOn;

    this.tTextViewBlueColorOn = d3eParams?.tTextViewBlueColorOn;

    this.tTextViewBlueFontSizeOn = d3eParams?.tTextViewBlueFontSizeOn;

    this.tBaseBorderShadowStyleDecorationOn =
      d3eParams?.tBaseBorderShadowStyleDecorationOn;

    this.tButtonCallPrimaryDecorationOn =
      d3eParams?.tButtonCallPrimaryDecorationOn;

    this.tButtonCallPrimaryPaddingOn = d3eParams?.tButtonCallPrimaryPaddingOn;

    this.tButtonCallPrimaryTextStyleOn =
      d3eParams?.tButtonCallPrimaryTextStyleOn;

    this.tButtonCallPrimaryConstraintsOn =
      d3eParams?.tButtonCallPrimaryConstraintsOn;

    this.tButtonCallPrimaryDecorationOnHover =
      d3eParams?.tButtonCallPrimaryDecorationOnHover;

    this.tButtonCallPrimaryPaddingOnHover =
      d3eParams?.tButtonCallPrimaryPaddingOnHover;

    this.tButtonCallPrimaryConstraintsOnHover =
      d3eParams?.tButtonCallPrimaryConstraintsOnHover;

    this.tButtonCallPrimaryTextStyleOnHover =
      d3eParams?.tButtonCallPrimaryTextStyleOnHover;

    this.tBaseCardMarginMarginOn = d3eParams?.tBaseCardMarginMarginOn;

    this.tBaseCardPaddingPaddingOn = d3eParams?.tBaseCardPaddingPaddingOn;

    this.tBaseCardShadowDecorationOn = d3eParams?.tBaseCardShadowDecorationOn;

    this.tButtonDangerDecorationOn = d3eParams?.tButtonDangerDecorationOn;

    this.tButtonDangerPaddingOn = d3eParams?.tButtonDangerPaddingOn;

    this.tButtonDangerConstraintsOn = d3eParams?.tButtonDangerConstraintsOn;

    this.tButtonDangerTextStyleOn = d3eParams?.tButtonDangerTextStyleOn;

    this.tButtonDangerDecorationOnHover =
      d3eParams?.tButtonDangerDecorationOnHover;

    this.tButtonDangerPaddingOnHover = d3eParams?.tButtonDangerPaddingOnHover;

    this.tButtonDangerConstraintsOnHover =
      d3eParams?.tButtonDangerConstraintsOnHover;

    this.tButtonDangerTextStyleOnHover =
      d3eParams?.tButtonDangerTextStyleOnHover;

    this.tButtonDangerOutlineDecorationOn =
      d3eParams?.tButtonDangerOutlineDecorationOn;

    this.tButtonDangerOutlinePaddingOn =
      d3eParams?.tButtonDangerOutlinePaddingOn;

    this.tButtonDangerOutlineTextStyleOn =
      d3eParams?.tButtonDangerOutlineTextStyleOn;

    this.tButtonDangerOutlineConstraintsOn =
      d3eParams?.tButtonDangerOutlineConstraintsOn;

    this.tButtonDangerOutlinePaddingOnHover =
      d3eParams?.tButtonDangerOutlinePaddingOnHover;

    this.tButtonDangerOutlineDecorationOnHover =
      d3eParams?.tButtonDangerOutlineDecorationOnHover;

    this.tButtonDangerOutlineConstraintsOnHover =
      d3eParams?.tButtonDangerOutlineConstraintsOnHover;

    this.tButtonDangerOutlineTextStyleOnHover =
      d3eParams?.tButtonDangerOutlineTextStyleOnHover;

    this.tButtonDarkDecorationOn = d3eParams?.tButtonDarkDecorationOn;

    this.tButtonDarkPaddingOn = d3eParams?.tButtonDarkPaddingOn;

    this.tButtonDarkConstraintsOn = d3eParams?.tButtonDarkConstraintsOn;

    this.tButtonDarkTextStyleOn = d3eParams?.tButtonDarkTextStyleOn;

    this.tButtonDarkDecorationOnHover = d3eParams?.tButtonDarkDecorationOnHover;

    this.tButtonDarkPaddingOnHover = d3eParams?.tButtonDarkPaddingOnHover;

    this.tButtonDarkConstraintsOnHover =
      d3eParams?.tButtonDarkConstraintsOnHover;

    this.tButtonDarkTextStyleOnHover = d3eParams?.tButtonDarkTextStyleOnHover;

    this.tButtonDarkOutlineDecorationOn =
      d3eParams?.tButtonDarkOutlineDecorationOn;

    this.tButtonDarkOutlinePaddingOn = d3eParams?.tButtonDarkOutlinePaddingOn;

    this.tButtonDarkOutlineTextStyleOn =
      d3eParams?.tButtonDarkOutlineTextStyleOn;

    this.tButtonDarkOutlineConstraintsOn =
      d3eParams?.tButtonDarkOutlineConstraintsOn;

    this.tButtonDarkOutlinePaddingOnHover =
      d3eParams?.tButtonDarkOutlinePaddingOnHover;

    this.tButtonDarkOutlineDecorationOnHover =
      d3eParams?.tButtonDarkOutlineDecorationOnHover;

    this.tButtonDarkOutlineConstraintsOnHover =
      d3eParams?.tButtonDarkOutlineConstraintsOnHover;

    this.tButtonDarkOutlineTextStyleOnHover =
      d3eParams?.tButtonDarkOutlineTextStyleOnHover;

    this.tCardDefaultCardColorOn = d3eParams?.tCardDefaultCardColorOn;

    this.tCardDefaultCardDecorationOn = d3eParams?.tCardDefaultCardDecorationOn;

    this.tDateFieldDefaultDateInputFieldHeightOn =
      d3eParams?.tDateFieldDefaultDateInputFieldHeightOn;

    this.tDateFieldDefaultDateInputFieldCornerRadiusOn =
      d3eParams?.tDateFieldDefaultDateInputFieldCornerRadiusOn;

    this.tDateTimeFieldDefaultDateTimeInputFieldHeightOn =
      d3eParams?.tDateTimeFieldDefaultDateTimeInputFieldHeightOn;

    this.tDateTimeFieldDefaultDateTimeInputFieldCornerRadiusOn =
      d3eParams?.tDateTimeFieldDefaultDateTimeInputFieldCornerRadiusOn;

    this.tDoubleInputFieldDefaultDoubleInputFieldHeightOn =
      d3eParams?.tDoubleInputFieldDefaultDoubleInputFieldHeightOn;

    this.tDoubleInputFieldDefaultDoubleInputFieldBackgroundColorOn =
      d3eParams?.tDoubleInputFieldDefaultDoubleInputFieldBackgroundColorOn;

    this.tDoubleInputFieldDefaultDoubleInputFieldCornerRadiusOn =
      d3eParams?.tDoubleInputFieldDefaultDoubleInputFieldCornerRadiusOn;

    this.tDoubleInputFieldDefaultDoubleInputFieldPaddingOn =
      d3eParams?.tDoubleInputFieldDefaultDoubleInputFieldPaddingOn;

    this.tDropDownDefaultDropdownDropDownDecorationOn =
      d3eParams?.tDropDownDefaultDropdownDropDownDecorationOn;

    this.tDropDownDefaultDropdownPlaceHolderColorOn =
      d3eParams?.tDropDownDefaultDropdownPlaceHolderColorOn;

    this.tInputFieldDefaultInputFieldHeightOn =
      d3eParams?.tInputFieldDefaultInputFieldHeightOn;

    this.tInputFieldDefaultInputFieldBackgroundColorOn =
      d3eParams?.tInputFieldDefaultInputFieldBackgroundColorOn;

    this.tInputFieldDefaultInputFieldPlaceHolderColorOn =
      d3eParams?.tInputFieldDefaultInputFieldPlaceHolderColorOn;

    this.tInputFieldDefaultInputFieldInActiveColorOn =
      d3eParams?.tInputFieldDefaultInputFieldInActiveColorOn;

    this.tInputFieldDefaultInputFieldActiveColorOn =
      d3eParams?.tInputFieldDefaultInputFieldActiveColorOn;

    this.tInputFieldDefaultInputFieldPaddingOn =
      d3eParams?.tInputFieldDefaultInputFieldPaddingOn;

    this.tInputFieldDefaultInputFieldCornerRadiusOn =
      d3eParams?.tInputFieldDefaultInputFieldCornerRadiusOn;

    this.tInputFieldDefaultInputFieldStyleOn =
      d3eParams?.tInputFieldDefaultInputFieldStyleOn;

    this.tIntegerInputFieldDefaultIntegerInputFieldHeightOn =
      d3eParams?.tIntegerInputFieldDefaultIntegerInputFieldHeightOn;

    this.tIntegerInputFieldDefaultIntegerInputFieldBackgroundColorOn =
      d3eParams?.tIntegerInputFieldDefaultIntegerInputFieldBackgroundColorOn;

    this.tIntegerInputFieldDefaultIntegerInputFieldCornerRadiusOn =
      d3eParams?.tIntegerInputFieldDefaultIntegerInputFieldCornerRadiusOn;

    this.tIntegerInputFieldDefaultIntegerInputFieldPaddingOn =
      d3eParams?.tIntegerInputFieldDefaultIntegerInputFieldPaddingOn;

    this.tListTileDefaultListTileDecorationOnHover =
      d3eParams?.tListTileDefaultListTileDecorationOnHover;

    this.tListTileDefaultListTileDecorationOnFocused =
      d3eParams?.tListTileDefaultListTileDecorationOnFocused;

    this.tListTileDefaultListTileDecorationOnSelected =
      d3eParams?.tListTileDefaultListTileDecorationOnSelected;

    this.tBaseDefaultPopUpDecorationOn =
      d3eParams?.tBaseDefaultPopUpDecorationOn;

    this.tTabBarDefaultTabBarBackgroundColorOn =
      d3eParams?.tTabBarDefaultTabBarBackgroundColorOn;

    this.tTabBarDefaultTabBarActiveColorOn =
      d3eParams?.tTabBarDefaultTabBarActiveColorOn;

    this.tTimeFieldDefaultTimeInputFieldHeightOn =
      d3eParams?.tTimeFieldDefaultTimeInputFieldHeightOn;

    this.tTimeFieldDefaultTimeInputFieldCornerRadiusOn =
      d3eParams?.tTimeFieldDefaultTimeInputFieldCornerRadiusOn;

    this.tButtonDialNumberDecorationOn =
      d3eParams?.tButtonDialNumberDecorationOn;

    this.tButtonDialNumberTextStyleOn = d3eParams?.tButtonDialNumberTextStyleOn;

    this.tButtonDialNumberWidthOn = d3eParams?.tButtonDialNumberWidthOn;

    this.tButtonDialNumberHeightOn = d3eParams?.tButtonDialNumberHeightOn;

    this.tInputFieldDisabledHeightOn = d3eParams?.tInputFieldDisabledHeightOn;

    this.tInputFieldDisabledBackgroundColorOn =
      d3eParams?.tInputFieldDisabledBackgroundColorOn;

    this.tInputFieldDisabledCornerRadiusOn =
      d3eParams?.tInputFieldDisabledCornerRadiusOn;

    this.tInputFieldDisabledStyleOn = d3eParams?.tInputFieldDisabledStyleOn;

    this.tInputFieldDisabledDisableOn = d3eParams?.tInputFieldDisabledDisableOn;

    this.tTextViewDisplayHeadingFourFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingFourFontSizeOn;

    this.tTextViewDisplayHeadingFourMarginOn =
      d3eParams?.tTextViewDisplayHeadingFourMarginOn;

    this.tTextViewDisplayHeadingOneFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingOneFontSizeOn;

    this.tTextViewDisplayHeadingOneMarginOn =
      d3eParams?.tTextViewDisplayHeadingOneMarginOn;

    this.tTextViewDisplayHeadingThreeFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingThreeFontSizeOn;

    this.tTextViewDisplayHeadingThreeMarginOn =
      d3eParams?.tTextViewDisplayHeadingThreeMarginOn;

    this.tTextViewDisplayHeadingTwoFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingTwoFontSizeOn;

    this.tTextViewDisplayHeadingTwoMarginOn =
      d3eParams?.tTextViewDisplayHeadingTwoMarginOn;

    this.tDropDownDropdownWithAllBordersHeightOn =
      d3eParams?.tDropDownDropdownWithAllBordersHeightOn;

    this.tDropDownDropdownWithAllBordersViewDecorationOn =
      d3eParams?.tDropDownDropdownWithAllBordersViewDecorationOn;

    this.tDropDownDropdownWithAllBordersDropDownDecorationOn =
      d3eParams?.tDropDownDropdownWithAllBordersDropDownDecorationOn;

    this.tDropDownDropdownWithAllBordersPlaceHolderColorOn =
      d3eParams?.tDropDownDropdownWithAllBordersPlaceHolderColorOn;

    this.tTextViewErrorColorOn = d3eParams?.tTextViewErrorColorOn;

    this.tTextViewErrorTextAlignOn = d3eParams?.tTextViewErrorTextAlignOn;

    this.tTextViewErrorMarginOn = d3eParams?.tTextViewErrorMarginOn;

    this.tTextViewErrorLabelColorOn = d3eParams?.tTextViewErrorLabelColorOn;

    this.tTextViewErrorLabelFontSizeOn =
      d3eParams?.tTextViewErrorLabelFontSizeOn;

    this.tTextViewErrorLabelMarginOn = d3eParams?.tTextViewErrorLabelMarginOn;

    this.tTextViewErrorTextColorOn = d3eParams?.tTextViewErrorTextColorOn;

    this.tTextViewErrorTextTextAlignOn =
      d3eParams?.tTextViewErrorTextTextAlignOn;

    this.tTextViewErrorTextMarginOn = d3eParams?.tTextViewErrorTextMarginOn;

    this.tColumnFieldStyleCrossAxisAlignmentOn =
      d3eParams?.tColumnFieldStyleCrossAxisAlignmentOn;

    this.tColumnFieldStyleMarginOn = d3eParams?.tColumnFieldStyleMarginOn;

    this.tTextViewHeadlineFiveFontSizeOn =
      d3eParams?.tTextViewHeadlineFiveFontSizeOn;

    this.tTextViewHeadlineFiveFontWeightOn =
      d3eParams?.tTextViewHeadlineFiveFontWeightOn;

    this.tTextViewHeadlineFourFontSizeOn =
      d3eParams?.tTextViewHeadlineFourFontSizeOn;

    this.tTextViewHeadlineFourFontWeightOn =
      d3eParams?.tTextViewHeadlineFourFontWeightOn;

    this.tTextViewHeadlineOneFontSizeOn =
      d3eParams?.tTextViewHeadlineOneFontSizeOn;

    this.tTextViewHeadlineOneFontWeightOn =
      d3eParams?.tTextViewHeadlineOneFontWeightOn;

    this.tTextViewHeadlineSixFontSizeOn =
      d3eParams?.tTextViewHeadlineSixFontSizeOn;

    this.tTextViewHeadlineSixFontWeightOn =
      d3eParams?.tTextViewHeadlineSixFontWeightOn;

    this.tTextViewHeadlineThreeFontSizeOn =
      d3eParams?.tTextViewHeadlineThreeFontSizeOn;

    this.tTextViewHeadlineThreeFontWeightOn =
      d3eParams?.tTextViewHeadlineThreeFontWeightOn;

    this.tTextViewHeadlineTwoFontSizeOn =
      d3eParams?.tTextViewHeadlineTwoFontSizeOn;

    this.tTextViewHeadlineTwoFontWeightOn =
      d3eParams?.tTextViewHeadlineTwoFontWeightOn;

    this.tTextViewLabelColorOn = d3eParams?.tTextViewLabelColorOn;

    this.tTextViewLabelTextAlignOn = d3eParams?.tTextViewLabelTextAlignOn;

    this.tTextViewLabelMarginOn = d3eParams?.tTextViewLabelMarginOn;

    this.tTextViewLabelTextFontSizeOn = d3eParams?.tTextViewLabelTextFontSizeOn;

    this.tTextViewLabelTextFontWeightOn =
      d3eParams?.tTextViewLabelTextFontWeightOn;

    this.tTextViewLabelTextColorOn = d3eParams?.tTextViewLabelTextColorOn;

    this.tInputFieldLargeHeightOn = d3eParams?.tInputFieldLargeHeightOn;

    this.tInputFieldLargeBackgroundColorOn =
      d3eParams?.tInputFieldLargeBackgroundColorOn;

    this.tInputFieldLargeCornerRadiusOn =
      d3eParams?.tInputFieldLargeCornerRadiusOn;

    this.tInputFieldLargeStyleOn = d3eParams?.tInputFieldLargeStyleOn;

    this.tButtonLightDecorationOn = d3eParams?.tButtonLightDecorationOn;

    this.tButtonLightPaddingOn = d3eParams?.tButtonLightPaddingOn;

    this.tButtonLightConstraintsOn = d3eParams?.tButtonLightConstraintsOn;

    this.tButtonLightTextStyleOn = d3eParams?.tButtonLightTextStyleOn;

    this.tButtonLightDecorationOnHover =
      d3eParams?.tButtonLightDecorationOnHover;

    this.tButtonLightPaddingOnHover = d3eParams?.tButtonLightPaddingOnHover;

    this.tButtonLightConstraintsOnHover =
      d3eParams?.tButtonLightConstraintsOnHover;

    this.tButtonLightTextStyleOnHover = d3eParams?.tButtonLightTextStyleOnHover;

    this.tBaseLightColorWithMarginDecorationOn =
      d3eParams?.tBaseLightColorWithMarginDecorationOn;

    this.tBaseLightColorWithMarginMarginOn =
      d3eParams?.tBaseLightColorWithMarginMarginOn;

    this.tButtonLightOutlineDecorationOn =
      d3eParams?.tButtonLightOutlineDecorationOn;

    this.tButtonLightOutlinePaddingOn = d3eParams?.tButtonLightOutlinePaddingOn;

    this.tButtonLightOutlineTextStyleOn =
      d3eParams?.tButtonLightOutlineTextStyleOn;

    this.tButtonLightOutlineConstraintsOn =
      d3eParams?.tButtonLightOutlineConstraintsOn;

    this.tButtonLightOutlinePaddingOnHover =
      d3eParams?.tButtonLightOutlinePaddingOnHover;

    this.tButtonLightOutlineDecorationOnHover =
      d3eParams?.tButtonLightOutlineDecorationOnHover;

    this.tButtonLightOutlineConstraintsOnHover =
      d3eParams?.tButtonLightOutlineConstraintsOnHover;

    this.tButtonLightOutlineTextStyleOnHover =
      d3eParams?.tButtonLightOutlineTextStyleOnHover;

    this.tButtonLinkTextStyleOn = d3eParams?.tButtonLinkTextStyleOn;

    this.tButtonLinkPaddingOn = d3eParams?.tButtonLinkPaddingOn;

    this.tButtonLinkConstraintsOn = d3eParams?.tButtonLinkConstraintsOn;

    this.tButtonLinkDecorationOn = d3eParams?.tButtonLinkDecorationOn;

    this.tButtonLinkPaddingOnHover = d3eParams?.tButtonLinkPaddingOnHover;

    this.tButtonLinkTextStyleOnHover = d3eParams?.tButtonLinkTextStyleOnHover;

    this.tButtonLinkDecorationOnHover = d3eParams?.tButtonLinkDecorationOnHover;

    this.tButtonLinkConstraintsOnHover =
      d3eParams?.tButtonLinkConstraintsOnHover;

    this.tButtonLinkButtonTextStyleOn = d3eParams?.tButtonLinkButtonTextStyleOn;

    this.tButtonLinkButtonTextStyleOnHover =
      d3eParams?.tButtonLinkButtonTextStyleOnHover;

    this.tListTileListTileDecorationOnHover =
      d3eParams?.tListTileListTileDecorationOnHover;

    this.tListTileListTileDecorationOnFocused =
      d3eParams?.tListTileListTileDecorationOnFocused;

    this.tListTileListTileDecorationOnSelected =
      d3eParams?.tListTileListTileDecorationOnSelected;

    this.tListTileListTileDecorationOn =
      d3eParams?.tListTileListTileDecorationOn;

    this.tBaseListViewBgColorDecorationOn =
      d3eParams?.tBaseListViewBgColorDecorationOn;

    this.tBaseListViewBgColorMarginOn = d3eParams?.tBaseListViewBgColorMarginOn;

    this.tButtonPrimaryDecorationOn = d3eParams?.tButtonPrimaryDecorationOn;

    this.tButtonPrimaryPaddingOn = d3eParams?.tButtonPrimaryPaddingOn;

    this.tButtonPrimaryTextStyleOn = d3eParams?.tButtonPrimaryTextStyleOn;

    this.tButtonPrimaryConstraintsOn = d3eParams?.tButtonPrimaryConstraintsOn;

    this.tButtonPrimaryDecorationOnHover =
      d3eParams?.tButtonPrimaryDecorationOnHover;

    this.tButtonPrimaryPaddingOnHover = d3eParams?.tButtonPrimaryPaddingOnHover;

    this.tButtonPrimaryConstraintsOnHover =
      d3eParams?.tButtonPrimaryConstraintsOnHover;

    this.tButtonPrimaryTextStyleOnHover =
      d3eParams?.tButtonPrimaryTextStyleOnHover;

    this.tButtonPrimaryOrangeDecorationOn =
      d3eParams?.tButtonPrimaryOrangeDecorationOn;

    this.tButtonPrimaryOrangePaddingOn =
      d3eParams?.tButtonPrimaryOrangePaddingOn;

    this.tButtonPrimaryOrangeConstraintsOn =
      d3eParams?.tButtonPrimaryOrangeConstraintsOn;

    this.tButtonPrimaryOrangeTextStyleOn =
      d3eParams?.tButtonPrimaryOrangeTextStyleOn;

    this.tButtonPrimaryOrangePaddingOnHover =
      d3eParams?.tButtonPrimaryOrangePaddingOnHover;

    this.tButtonPrimaryOrangeDecorationOnHover =
      d3eParams?.tButtonPrimaryOrangeDecorationOnHover;

    this.tButtonPrimaryOrangeTextStyleOnHover =
      d3eParams?.tButtonPrimaryOrangeTextStyleOnHover;

    this.tButtonPrimaryOrangeConstraintsOnHover =
      d3eParams?.tButtonPrimaryOrangeConstraintsOnHover;

    this.tButtonPrimaryOrangeOutlineDecorationOn =
      d3eParams?.tButtonPrimaryOrangeOutlineDecorationOn;

    this.tButtonPrimaryOrangeOutlinePaddingOn =
      d3eParams?.tButtonPrimaryOrangeOutlinePaddingOn;

    this.tButtonPrimaryOrangeOutlineTextStyleOn =
      d3eParams?.tButtonPrimaryOrangeOutlineTextStyleOn;

    this.tButtonPrimaryOrangeOutlineConstraintsOn =
      d3eParams?.tButtonPrimaryOrangeOutlineConstraintsOn;

    this.tButtonPrimaryOrangeOutlinePaddingOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlinePaddingOnHover;

    this.tButtonPrimaryOrangeOutlineDecorationOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineDecorationOnHover;

    this.tButtonPrimaryOrangeOutlineConstraintsOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineConstraintsOnHover;

    this.tButtonPrimaryOrangeOutlineTextStyleOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineTextStyleOnHover;

    this.tButtonPrimaryOutlineDecorationOn =
      d3eParams?.tButtonPrimaryOutlineDecorationOn;

    this.tButtonPrimaryOutlinePaddingOn =
      d3eParams?.tButtonPrimaryOutlinePaddingOn;

    this.tButtonPrimaryOutlineTextStyleOn =
      d3eParams?.tButtonPrimaryOutlineTextStyleOn;

    this.tButtonPrimaryOutlineConstraintsOn =
      d3eParams?.tButtonPrimaryOutlineConstraintsOn;

    this.tButtonPrimaryOutlinePaddingOnHover =
      d3eParams?.tButtonPrimaryOutlinePaddingOnHover;

    this.tButtonPrimaryOutlineDecorationOnHover =
      d3eParams?.tButtonPrimaryOutlineDecorationOnHover;

    this.tButtonPrimaryOutlineConstraintsOnHover =
      d3eParams?.tButtonPrimaryOutlineConstraintsOnHover;

    this.tButtonPrimaryOutlineTextStyleOnHover =
      d3eParams?.tButtonPrimaryOutlineTextStyleOnHover;

    this.tBaseScreenBgColorMarginOn = d3eParams?.tBaseScreenBgColorMarginOn;

    this.tBaseScreenBgColorDecorationOn =
      d3eParams?.tBaseScreenBgColorDecorationOn;

    this.tBaseScreenBgColorPaddingOn = d3eParams?.tBaseScreenBgColorPaddingOn;

    this.tButtonSecondaryDecorationOn = d3eParams?.tButtonSecondaryDecorationOn;

    this.tButtonSecondaryPaddingOn = d3eParams?.tButtonSecondaryPaddingOn;

    this.tButtonSecondaryConstraintsOn =
      d3eParams?.tButtonSecondaryConstraintsOn;

    this.tButtonSecondaryTextStyleOn = d3eParams?.tButtonSecondaryTextStyleOn;

    this.tButtonSecondaryDecorationOnHover =
      d3eParams?.tButtonSecondaryDecorationOnHover;

    this.tButtonSecondaryPaddingOnHover =
      d3eParams?.tButtonSecondaryPaddingOnHover;

    this.tButtonSecondaryConstraintsOnHover =
      d3eParams?.tButtonSecondaryConstraintsOnHover;

    this.tButtonSecondaryTextStyleOnHover =
      d3eParams?.tButtonSecondaryTextStyleOnHover;

    this.tButtonSecondaryOutlineDecorationOn =
      d3eParams?.tButtonSecondaryOutlineDecorationOn;

    this.tButtonSecondaryOutlinePaddingOn =
      d3eParams?.tButtonSecondaryOutlinePaddingOn;

    this.tButtonSecondaryOutlineTextStyleOn =
      d3eParams?.tButtonSecondaryOutlineTextStyleOn;

    this.tButtonSecondaryOutlineConstraintsOn =
      d3eParams?.tButtonSecondaryOutlineConstraintsOn;

    this.tButtonSecondaryOutlinePaddingOnHover =
      d3eParams?.tButtonSecondaryOutlinePaddingOnHover;

    this.tButtonSecondaryOutlineDecorationOnHover =
      d3eParams?.tButtonSecondaryOutlineDecorationOnHover;

    this.tButtonSecondaryOutlineConstraintsOnHover =
      d3eParams?.tButtonSecondaryOutlineConstraintsOnHover;

    this.tButtonSecondaryOutlineTextStyleOnHover =
      d3eParams?.tButtonSecondaryOutlineTextStyleOnHover;

    this.tBaseSelectedTabContainerHeightOn =
      d3eParams?.tBaseSelectedTabContainerHeightOn;

    this.tBaseSelectedTabContainerWidthOn =
      d3eParams?.tBaseSelectedTabContainerWidthOn;

    this.tBaseSelectedTabContainerDecorationOn =
      d3eParams?.tBaseSelectedTabContainerDecorationOn;

    this.tBaseSelectedTabContainerMarginOn =
      d3eParams?.tBaseSelectedTabContainerMarginOn;

    this.tBaseSidemenuBgDecorationOn = d3eParams?.tBaseSidemenuBgDecorationOn;

    this.tBaseSidemenuBgTextStyleOn = d3eParams?.tBaseSidemenuBgTextStyleOn;

    this.tBaseSidemenuBgPaddingOn = d3eParams?.tBaseSidemenuBgPaddingOn;

    this.tInputFieldSmallHeightOn = d3eParams?.tInputFieldSmallHeightOn;

    this.tInputFieldSmallBackgroundColorOn =
      d3eParams?.tInputFieldSmallBackgroundColorOn;

    this.tInputFieldSmallCornerRadiusOn =
      d3eParams?.tInputFieldSmallCornerRadiusOn;

    this.tInputFieldSmallStyleOn = d3eParams?.tInputFieldSmallStyleOn;

    this.tButtonSubSidemenuStylePaddingOn =
      d3eParams?.tButtonSubSidemenuStylePaddingOn;

    this.tButtonSubSidemenuStyleMarginOn =
      d3eParams?.tButtonSubSidemenuStyleMarginOn;

    this.tButtonSubSidemenuStyleDecorationOn =
      d3eParams?.tButtonSubSidemenuStyleDecorationOn;

    this.tButtonSubSidemenuStyleTextStyleOn =
      d3eParams?.tButtonSubSidemenuStyleTextStyleOn;

    this.tButtonSubSidemenuStylePaddingOnHover =
      d3eParams?.tButtonSubSidemenuStylePaddingOnHover;

    this.tButtonSubSidemenuStyleDecorationOnHover =
      d3eParams?.tButtonSubSidemenuStyleDecorationOnHover;

    this.tButtonSubSidemenuStyleTextStyleOnHover =
      d3eParams?.tButtonSubSidemenuStyleTextStyleOnHover;

    this.tButtonSubSidemenuStyleDecorationOnSelected =
      d3eParams?.tButtonSubSidemenuStyleDecorationOnSelected;

    this.tButtonSubSidemenuStyleTextStyleOnSelected =
      d3eParams?.tButtonSubSidemenuStyleTextStyleOnSelected;

    this.tButtonSubSidemenuStylePaddingOnSelected =
      d3eParams?.tButtonSubSidemenuStylePaddingOnSelected;

    this.tButtonSuccessDecorationOn = d3eParams?.tButtonSuccessDecorationOn;

    this.tButtonSuccessPaddingOn = d3eParams?.tButtonSuccessPaddingOn;

    this.tButtonSuccessConstraintsOn = d3eParams?.tButtonSuccessConstraintsOn;

    this.tButtonSuccessTextStyleOn = d3eParams?.tButtonSuccessTextStyleOn;

    this.tButtonSuccessDecorationOnHover =
      d3eParams?.tButtonSuccessDecorationOnHover;

    this.tButtonSuccessPaddingOnHover = d3eParams?.tButtonSuccessPaddingOnHover;

    this.tButtonSuccessConstraintsOnHover =
      d3eParams?.tButtonSuccessConstraintsOnHover;

    this.tButtonSuccessTextStyleOnHover =
      d3eParams?.tButtonSuccessTextStyleOnHover;

    this.tButtonSuccessOutlineDecorationOn =
      d3eParams?.tButtonSuccessOutlineDecorationOn;

    this.tButtonSuccessOutlinePaddingOn =
      d3eParams?.tButtonSuccessOutlinePaddingOn;

    this.tButtonSuccessOutlineTextStyleOn =
      d3eParams?.tButtonSuccessOutlineTextStyleOn;

    this.tButtonSuccessOutlineConstraintsOn =
      d3eParams?.tButtonSuccessOutlineConstraintsOn;

    this.tButtonSuccessOutlinePaddingOnHover =
      d3eParams?.tButtonSuccessOutlinePaddingOnHover;

    this.tButtonSuccessOutlineDecorationOnHover =
      d3eParams?.tButtonSuccessOutlineDecorationOnHover;

    this.tButtonSuccessOutlineConstraintsOnHover =
      d3eParams?.tButtonSuccessOutlineConstraintsOnHover;

    this.tButtonSuccessOutlineTextStyleOnHover =
      d3eParams?.tButtonSuccessOutlineTextStyleOnHover;

    this.tBaseTable1DecorationOn = d3eParams?.tBaseTable1DecorationOn;

    this.tTableCellTableCellHeaderTextStyleOn =
      d3eParams?.tTableCellTableCellHeaderTextStyleOn;

    this.tTableRowTableHeaderTextStyleOn =
      d3eParams?.tTableRowTableHeaderTextStyleOn;

    this.tTableRowTableHeaderPaddingOn =
      d3eParams?.tTableRowTableHeaderPaddingOn;

    this.tTableRowTableRow1TextStyleOn =
      d3eParams?.tTableRowTableRow1TextStyleOn;

    this.tTableRowTableRow1PaddingOn = d3eParams?.tTableRowTableRow1PaddingOn;

    this.tTableRowTableRow1DecorationOn =
      d3eParams?.tTableRowTableRow1DecorationOn;

    this.tTableRowTableRow1PaddingOnHover =
      d3eParams?.tTableRowTableRow1PaddingOnHover;

    this.tTableRowTableRow1DecorationOnHover =
      d3eParams?.tTableRowTableRow1DecorationOnHover;

    this.tTableRowTableRow1TextStyleOnHover =
      d3eParams?.tTableRowTableRow1TextStyleOnHover;

    this.tBaseTopContainerPaddingOn = d3eParams?.tBaseTopContainerPaddingOn;

    this.tBaseUnselectedTabContainerHeightOn =
      d3eParams?.tBaseUnselectedTabContainerHeightOn;

    this.tBaseUnselectedTabContainerWidthOn =
      d3eParams?.tBaseUnselectedTabContainerWidthOn;

    this.tBaseUnselectedTabContainerDecorationOn =
      d3eParams?.tBaseUnselectedTabContainerDecorationOn;

    this.tBaseUnselectedTabContainerMarginOn =
      d3eParams?.tBaseUnselectedTabContainerMarginOn;

    this.tButtonWarningDecorationOn = d3eParams?.tButtonWarningDecorationOn;

    this.tButtonWarningConstraintsOn = d3eParams?.tButtonWarningConstraintsOn;

    this.tButtonWarningTextStyleOn = d3eParams?.tButtonWarningTextStyleOn;

    this.tButtonWarningDecorationOnHover =
      d3eParams?.tButtonWarningDecorationOnHover;

    this.tButtonWarningTextStyleOnHover =
      d3eParams?.tButtonWarningTextStyleOnHover;

    this.tButtonWarningOutlineDecorationOn =
      d3eParams?.tButtonWarningOutlineDecorationOn;

    this.tButtonWarningOutlineTextStyleOn =
      d3eParams?.tButtonWarningOutlineTextStyleOn;

    this.tButtonWarningOutlineConstraintsOn =
      d3eParams?.tButtonWarningOutlineConstraintsOn;

    this.tButtonWarningOutlineDecorationOnHover =
      d3eParams?.tButtonWarningOutlineDecorationOnHover;

    this.tButtonWarningOutlineTextStyleOnHover =
      d3eParams?.tButtonWarningOutlineTextStyleOnHover;

    this.tInputFieldSearchInputFieldInActiveColorOn =
      d3eParams?.tInputFieldSearchInputFieldInActiveColorOn;

    this.tInputFieldSearchInputFieldPlaceHolderColorOn =
      d3eParams?.tInputFieldSearchInputFieldPlaceHolderColorOn;

    this.tInputFieldSearchInputFieldActiveColorOn =
      d3eParams?.tInputFieldSearchInputFieldActiveColorOn;

    this.tInputFieldSearchInputFieldPaddingOn =
      d3eParams?.tInputFieldSearchInputFieldPaddingOn;

    this.tInputFieldSearchInputFieldCornerRadiusOn =
      d3eParams?.tInputFieldSearchInputFieldCornerRadiusOn;

    this.c1 = d3eParams?.c1;

    this.c2 = d3eParams?.c2;

    this.c3 = d3eParams?.c3;

    this.c4 = d3eParams?.c4;

    this.c5 = d3eParams?.c5;

    this.c6 = d3eParams?.c6;

    this.c7 = d3eParams?.c7;

    this.c8 = d3eParams?.c8;

    this.c9 = d3eParams?.c9;

    this.c10 = d3eParams?.c10;

    this.c11 = d3eParams?.c11;

    this.c12 = d3eParams?.c12;

    this.c13 = d3eParams?.c13;

    this.c14 = d3eParams?.c14;

    this.c15 = d3eParams?.c15;

    this.c16 = d3eParams?.c16;

    this.c17 = d3eParams?.c17;

    this.c18 = d3eParams?.c18;

    this.c19 = d3eParams?.c19;

    this.c21 = d3eParams?.c21;

    this.c22 = d3eParams?.c22;

    this.c23 = d3eParams?.c23;
  }
  public static createLeadMgtTheme(): StyleThemeData {
    StyleThemeData.LeadMgtTheme = new StyleThemeData({
      themeName: "LeadMgtTheme",
      color: new ui.Color(0xfff4f5f7),
      textStyle: new ui.TextStyle({
        color: new ui.Color(0xff000000),
        fontFamily: "Nunito Sans",
        fontSize: 13.0,
      }),
      tooltipBackgroundColor: new ui.Color(0xffffffff),
      tooltipTextColor: new ui.Color(0xff000000),
      tButtonAttachmentButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xfff4f5f7),
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonAttachmentButtonPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonAttachmentButtonConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonAttachmentButtonDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffcfe4fa),
        transitions: new Map(),
      }),
      tButtonAttachmentButtonPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonAttachmentButtonConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tBaseBaseViewStyleDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tBaseBaseViewStylePaddingOn: ui.EdgeInsets.all(20.0, new Map()),
      tTextViewBlueColorOn: new ui.Color(0xffdcdcdc),
      tTextViewBlueFontSizeOn: 13.5,
      tBaseBorderShadowStyleDecorationOn: new ui.BoxDecoration({
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0xff000000),
            blurRadius: 1.0,
            spreadRadius: 1.0,
            offset: ui.OffsetExt.getOffset({ dx: 0.0, dy: 0.0 }),
          }),
        ],
        transitions: new Map(),
      }),
      tButtonCallPrimaryDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff299d91),
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonCallPrimaryPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 4.0,
        vertical: 1.0,
        transitions: new Map(),
      }),
      tButtonCallPrimaryTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonCallPrimaryConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonCallPrimaryDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff108075), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff108075),
        transitions: new Map(),
      }),
      tButtonCallPrimaryPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 4.0,
        vertical: 1.0,
        transitions: new Map(),
      }),
      tButtonCallPrimaryConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonCallPrimaryTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tBaseCardMarginMarginOn: ui.EdgeInsets.fromLTRB(
        3.0,
        3.0,
        3.0,
        7.0,
        new Map()
      ),
      tBaseCardPaddingPaddingOn: ui.EdgeInsets.all(15.0, new Map()),
      tBaseCardShadowDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 1.0 }),
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0x4d4c6764),
            offset: ui.OffsetExt.getOffset({ dx: 0.0, dy: 0.0 }),
            blurRadius: 1.0,
            spreadRadius: 1.0,
          }),
        ],
        borderRadius: ui.BorderRadius.circular(8.0),
        transitions: new Map(),
      }),
      tButtonDangerDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe8e8e8),
        transitions: new Map(),
      }),
      tButtonDangerPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDangerTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonDangerDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffba2a92),
        transitions: new Map(),
      }),
      tButtonDangerPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonDangerOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffe8e8e8), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonDangerOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe8e8e8),
        fontFamily: "Nunito Sans",
      }),
      tButtonDangerOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffba2a92),
        transitions: new Map(),
      }),
      tButtonDangerOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonDarkDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfff5f5f5),
        transitions: new Map(),
      }),
      tButtonDarkPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDarkTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonDarkDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe8e1e1),
        transitions: new Map(),
      }),
      tButtonDarkPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkConstraintsOnHover: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDarkTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonDarkOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff5f5f5), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonDarkOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xfff5f5f5),
        fontFamily: "Nunito Sans",
      }),
      tButtonDarkOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe8e1e1),
        transitions: new Map(),
      }),
      tButtonDarkOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDarkOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tCardDefaultCardColorOn: new ui.Color(0xffdee2e6),
      tCardDefaultCardDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 2.0 }),
        transitions: new Map(),
      }),
      tDateFieldDefaultDateInputFieldHeightOn: 40.0,
      tDateFieldDefaultDateInputFieldCornerRadiusOn: 5.0,
      tDateTimeFieldDefaultDateTimeInputFieldHeightOn: 40.0,
      tDateTimeFieldDefaultDateTimeInputFieldCornerRadiusOn: 5.0,
      tDoubleInputFieldDefaultDoubleInputFieldHeightOn: 40.0,
      tDoubleInputFieldDefaultDoubleInputFieldBackgroundColorOn: new ui.Color(
        0xff191919
      ),
      tDoubleInputFieldDefaultDoubleInputFieldCornerRadiusOn: 5.0,
      tDoubleInputFieldDefaultDoubleInputFieldPaddingOn: ui.EdgeInsets.fromLTRB(
        5.0,
        0.0,
        0.0,
        0.0,
        new Map()
      ),
      tDropDownDefaultDropdownDropDownDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(13.0),
        transitions: new Map(),
      }),
      tDropDownDefaultDropdownPlaceHolderColorOn: new ui.Color(0xffe8e1e1),
      tInputFieldDefaultInputFieldHeightOn: 40.0,
      tInputFieldDefaultInputFieldBackgroundColorOn: new ui.Color(0xffffffff),
      tInputFieldDefaultInputFieldPlaceHolderColorOn: new ui.Color(0xff191919),
      tInputFieldDefaultInputFieldInActiveColorOn: new ui.Color(0xffe8e1e1),
      tInputFieldDefaultInputFieldActiveColorOn: new ui.Color(0xff299d91),
      tInputFieldDefaultInputFieldPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 10.0,
        vertical: 0.0,
        transitions: new Map(),
      }),
      tInputFieldDefaultInputFieldCornerRadiusOn: 4.0,
      tInputFieldDefaultInputFieldStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tIntegerInputFieldDefaultIntegerInputFieldHeightOn: 40.0,
      tIntegerInputFieldDefaultIntegerInputFieldBackgroundColorOn: new ui.Color(
        0xff191919
      ),
      tIntegerInputFieldDefaultIntegerInputFieldCornerRadiusOn: 5.0,
      tIntegerInputFieldDefaultIntegerInputFieldPaddingOn:
        ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
      tListTileDefaultListTileDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xffdee2e6),
        transitions: new Map(),
      }),
      tListTileDefaultListTileDecorationOnFocused: new ui.BoxDecoration({
        color: new ui.Color(0xffdfefff),
        transitions: new Map(),
      }),
      tListTileDefaultListTileDecorationOnSelected: new ui.BoxDecoration({
        color: new ui.Color(0xff299d91),
        transitions: new Map(),
      }),
      tBaseDefaultPopUpDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        borderRadius: ui.BorderRadius.circular(2.0),
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0x32000000),
            blurRadius: 5.0,
          }),
        ],
        transitions: new Map(),
      }),
      tTabBarDefaultTabBarBackgroundColorOn: new ui.Color(0xffffffff),
      tTabBarDefaultTabBarActiveColorOn: new ui.Color(0xff191919),
      tTimeFieldDefaultTimeInputFieldHeightOn: 40.0,
      tTimeFieldDefaultTimeInputFieldCornerRadiusOn: 5.0,
      tButtonDialNumberDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffdcdcdc),
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(20.0),
        transitions: new Map(),
      }),
      tButtonDialNumberTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff000000),
        fontFamily: "Nunito Sans",
      }),
      tButtonDialNumberWidthOn: 40.0,
      tButtonDialNumberHeightOn: 40.0,
      tInputFieldDisabledHeightOn: 30.0,
      tInputFieldDisabledBackgroundColorOn: new ui.Color(0xffffffff),
      tInputFieldDisabledCornerRadiusOn: 2.0,
      tInputFieldDisabledStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe8e1e1),
        fontFamily: "Nunito Sans",
      }),
      tInputFieldDisabledDisableOn: true,
      tTextViewDisplayHeadingFourFontSizeOn: 30.0,
      tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingOneFontSizeOn: 60.0,
      tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingThreeFontSizeOn: 40.0,
      tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingTwoFontSizeOn: 50.0,
      tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tDropDownDropdownWithAllBordersHeightOn: 40.0,
      tDropDownDropdownWithAllBordersViewDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tDropDownDropdownWithAllBordersDropDownDecorationOn: new ui.BoxDecoration(
        {
          border: ui.Border.all({
            color: new ui.Color(0xffffffff),
            width: 1.0,
          }),
          borderRadius: ui.BorderRadius.circular(7.0),
          transitions: new Map(),
        }
      ),
      tDropDownDropdownWithAllBordersPlaceHolderColorOn: new ui.Color(
        0xffe8e1e1
      ),
      tTextViewErrorColorOn: new ui.Color(0xffe8e8e8),
      tTextViewErrorTextAlignOn: ui.TextAlign.start,
      tTextViewErrorMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tTextViewErrorLabelColorOn: new ui.Color(0xffe71306),
      tTextViewErrorLabelFontSizeOn: 14.0,
      tTextViewErrorLabelMarginOn: ui.EdgeInsets.fromLTRB(
        0.0,
        5.0,
        0.0,
        0.0,
        new Map()
      ),
      tTextViewErrorTextColorOn: new ui.Color(0xffc20f2f),
      tTextViewErrorTextTextAlignOn: ui.TextAlign.start,
      tTextViewErrorTextMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tColumnFieldStyleCrossAxisAlignmentOn: ui.CrossAxisAlignment.start,
      tColumnFieldStyleMarginOn: ui.EdgeInsets.fromLTRB(
        4.0,
        8.0,
        4.0,
        0.0,
        new Map()
      ),
      tTextViewHeadlineFiveFontSizeOn: 16.0,
      tTextViewHeadlineFiveFontWeightOn: ui.FontWeight.w600,
      tTextViewHeadlineFourFontSizeOn: 15.0,
      tTextViewHeadlineFourFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineOneFontSizeOn: 40.0,
      tTextViewHeadlineOneFontWeightOn: ui.FontWeight.w800,
      tTextViewHeadlineSixFontSizeOn: 14.0,
      tTextViewHeadlineSixFontWeightOn: ui.FontWeight.w500,
      tTextViewHeadlineThreeFontSizeOn: 24.0,
      tTextViewHeadlineThreeFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineTwoFontSizeOn: 32.0,
      tTextViewHeadlineTwoFontWeightOn: ui.FontWeight.w700,
      tTextViewLabelColorOn: new ui.Color(0xffe8e1e1),
      tTextViewLabelTextAlignOn: ui.TextAlign.start,
      tTextViewLabelMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tTextViewLabelTextFontSizeOn: 14.0,
      tTextViewLabelTextFontWeightOn: ui.FontWeight.w500,
      tTextViewLabelTextColorOn: new ui.Color(0xff000000),
      tInputFieldLargeHeightOn: 60.0,
      tInputFieldLargeBackgroundColorOn: new ui.Color(0xff191919),
      tInputFieldLargeCornerRadiusOn: 2.0,
      tInputFieldLargeStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe8e1e1),
        fontFamily: "Nunito Sans",
      }),
      tButtonLightDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonLightPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonLightConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLightTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonLightDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xfff5f5f5),
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonLightPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonLightConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tBaseLightColorWithMarginDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffdee2e6),
        transitions: new Map(),
      }),
      tBaseLightColorWithMarginMarginOn: ui.EdgeInsets.fromLTRB(
        8.0,
        0.0,
        8.0,
        8.0,
        new Map()
      ),
      tButtonLightOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonLightOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonLightOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffc20f2f),
        transitions: new Map(),
      }),
      tButtonLightOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xfff5f5f5),
        fontFamily: "Nunito Sans",
      }),
      tButtonLinkTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffdcdcdc),
        fontFamily: "Nunito Sans",
      }),
      tButtonLinkPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLinkConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLinkDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0x0),
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonLinkPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLinkTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffdcdcdc),
        decoration: ui.TextDecoration.underline,
        fontFamily: "Nunito Sans",
      }),
      tButtonLinkDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0x0),
        transitions: new Map(),
      }),
      tButtonLinkConstraintsOnHover: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLinkButtonTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tButtonLinkButtonTextStyleOnHover: new ui.TextStyle({
        decoration: ui.TextDecoration.underline,
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tListTileListTileDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xffdee2e6),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOnFocused: new ui.BoxDecoration({
        color: new ui.Color(0xffdfefff),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOnSelected: new ui.BoxDecoration({
        color: new ui.Color(0xff299d91),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tBaseListViewBgColorDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffdee2e6),
        transitions: new Map(),
      }),
      tBaseListViewBgColorMarginOn: ui.EdgeInsets.fromLTRB(
        8.0,
        0.0,
        8.0,
        8.0,
        new Map()
      ),
      tButtonPrimaryDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff299d91),
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonPrimaryPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonPrimaryTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonPrimaryDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff108075), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff108075),
        transitions: new Map(),
      }),
      tButtonPrimaryPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonPrimaryConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOrangeDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdfefff),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdfefff),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOrangeConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe8e1e1),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOrangeOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets.all(
        7.0,
        new Map()
      ),
      tButtonPrimaryOrangeOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdcdcdc),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOutlineDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 15.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tButtonPrimaryOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontSize: 16.0,
        fontFamily: "Nunito Sans",
      }),
      tButtonPrimaryOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 40.0,
      }),
      tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 15.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tButtonPrimaryOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe5a000),
        transitions: new Map(),
      }),
      tButtonPrimaryOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 40.0,
      }),
      tButtonPrimaryOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontSize: 16.0,
        fontFamily: "Nunito Sans",
      }),
      tBaseScreenBgColorMarginOn: ui.EdgeInsets.zero,
      tBaseScreenBgColorDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffe8e8e8),
        transitions: new Map(),
      }),
      tBaseScreenBgColorPaddingOn: ui.EdgeInsets.all(20.0, new Map()),
      tButtonSecondaryDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonSecondaryPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonSecondaryConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonSecondaryTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tButtonSecondaryDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfff5f5f5),
        transitions: new Map(),
      }),
      tButtonSecondaryPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonSecondaryConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tButtonSecondaryOutlineDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xff299d91), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 15.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tButtonSecondaryOutlineTextStyleOn: new ui.TextStyle({
        fontSize: 16.0,
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tButtonSecondaryOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 15.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tButtonSecondaryOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe5a000),
        transitions: new Map(),
      }),
      tButtonSecondaryOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff299d91),
        fontFamily: "Nunito Sans",
      }),
      tBaseSelectedTabContainerHeightOn: 2.5,
      tBaseSelectedTabContainerWidthOn: 45.0,
      tBaseSelectedTabContainerDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff299d91),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tBaseSelectedTabContainerMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 0.0,
        transitions: new Map(),
      }),
      tBaseSidemenuBgDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff000000),
        transitions: new Map(),
      }),
      tBaseSidemenuBgTextStyleOn: new ui.TextStyle({
        fontSize: 16.0,
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tBaseSidemenuBgPaddingOn: ui.EdgeInsets.fromLTRB(
        15.0,
        15.0,
        0.0,
        15.0,
        new Map()
      ),
      tInputFieldSmallHeightOn: 30.0,
      tInputFieldSmallBackgroundColorOn: new ui.Color(0xff191919),
      tInputFieldSmallCornerRadiusOn: 2.0,
      tInputFieldSmallStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe8e1e1),
        fontFamily: "Nunito Sans",
      }),
      tButtonSubSidemenuStylePaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 8.0,
        transitions: new Map(),
      }),
      tButtonSubSidemenuStyleMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tButtonSubSidemenuStyleDecorationOn: new ui.BoxDecoration({
        borderRadius: ui.BorderRadiusExt.only({
          topLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          topRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
          bottomLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          bottomRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
        }),
        color: new ui.Color(0xff000000),
        transitions: new Map(),
      }),
      tButtonSubSidemenuStyleTextStyleOn: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonSubSidemenuStylePaddingOnHover: ui.EdgeInsets.all(8.0, new Map()),
      tButtonSubSidemenuStyleDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xff2b2b2b),
        borderRadius: ui.BorderRadiusExt.only({
          topLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          topRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
          bottomLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          bottomRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
        }),
        transitions: new Map(),
      }),
      tButtonSubSidemenuStyleTextStyleOnHover: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xffffffff),
        fontFamily: "Nunito Sans",
      }),
      tButtonSubSidemenuStyleDecorationOnSelected: new ui.BoxDecoration({
        borderRadius: ui.BorderRadiusExt.only({
          topLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          topRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
          bottomLeft: ui.RadiusExt.elliptical({ x: 18.0, y: 18.0 }),
          bottomRight: ui.RadiusExt.elliptical({ x: 0.0, y: 0.0 }),
        }),
        color: new ui.Color(0xff299d91),
        transitions: new Map(),
      }),
      tButtonSubSidemenuStyleTextStyleOnSelected: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        fontFamily: "Nunito Sans",
      }),
      tButtonSubSidemenuStylePaddingOnSelected: ui.EdgeInsets.all(
        8.0,
        new Map()
      ),
      tButtonSuccessDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff6c73ff),
        transitions: new Map(),
      }),
      tButtonSuccessPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonSuccessTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonSuccessDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff14ae5c),
        transitions: new Map(),
      }),
      tButtonSuccessPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonSuccessOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff6c73ff), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonSuccessOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff6c73ff),
        fontFamily: "Nunito Sans",
      }),
      tButtonSuccessOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff14ae5c),
        transitions: new Map(),
      }),
      tButtonSuccessOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tBaseTable1DecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 1.0 }),
        transitions: new Map(),
      }),
      tTableCellTableCellHeaderTextStyleOn: new ui.TextStyle({
        fontFamily: "Nunito Sans",
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w600,
        color: new ui.Color(0xff191919),
      }),
      tTableRowTableHeaderTextStyleOn: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w700,
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tTableRowTableHeaderPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tTableRowTableRow1TextStyleOn: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xff0d0c0c),
        fontFamily: "Nunito Sans",
      }),
      tTableRowTableRow1PaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tTableRowTableRow1DecorationOn: new ui.BoxDecoration({
        border: new ui.Border({
          top: new ui.BorderSide({
            color: new ui.Color(0xffdcdcdc),
            width: 1.0,
            style: ui.BorderStyle.solid,
          }),
          left: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
          right: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
          bottom: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
        }),
        transitions: new Map(),
      }),
      tTableRowTableRow1PaddingOnHover: ui.EdgeInsets.all(10.0, new Map()),
      tTableRowTableRow1DecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xffe7f4f3),
        border: new ui.Border({
          top: new ui.BorderSide({
            color: new ui.Color(0xffdcdcdc),
            width: 1.0,
            style: ui.BorderStyle.solid,
          }),
          left: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
          right: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
          bottom: new ui.BorderSide({
            color: new ui.Color(0xff0000),
            width: 1.0,
            style: ui.BorderStyle.none,
          }),
        }),
        transitions: new Map(),
      }),
      tTableRowTableRow1TextStyleOnHover: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xff0d0c0c),
        fontFamily: "Nunito Sans",
      }),
      tBaseTopContainerPaddingOn: ui.EdgeInsets.fromLTRB(
        0.0,
        0.0,
        8.0,
        0.0,
        new Map()
      ),
      tBaseUnselectedTabContainerHeightOn: 2.5,
      tBaseUnselectedTabContainerWidthOn: 45.0,
      tBaseUnselectedTabContainerDecorationOn: new ui.BoxDecoration({
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tBaseUnselectedTabContainerMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 12.0,
        vertical: 0.0,
        transitions: new Map(),
      }),
      tButtonWarningDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffff1313),
        transitions: new Map(),
      }),
      tButtonWarningConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonWarningTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonWarningDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfffee9e7),
        transitions: new Map(),
      }),
      tButtonWarningTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tButtonWarningOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffff1313), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff191919),
        transitions: new Map(),
      }),
      tButtonWarningOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xfffee9e7),
        fontFamily: "Nunito Sans",
      }),
      tButtonWarningOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonWarningOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xfff4f5f7), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfffee9e7),
        transitions: new Map(),
      }),
      tButtonWarningOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff191919),
        fontFamily: "Nunito Sans",
      }),
      tInputFieldSearchInputFieldInActiveColorOn: new ui.Color(0xffdee2e6),
      tInputFieldSearchInputFieldPlaceHolderColorOn: new ui.Color(0xffe8e8e8),
      tInputFieldSearchInputFieldActiveColorOn: new ui.Color(0xffdee2e6),
      tInputFieldSearchInputFieldPaddingOn: ui.EdgeInsets.fromLTRB(
        0.0,
        4.0,
        2.0,
        4.0,
        new Map()
      ),
      tInputFieldSearchInputFieldCornerRadiusOn: 4.0,
      c1: new ui.Color(0xff299d91),
      c2: new ui.Color(0xff191919),
      c3: new ui.Color(0xffdfefff),
      c4: new ui.Color(0xffe5a000),
      c5: new ui.Color(0xffd732a8),
      c6: new ui.Color(0xff6c73ff),
      c7: new ui.Color(0xff14ae5c),
      c8: new ui.Color(0xffe8e8e8),
      c9: new ui.Color(0xffba2a92),
      c10: new ui.Color(0xffff1313),
      c11: new ui.Color(0xfffee9e7),
      c12: new ui.Color(0xfff5f5f5),
      c13: new ui.Color(0xffe8e1e1),
      c14: new ui.Color(0xffffffff),
      c15: new ui.Color(0xffc20f2f),
      c16: new ui.Color(0xffdcdcdc),
      c17: new ui.Color(0xffdee2e6),
      c18: new ui.Color(0xfff4f5f7),
      c19: new ui.Color(0xff8a8787),
      c21: new ui.Color(0xffd2d2d2),
      c22: new ui.Color(0xff828282),
      c23: new ui.Color(0xffff0000),
    });

    return StyleThemeData.LeadMgtTheme;
  }
}

interface ThemeWrapperProps {
  child: ReactNode;
}

class _ThemeWrapper extends React.Component<ThemeWrapperProps> {
  public render(): React.ReactNode {
    DomUtils.loadTheme(StyleThemeData.current.themeName);

    return React.createElement(
      BuildContext.Provider,
      { value: { ...this.context, theme: StyleThemeData.current } },
      this.props.child
    );
  }
}
export default function ThemeWrapper(props: ThemeWrapperProps) {
  return React.createElement(_ThemeWrapper, props);
}

export const ThemeContext = React.createContext<StyleThemeData>(null);
