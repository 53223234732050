import User from "../models/User";
import Interaction from "../models/Interaction";
import WSReader from "../rocket/WSReader";
import PhoneNumberDetails from "../classes/PhoneNumberDetails";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class CallServiceClient {
  public async getAccessToken(user: User): Promise<string> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceGetAccessToken,
      {
        "args": (w) => {
          w.writeObjFull(user);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readString() as string;
  }
  public async getAudioText(audioUrl: string): Promise<string> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceGetAudioText,
      {
        "args": (w) => {
          w.writeString(audioUrl);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readString() as string;
  }
  public async searchAvailableNumbers(
    countryWithCode: string,
    searchBy: string,
    d3eParams?: Partial<{ areaCode: string }>
  ): Promise<Array<PhoneNumberDetails>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceSearchAvailableNumbers,
      {
        "args": (w) => {
          w.writeString(countryWithCode);

          w.writeString(searchBy);

          w.writeString(d3eParams?.areaCode || "");
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readRefList() as Array<PhoneNumberDetails>;
  }
  public async buyNumber(phoneNumber: string): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceBuyNumber,
      {
        "args": (w) => {
          w.writeString(phoneNumber);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async configureNumberWebhooks(
    user: User,
    phoneNumber: string,
    phoneSid: string,
    messageServiceSid: string
  ): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceConfigureNumberWebhooks,
      {
        "args": (w) => {
          w.writeObjFull(user);

          w.writeString(phoneNumber);

          w.writeString(phoneSid);

          w.writeString(messageServiceSid);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async getUnconfiguredNumbers(): Promise<Array<PhoneNumberDetails>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceGetUnconfiguredNumbers,
      { "args": (w) => {} }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readRefList() as Array<PhoneNumberDetails>;
  }
  public async releaseNumber(phoneNumber: string): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceReleaseNumber,
      {
        "args": (w) => {
          w.writeString(phoneNumber);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async sendSMS(interaction: Interaction): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceSendSMS,
      {
        "args": (w) => {
          w.writeObjFull(interaction);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async removeCallBacks(phoneNumber: string): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.CallService,
      RPCConstants.CallServiceRemoveCallBacks,
      {
        "args": (w) => {
          w.writeString(phoneNumber);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
}
