import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _DialPadButtonOnTap = () => void;

type _ContainerOnTap = (d3eState: DialPadButtonRefs) => void;

export interface DialPadButtonProps extends BaseUIProps {
  key?: string;
  digit: string;
  isSelected: boolean;
  onTap?: _DialPadButtonOnTap;
}
/// To store state data for DialPadButton
class DialPadButtonRefs {
  public container: ContainerState = new ContainerState();
}

interface ContainerWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DialPadButtonRefs;
  _onContainerTap?: _ContainerOnTap;
  digit: string;
  isSelected: boolean;
}

class ContainerState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ContainerWithState extends ObservableComponent<ContainerWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ContainerWithStateProps) {
    super(props);

    this.initState();
  }
  public get container(): ContainerState {
    return this.props.d3eState.container;
  }
  public get digit(): string {
    return this.props.digit;
  }
  public get isSelected(): boolean {
    return this.props.isSelected;
  }
  public get d3eState(): DialPadButtonRefs {
    return this.props.d3eState;
  }
  public get _onContainerTap(): _ContainerOnTap {
    return this.props._onContainerTap;
  }
  public initState() {
    super.initState();

    this.updateObservable("container", null, this.container);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["container", "container.hover", "digit", "isSelected"],
      this.rebuild
    );
  }
  public containerOnEnter(event): void {
    return this.container.setHover(true);
  }
  public containerOnExit(event): void {
    return this.container.setHover(false);
  }
  public dispose(): void {
    this.container.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      alignment: ui.Alignment.center,
      states: ui.joinStates(
        { "data-c0": this.isSelected, "data-c1": this.container.hover },
        this.props.states
      ),
      decoration: this.isSelected
        ? new ui.BoxDecoration({
            color: new ui.Color(0xff8aded5),
            borderRadius: ui.BorderRadius.circular(25.0),
          })
        : this.container.hover
        ? new ui.BoxDecoration({
            color: new ui.Color(0xff8aded5),
            borderRadius: ui.BorderRadius.circular(25.0),
          })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: this.digit,
        textAlign: ui.TextAlign.center,
        style: new ui.TextStyle({
          color: new ui.Color(0xff000000),
          fontSize: 14,
        }),
        className: "x8ef hc vc",
      }),
      onEnter: (event) => {
        this.containerOnEnter(event);
      },
      onExit: (event) => {
        this.containerOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onContainerTap(this.d3eState);
      },
      className: ui.join(this.props.className, "DialPadButton x285 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function ContainerWithState(props: ContainerWithStateProps) {
  return React.createElement(_ContainerWithState, props);
}

class _DialPadButtonState extends ObservableComponent<DialPadButtonProps> {
  static defaultProps = { digit: "", isSelected: false, onTap: null };
  d3eState: DialPadButtonRefs = new DialPadButtonRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DialPadButtonProps) {
    super(props);

    this.initState();
  }
  public get digit(): string {
    return this.props.digit;
  }
  public get isSelected(): boolean {
    return this.props.isSelected;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["digit", "isSelected"], this.rebuild);
  }
  public componentDidUpdate(prevProps: DialPadButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.digit !== this.props.digit) {
      this.fire("digit", this);
    }

    if (prevProps.isSelected !== this.props.isSelected) {
      this.fire("isSelected", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ContainerWithState({
      d3eState: this.d3eState,
      _onContainerTap: this.onContainerTap,
      digit: this.digit,
      isSelected: this.isSelected,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onContainerTap = (d3eState: DialPadButtonRefs): void => {
    this.onTap();
  };
  public get onTap(): _DialPadButtonOnTap {
    return this.props.onTap;
  }
  public get container() {
    return this.d3eState.container;
  }
}
export default function DialPadButton(props: DialPadButtonProps) {
  return React.createElement(_DialPadButtonState, {
    ..._DialPadButtonState.defaultProps,
    ...props,
  });
}
