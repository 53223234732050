import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import SuccessMessage from "../classes/SuccessMessage";
import D3EDate from "../classes/D3EDate";
import LeadStatus from "../classes/LeadStatus";
import EventBus from "../utils/EventBus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import DBSaveStatus from "../utils/DBSaveStatus";
import ListWrapper from "../utils/ListWrapper";
import LeadAssignment from "../models/LeadAssignment";
import Lead from "../models/Lead";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: AddNewConnectionViewRefs) => void;

type _UserButtonOnPressed = (d3eState: AddNewConnectionViewRefs) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: AddNewConnectionViewRefs
) => void;

type _LabelWithInputField4OnChanged = (
  text: string,
  d3eState: AddNewConnectionViewRefs
) => void;

type _LabelWithInputField5OnChanged = (
  text: string,
  d3eState: AddNewConnectionViewRefs
) => void;

export interface AddNewConnectionViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  user: User;
}
/// To store state data for AddNewConnectionView
class AddNewConnectionViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public userButton: UserButtonState = new UserButtonState();
}

interface UserButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddNewConnectionViewRefs;
  _onAddUserHandler?: _UserButtonOnPressed;
  lead: Lead;
}

class UserButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UserButtonWithState extends ObservableComponent<UserButtonWithStateProps> {
  userButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get userButton(): UserButtonState {
    return this.props.d3eState.userButton;
  }
  public get d3eState(): AddNewConnectionViewRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _UserButtonOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("userButton", null, this.userButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(
      ["lead", "userButton", "userButton.", "userButton.hover"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: UserButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public userButtonOnEnter(event): void {
    return this.userButton.setHover(true);
  }
  public userButtonOnExit(event): void {
    return this.userButton.setHover(false);
  }
  public dispose(): void {
    this.userButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.userButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.userButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.userButton.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: this.lead.saveStatus !== DBSaveStatus.New ? "Update" : "Save ",
        }),
        onEnter: (event) => {
          this.userButtonOnEnter(event);
        },
        onExit: (event) => {
          this.userButtonOnExit(event);
        },
      }),
      className: "x5e",
    });
  }
}
function UserButtonWithState(props: UserButtonWithStateProps) {
  return React.createElement(_UserButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddNewConnectionViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): AddNewConnectionViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "xa5",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _AddNewConnectionViewState extends ObservableComponent<AddNewConnectionViewProps> {
  static defaultProps = { lead: null, user: null };
  d3eState: AddNewConnectionViewRefs = new AddNewConnectionViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  phoneErrors: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _nameErrors: Array<string> = [];
  private _statusErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddNewConnectionViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.on(
      ["lead.leadAssignment", "lead.leadAssignment.assignedTo", "lead"],
      this.computeHandlingUser
    );

    this.computeHandlingUser();

    this.on(
      [
        "errors",
        "lead",
        "lead.email",
        "lead.name",
        "lead.phone",
        "nameErrors",
        "phoneErrors",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: AddNewConnectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setPhoneErrors(val: boolean): void {
    let isValChanged: boolean = this.phoneErrors !== val;

    if (!isValChanged) {
      return;
    }

    this.phoneErrors = val;

    this.fire("phoneErrors", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get nameErrors(): Array<string> {
    return this._nameErrors ?? [];
  }
  public set nameErrors(val: Array<string>) {
    this._nameErrors.clear();

    this._nameErrors.addAll(val);
  }
  public async computeNameErrors(): Promise<void> {
    this.nameErrors = [];

    let it: string = this.lead.name;

    if (it == null || it === "") {
      this.nameErrors.add("Name cannot be empty.");
    }

    this.fire("nameErrors", this);
  }
  public get statusErrors(): Array<string> {
    return this._statusErrors ?? [];
  }
  public set statusErrors(val: Array<string>) {
    this._statusErrors.clear();

    this._statusErrors.addAll(val);
  }
  public async computeStatusErrors(): Promise<void> {
    this.statusErrors = [];

    let it: LeadStatus = this.lead.status;

    this.fire("statusErrors", this);
  }
  public computeHandlingUser = (): void => {
    try {
      this.lead.setHandlingUser(
        this.lead.leadAssignment !== null
          ? this.lead.leadAssignment.assignedTo
          : null
      );
    } catch (exception) {
      console.log(
        " exception in computeHandlingUser : " + exception.toString()
      );

      this.lead.setHandlingUser(null);
    }
  };
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.nameErrors.isNotEmpty) {
      return false;
    }

    if (this.statusErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeNameErrors();

    errors.addAll(this.nameErrors);

    this.computeStatusErrors();

    errors.addAll(this.statusErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title:
        this.lead.saveStatus !== DBSaveStatus.New
          ? "Edit Connection"
          : "Add New Connection",
      content: ui.Column({
        children: [
          LabelWithInputField({
            name: "Name",
            placeHolder: "Enter First Name",
            value: this.lead.name,
            errors: this.nameErrors,
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputFieldonChanged(text, this.d3eState);
            },
            className: "xfd hc h",
            key: "0",
          }),
          LabelWithInputField({
            name: "Phone Number (With Country Code)",
            placeHolder: "Enter Phone Number",
            value: this.lead.phone,
            errors: this.phoneErrors ? ["Phone Number is required"] : [],
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputField4onChanged(text, this.d3eState);
            },
            className: "x54 hc h",
            key: "1",
          }),
          LabelWithInputField({
            name: "Email",
            placeHolder: "Enter Email",
            value: this.lead.email,
            onChanged: (text) => {
              this.labelWithInputField5onChanged(text, this.d3eState);
            },
            className: "x7d hc h",
            key: "2",
          }),
          this.errors.expand((error) => [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: error,
                  textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewErrorTextColorOn,
                  }),
                  className: "x97 hc",
                  key: "0",
                }),
              ],
              className: "x6e hc",
              key: error?.toString(),
            }),
          ]),
        ],
        className: "x7c hc h",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        UserButtonWithState({
          d3eState: this.d3eState,
          _onAddUserHandler: this.onAddUserHandler,
          lead: this.lead,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "AddNewConnectionView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (d3eState: AddNewConnectionViewRefs): void => {
    this.lead.restore();

    this.navigator.close();
  };
  public onAddUserHandler = async (
    d3eState: AddNewConnectionViewRefs
  ): Promise<void> => {
    this.setErrors([]);

    if (this.validate().isEmpty) {
      if (this.lead.phone === null || this.lead.phone.isEmpty) {
        this.setPhoneErrors(true);

        return;
      } else {
        this.setPhoneErrors(false);
      }

      if (this.lead.phone !== null || this.lead.phone.isNotEmpty) {
        this.lead.setPhone(this.lead.phone.replaceAll(" ", ""));
      }

      this.lead.setCreatedBy(this.user);

      if (this.lead.email !== null) {
        this.lead.setEmail(this.lead.email.toLowerCase());

        this.lead.setEmail(this.lead.email.replaceAll(" ", ""));
      }

      this.lead.setStatus(LeadStatus.New);

      let result: Result<Lead> = await this.lead.save();

      if (result.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({ message: "Successfully Saved" })
        );

        let leadAssignment: LeadAssignment = new LeadAssignment({
          lead: this.lead,
          assignedTo: this.user,
          assignedBy: this.user,
        });

        leadAssignment.setAssignedDate(D3EDate.now());

        let result2: Result<LeadAssignment> = await leadAssignment.save();

        if (result2.status === ResultStatus.Success) {
          this.navigator.close();
        } else {
          this.setErrors(result2.errors);
        }
      } else {
        this.setErrors(result.errors);
      }
    }
  };
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: AddNewConnectionViewRefs
  ): void => {
    this.lead.setName(val);
  };
  public labelWithInputField4onChanged = (
    val: string,
    d3eState: AddNewConnectionViewRefs
  ): void => {
    this.lead.setPhone(val);
  };
  public labelWithInputField5onChanged = (
    val: string,
    d3eState: AddNewConnectionViewRefs
  ): void => {
    this.lead.setEmail(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get userButton() {
    return this.d3eState.userButton;
  }
}
export default function AddNewConnectionView(props: AddNewConnectionViewProps) {
  return React.createElement(_AddNewConnectionViewState, {
    ..._AddNewConnectionViewState.defaultProps,
    ...props,
  });
}
