import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _SearchLocationsOnSelectItem = (item: string) => void;

type _OnItemOnTap = (d3eState: _ItemState) => void;

type _NoneOptionOnTap = (d3eState: SearchLocationsRefs) => void;

export interface SearchLocationsProps extends BaseUIProps {
  key?: string;
  value?: string;
  items?: Array<string>;
  itemColor?: ui.Color;
  _itemsHash?: number;
  onSelectItem?: _SearchLocationsOnSelectItem;
}
/// To store state data for SearchLocations
class SearchLocationsRefs {
  idController: ui.ScrollController = new ui.ScrollController();
  public itemState: Map<string, _ItemState> = new Map();
  public noneOption: NoneOptionState = new NoneOptionState();
  public forItem(item: string): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface NoneOptionWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SearchLocationsRefs;
  _onPressedNone?: _NoneOptionOnTap;
  itemColor: ui.Color;
}

class NoneOptionState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NoneOptionWithState extends ObservableComponent<NoneOptionWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NoneOptionWithStateProps) {
    super(props);

    this.initState();
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public get noneOption(): NoneOptionState {
    return this.props.d3eState.noneOption;
  }
  public get d3eState(): SearchLocationsRefs {
    return this.props.d3eState;
  }
  public get _onPressedNone(): _NoneOptionOnTap {
    return this.props._onPressedNone;
  }
  public initState() {
    super.initState();

    this.updateObservable("noneOption", null, this.noneOption);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["itemColor", "noneOption", "noneOption.hover"], this.rebuild);
  }
  public noneOptionOnEnter(event): void {
    return this.noneOption.setHover(true);
  }
  public noneOptionOnExit(event): void {
    return this.noneOption.setHover(false);
  }
  public dispose(): void {
    this.noneOption.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.noneOption.hover }, {}),
      decoration: this.noneOption.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: " --None-- ",
        style: new ui.TextStyle({ color: this.itemColor }),
        className: "x8e5 hc",
      }),
      onEnter: (event) => {
        this.noneOptionOnEnter(event);
      },
      onExit: (event) => {
        this.noneOptionOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onPressedNone(this.d3eState);
      },
      className: "x779b hc",
    });
  }
}
function NoneOptionWithState(props: NoneOptionWithStateProps) {
  return React.createElement(_NoneOptionWithState, props);
}

interface OnItemWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onSelectListTile?: _OnItemOnTap;
  item: string;
  itemColor: ui.Color;
}

class OnItemState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _OnItemWithState extends ObservableComponent<OnItemWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: OnItemWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): string {
    return this.props.item;
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public get onItem(): OnItemState {
    return this.props.d3eState.onItem;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onSelectListTile(): _OnItemOnTap {
    return this.props._onSelectListTile;
  }
  public initState() {
    super.initState();

    this.updateObservable("onItem", null, this.onItem);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["item", "itemColor", "onItem", "onItem.hover"], this.rebuild);
  }
  public onItemOnEnter(event): void {
    return this.onItem.setHover(true);
  }
  public onItemOnExit(event): void {
    return this.onItem.setHover(false);
  }
  public dispose(): void {
    this.onItem.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.onItem.hover }, {}),
      decoration: this.onItem.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: this.item !== null ? this.item.toString() : "None",
        style: new ui.TextStyle({ color: this.itemColor }),
        className: "x198e hc",
      }),
      onEnter: (event) => {
        this.onItemOnEnter(event);
      },
      onExit: (event) => {
        this.onItemOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onSelectListTile(this.d3eState);
      },
      className: "xe04 hc",
    });
  }
}
function OnItemWithState(props: OnItemWithStateProps) {
  return React.createElement(_OnItemWithState, props);
}

class _ItemState {
  parent: SearchLocationsRefs;
  item: string;
  onItem: OnItemState = new OnItemState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _SearchLocationsState extends ObservableComponent<SearchLocationsProps> {
  static defaultProps = {
    value: "",
    itemColor: null,
    items: [],
    onSelectItem: null,
  };
  d3eState: SearchLocationsRefs = new SearchLocationsRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchLocationsProps) {
    super(props);

    this.initState();
  }
  public get value(): string {
    return this.props.value;
  }
  public get items(): Array<string> {
    return this.props.items;
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["itemColor", "items", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SearchLocationsProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.itemColor !== this.props.itemColor) {
      this.fire("itemColor", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.ListView({
      shrinkWrap: true,
      controller: this.d3eState.idController,
      children: [
        this.items
          .toList()
          .expand((item) => [
            OnItemWithState({
              d3eState: this.d3eState.forItem(item),
              _onSelectListTile: this.onSelectListTile,
              item: item,
              itemColor: this.itemColor,
              key: item?.toString(),
            }),
          ]),
        this.value !== null
          ? NoneOptionWithState({
              d3eState: this.d3eState,
              _onPressedNone: this.onPressedNone,
              itemColor: this.itemColor,
            })
          : [],
        this.items.isEmpty
          ? TextView({ data: " No Results Found ", className: "x83b hc" })
          : [],
      ],
      className: ui.join(this.props.className, "SearchLocations xee3 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onSelectListTile = (d3eState: _ItemState): void => {
    this.onSelectItem(d3eState.item);
  };
  public onPressedNone = (d3eState: SearchLocationsRefs): void => {
    this.onSelectItem(null);
  };
  public get onSelectItem(): _SearchLocationsOnSelectItem {
    return this.props.onSelectItem;
  }
  public get noneOption() {
    return this.d3eState.noneOption;
  }
}
export default function SearchLocations(props: SearchLocationsProps) {
  return React.createElement(
    _SearchLocationsState,
    { ..._SearchLocationsState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.items, "items")
  );
}
