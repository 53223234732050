import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import RecentInteractionView from "./RecentInteractionView";
import User from "../models/User";
import TextView from "./TextView";
import RecentConnectionView from "./RecentConnectionView";
import { BuildContext } from "../classes/BuildContext";

export interface ConnectionsPageProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _ConnectionsPageState extends ObservableComponent<ConnectionsPageProps> {
  static defaultProps = { user: null, client: null, data: null };
  recentLead: Lead = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ConnectionsPageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(["client", "data", "recentLead", "user"], this.rebuild);
  }
  public componentDidUpdate(prevProps: ConnectionsPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setRecentLead(val: Lead): void {
    let isValChanged: boolean = this.recentLead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("recentLead", this.recentLead, val);

    this.recentLead = val;

    this.fire("recentLead", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            ui.Container({
              width: 300,
              child: RecentConnectionView({
                user: this.user,
                client: this.client,
                data: this.data,
                onLeadClick: (lead) => {
                  this.onRecentLeadButtonPressed(lead);
                },
              }),
              key: "0",
              className: "x305 hc vc v",
            }),
            this.recentLead !== null
              ? ui.Container({
                  expand: true,
                  child: RecentInteractionView({
                    user: this.user,
                    lead: this.recentLead,
                    client: this.client,
                    data: this.data,
                  }),
                  className: "x619 hc vc h v",
                })
              : ui.Container({
                  child: TextView({
                    data: "Select a Contact to view interactions",
                    textAlign: ui.TextAlign.center,
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                    }),
                    className: "xe71 hc vc",
                  }),
                  className: "xa63 hc vc h",
                }),
          ],
          className: "xb2b hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "ConnectionsPage x1c1 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onRecentLeadButtonPressed = (lead: Lead): void => {
    this.setRecentLead(lead);
  };
}
export default function ConnectionsPage(props: ConnectionsPageProps) {
  return React.createElement(_ConnectionsPageState, {
    ..._ConnectionsPageState.defaultProps,
    ...props,
  });
}
