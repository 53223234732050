import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FileUploadResult from "../classes/FileUploadResult";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import SuccessMessage from "../classes/SuccessMessage";
import FileToUpload from "../classes/FileToUpload";
import MicroSoftAcc from "../models/MicroSoftAcc";
import EventBus from "../utils/EventBus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import UserRole from "../classes/UserRole";
import Result from "../classes/Result";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import PlatformClient from "../classes/PlatformClient";
import Browser from "../classes/Browser";
import Company from "../models/Company";
import TextView from "./TextView";
import LabelDropdown from "./LabelDropdown";
import RPCServices from "../rpc/RPCServices";
import CollectionUtils from "../utils/CollectionUtils";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: AddUserViewRefs) => void;

type _UserButtonOnPressed = (d3eState: AddUserViewRefs) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: AddUserViewRefs
) => void;

type _LabelWithInputField1OnChanged = (
  text: string,
  d3eState: AddUserViewRefs
) => void;

type _LabelDropdownOnChanged = (
  text: UserRole,
  d3eState: AddUserViewRefs
) => void;

type _LabelWithInputField2OnChanged = (
  text: string,
  d3eState: AddUserViewRefs
) => void;

type _LabelWithInputField4OnChanged = (
  text: string,
  d3eState: AddUserViewRefs
) => void;

type _LabelWithInputField3OnChanged = (
  text: string,
  d3eState: AddUserViewRefs
) => void;

export interface AddUserViewProps extends BaseUIProps {
  key?: string;
  user: User;
}
/// To store state data for AddUserView
class AddUserViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public userButton: UserButtonState = new UserButtonState();
}

interface UserButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddUserViewRefs;
  _onAddUserHandler?: _UserButtonOnPressed;
}

class UserButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UserButtonWithState extends ObservableComponent<UserButtonWithStateProps> {
  userButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get userButton(): UserButtonState {
    return this.props.d3eState.userButton;
  }
  public get d3eState(): AddUserViewRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _UserButtonOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("userButton", null, this.userButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["userButton", "userButton.", "userButton.hover"], this.rebuild);
  }
  public userButtonOnEnter(event): void {
    return this.userButton.setHover(true);
  }
  public userButtonOnExit(event): void {
    return this.userButton.setHover(false);
  }
  public dispose(): void {
    this.userButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.userButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.userButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.userButton.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Add New User" }),
        onEnter: (event) => {
          this.userButtonOnEnter(event);
        },
        onExit: (event) => {
          this.userButtonOnExit(event);
        },
      }),
      className: "x64",
    });
  }
}
function UserButtonWithState(props: UserButtonWithStateProps) {
  return React.createElement(_UserButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddUserViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): AddUserViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "xaa",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _AddUserViewState extends ObservableComponent<AddUserViewProps> {
  static defaultProps = { user: null };
  d3eState: AddUserViewRefs = new AddUserViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _firstNameErrors: Array<string> = [];
  private _lastNameErrors: Array<string> = [];
  private _emailErrors: Array<string> = [];
  public emailUniqueError: string;
  private _passwordErrors: Array<string> = [];
  private _roleErrors: Array<string> = [];
  private _companyErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddUserViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "emailErrors",
        "errors",
        "firstNameErrors",
        "lastNameErrors",
        "passwordErrors",
        "roleErrors",
        "user",
        "user.email",
        "user.firstName",
        "user.lastName",
        "user.password",
        "user.phoneNumber",
        "user.profile",
        "user.role",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: AddUserViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get firstNameErrors(): Array<string> {
    return this._firstNameErrors ?? [];
  }
  public set firstNameErrors(val: Array<string>) {
    this._firstNameErrors.clear();

    this._firstNameErrors.addAll(val);
  }
  public async computeFirstNameErrors(): Promise<void> {
    this.firstNameErrors = [];

    let it: string = this.user.firstName;

    if (it == null || it === "") {
      this.firstNameErrors.add("First Name cannot be empty.");
    }

    this.fire("firstNameErrors", this);
  }
  public get lastNameErrors(): Array<string> {
    return this._lastNameErrors ?? [];
  }
  public set lastNameErrors(val: Array<string>) {
    this._lastNameErrors.clear();

    this._lastNameErrors.addAll(val);
  }
  public async computeLastNameErrors(): Promise<void> {
    this.lastNameErrors = [];

    let it: string = this.user.lastName;

    if (it == null || it === "") {
      this.lastNameErrors.add("Last Name cannot be empty.");
    }

    this.fire("lastNameErrors", this);
  }
  public get emailErrors(): Array<string> {
    return this._emailErrors ?? [];
  }
  public set emailErrors(val: Array<string>) {
    this._emailErrors.clear();

    this._emailErrors.addAll(val);
  }
  public async computeEmailErrors(): Promise<void> {
    this.emailErrors = [];

    let it: string = this.user.email;

    if (it == null || it === "") {
      this.emailErrors.add("Email cannot be empty.");
    }

    this.fire("emailErrors", this);
  }
  public async checkUserEmailUniqueInCompany(): Promise<void> {
    if (this.user == null || this.user.company == null) {
      return;
    }

    let isUnique: boolean =
      await RPCServices.getUniqueChecker().checkUserEmailUniqueInCompany(
        this.user.company,
        this.user,
        this.user.email
      );

    if (!isUnique) {
      this.emailUniqueError = "Duplicate email found with this value.";
    }
  }
  public get passwordErrors(): Array<string> {
    return this._passwordErrors ?? [];
  }
  public set passwordErrors(val: Array<string>) {
    this._passwordErrors.clear();

    this._passwordErrors.addAll(val);
  }
  public async computePasswordErrors(): Promise<void> {
    this.passwordErrors = [];

    let it: string = this.user.password;

    if (it == null || it === "") {
      this.passwordErrors.add("Password cannot be empty.");
    }

    this.fire("passwordErrors", this);
  }
  public get roleErrors(): Array<string> {
    return this._roleErrors ?? [];
  }
  public set roleErrors(val: Array<string>) {
    this._roleErrors.clear();

    this._roleErrors.addAll(val);
  }
  public async computeRoleErrors(): Promise<void> {
    this.roleErrors = [];

    let it: UserRole = this.user.role;

    this.fire("roleErrors", this);
  }
  public get companyErrors(): Array<string> {
    return this._companyErrors ?? [];
  }
  public set companyErrors(val: Array<string>) {
    this._companyErrors.clear();

    this._companyErrors.addAll(val);
  }
  public async computeCompanyErrors(): Promise<void> {
    this.companyErrors = [];

    let it: Company = this.user.company;

    if (it == null) {
      this.companyErrors.add("Company cannot be null.");
    }

    this.fire("companyErrors", this);
  }
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.firstNameErrors.isNotEmpty) {
      return false;
    }

    if (this.lastNameErrors.isNotEmpty) {
      return false;
    }

    if (this.emailErrors.isNotEmpty) {
      return false;
    }

    if (this.passwordErrors.isNotEmpty) {
      return false;
    }

    if (this.roleErrors.isNotEmpty) {
      return false;
    }

    if (this.companyErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeFirstNameErrors();

    errors.addAll(this.firstNameErrors);

    this.computeLastNameErrors();

    errors.addAll(this.lastNameErrors);

    this.computeEmailErrors();

    errors.addAll(this.emailErrors);

    this.computePasswordErrors();

    errors.addAll(this.passwordErrors);

    this.computeRoleErrors();

    errors.addAll(this.roleErrors);

    this.computeCompanyErrors();

    errors.addAll(this.companyErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Add New User",
      content: ui.Column({
        children: [
          ui.Container({
            alignment: ui.Alignment.center,
            child:
              this.user.profile === null ||
              this.user.profile.downloadUrl === null
                ? ui.ClipRRect({
                    borderRadius: ui.BorderRadius.circular(50),
                    child: ui.AssetImage({
                      path: "images/profile1.png",
                      width: 100,
                      height: 100,
                      fit: ui.BoxFit.fill,
                      className: "x74f hc vc",
                    }),
                  })
                : ui.ClipRRect({
                    borderRadius: ui.BorderRadius.circular(50),
                    child: ui.NetworkImage({
                      url:
                        this.user.profile.downloadUrl +
                        "?width=100&height=100&inline=true",
                      width: 100,
                      height: 100,
                      fit: ui.BoxFit.fill,
                      className: "x73 hc vc",
                    }),
                  }),
            onTap: (e) => {
              e.stopPropagation();

              this.onPressedProfileButtonHandler(this.d3eState);
            },
            className: "x29 hc vc",
            key: "0",
          }),
          LabelWithInputField({
            name: "First Name",
            placeHolder: "Enter First Name",
            value: this.user.firstName,
            errors: this.firstNameErrors,
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputFieldonChanged(text, this.d3eState);
            },
            className: "xf6 hc h",
            key: "1",
          }),
          LabelWithInputField({
            name: "Last Name",
            placeHolder: "Enter Last Name",
            value: this.user.lastName,
            errors: this.lastNameErrors,
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputField1onChanged(text, this.d3eState);
            },
            className: "xd4 hc h",
            key: "2",
          }),
          LabelDropdown<UserRole>({
            name: "Role",
            placeHolder: "Select Role",
            value: this.user.role,
            items: UserRole.values,
            errors: this.roleErrors,
            width: 500,
            onChanged: (text) => {
              this.labelDropdownonChanged(text, this.d3eState);
            },
            builder: (context, item) => {
              return TextView({ data: item.name });
            },
            className: "x217 hc h",
            key: "3",
          }),
          LabelWithInputField({
            name: "Email Address",
            placeHolder: "Enter Email Address",
            value: this.user.email,
            errors: this.emailErrors,
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputField2onChanged(text, this.d3eState);
            },
            className: "x38 hc h",
            key: "4",
          }),
          LabelWithInputField({
            name: "Password",
            placeHolder: "Enter Password",
            value: this.user.password,
            errors: this.passwordErrors,
            obscureText: true,
            isRequired: true,
            onChanged: (text) => {
              this.labelWithInputField4onChanged(text, this.d3eState);
            },
            className: "x8d hc h",
            key: "5",
          }),
          LabelWithInputField({
            name: "Phone Number ( Enter with country code )",
            placeHolder: "Enter Phone Number",
            value: this.user.phoneNumber,
            onChanged: (text) => {
              this.labelWithInputField3onChanged(text, this.d3eState);
            },
            className: "x60 hc h",
            key: "6",
          }),
          this.errors.expand((error) => [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: error,
                  textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewErrorTextColorOn,
                  }),
                  className: "x2b hc",
                  key: "0",
                }),
              ],
              className: "x28 hc",
              key: error?.toString(),
            }),
          ]),
        ],
        className: "xb1 hc h",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        UserButtonWithState({
          d3eState: this.d3eState,
          _onAddUserHandler: this.onAddUserHandler,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "AddUserView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedProfileButtonHandler = async (
    d3eState: AddUserViewRefs
  ): Promise<void> => {
    let fileToUpload: FileToUpload = await Browser.selectFile(
      ["jpg", "jpeg", "png"],
      () => {}
    );

    if (fileToUpload !== null) {
      let res: FileUploadResult = await PlatformClient.upload(fileToUpload);

      if (res.success) {
        this.user.setProfile(res.file);
      }
    }
  };
  public cancelButtonHandler = (d3eState: AddUserViewRefs): void => {
    this.user.restore();

    this.navigator.close();
  };
  public onAddUserHandler = async (
    d3eState: AddUserViewRefs
  ): Promise<void> => {
    this.setErrors([]);

    if (this.user.phoneNumber !== null) {
      this.user.setPhoneNumber(this.user.phoneNumber.replaceAll(" ", ""));
    }

    if (this.validate().isEmpty) {
      this.user.setEmail(this.user.email.replaceAll(" ", ""));

      this.user.setEmail(this.user.email.toLowerCase());

      let result: Result<User> = await this.user.save();

      if (result.status === ResultStatus.Success) {
        let acc: MicroSoftAcc = new MicroSoftAcc({ user: this.user });

        let accResult: Result<MicroSoftAcc> = await acc.save();

        if (accResult.status === ResultStatus.Success) {
          EventBus.get().fire(
            new SuccessMessage({ message: "Successfully Saved" })
          );

          this.navigator.close();
        } else {
          this.setErrors(accResult.errors);
        }
      } else {
        this.setErrors(result.errors);
      }
    }
  };
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setFirstName(val);
  };
  public labelWithInputField1onChanged = (
    val: string,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setLastName(val);
  };
  public labelDropdownonChanged = (
    val: UserRole,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setRole(val);
  };
  public labelWithInputField2onChanged = (
    val: string,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setEmail(val);
  };
  public labelWithInputField4onChanged = (
    val: string,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setPassword(val);
  };
  public labelWithInputField3onChanged = (
    val: string,
    d3eState: AddUserViewRefs
  ): void => {
    this.user.setPhoneNumber(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get userButton() {
    return this.d3eState.userButton;
  }
}
export default function AddUserView(props: AddUserViewProps) {
  return React.createElement(_AddUserViewState, {
    ..._AddUserViewState.defaultProps,
    ...props,
  });
}
