import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Loader from "./Loader";
import SuccessMessage from "../classes/SuccessMessage";
import LeadStatus from "../classes/LeadStatus";
import Popup from "./Popup";
import EventBus from "../utils/EventBus";
import ApolloSearchFilterView from "./ApolloSearchFilterView";
import User from "../models/User";
import LeadResultItem from "../classes/LeadResultItem";
import SearchCriteria from "../classes/SearchCriteria";
import Query from "../classes/Query";
import SearchComponent from "./SearchComponent";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import ScrollView2 from "./ScrollView2";
import FindApolloIdRequest from "../models/FindApolloIdRequest";
import Lead from "../models/Lead";
import TextView from "./TextView";
import ApolloLeadDetailsView from "./ApolloLeadDetailsView";
import FailureMessage from "../classes/FailureMessage";
import Checkbox from "./Checkbox";
import RPCServices from "../rpc/RPCServices";
import CollectionUtils from "../utils/CollectionUtils";
import Divider from "./Divider";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _AddLeadsButtonOnPressed = (d3eState: ApolloLeadsPageRefs) => void;

export interface ApolloLeadsPageProps extends BaseUIProps {
  key?: string;
  user: User;
}
/// To store state data for ApolloLeadsPage
class ApolloLeadsPageRefs {
  public addLeadsButton: AddLeadsButtonState = new AddLeadsButtonState();
  columnScrollController: ui.ScrollController = new ui.ScrollController();
}

interface AddLeadsButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: ApolloLeadsPageRefs;
  _onAddLeadsButtonHandler?: _AddLeadsButtonOnPressed;
}

class AddLeadsButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AddLeadsButtonWithState extends ObservableComponent<AddLeadsButtonWithStateProps> {
  addLeadsButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddLeadsButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get addLeadsButton(): AddLeadsButtonState {
    return this.props.d3eState.addLeadsButton;
  }
  public get d3eState(): ApolloLeadsPageRefs {
    return this.props.d3eState;
  }
  public get _onAddLeadsButtonHandler(): _AddLeadsButtonOnPressed {
    return this.props._onAddLeadsButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("addLeadsButton", null, this.addLeadsButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["addLeadsButton", "addLeadsButton.", "addLeadsButton.hover"],
      this.rebuild
    );
  }
  public addLeadsButtonOnEnter(event): void {
    return this.addLeadsButton.setHover(true);
  }
  public addLeadsButtonOnExit(event): void {
    return this.addLeadsButton.setHover(false);
  }
  public dispose(): void {
    this.addLeadsButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 20.0, 15.0, 0.0, new Map()),
      child: Button({
        padding: this.addLeadsButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.addLeadsButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.addLeadsButton.disable,
        onPressed: () => {
          this._onAddLeadsButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Import" }),
        onEnter: (event) => {
          this.addLeadsButtonOnEnter(event);
        },
        onExit: (event) => {
          this.addLeadsButtonOnExit(event);
        },
      }),
      className: "x5a",
    });
  }
}
function AddLeadsButtonWithState(props: AddLeadsButtonWithStateProps) {
  return React.createElement(_AddLeadsButtonWithState, props);
}

class _ApolloLeadsPageState extends ObservableComponent<ApolloLeadsPageProps> {
  static defaultProps = { user: null };
  d3eState: ApolloLeadsPageRefs = new ApolloLeadsPageRefs();
  selectAll: boolean = false;
  selectedItems: Array<LeadResultItem> = ListWrapper.widget(
    this,
    "selectedItems"
  );
  leads: Array<LeadResultItem> = ListWrapper.widget(this, "leads");
  searchText: string = "";
  hackVar: boolean = false;
  loading: boolean = false;
  filterLeadList: Array<LeadResultItem> = ListWrapper.widget(
    this,
    "filterLeadList"
  );
  leadDetail: LeadResultItem = null;
  apolloDetailsPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ApolloLeadsPageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["leads", "leads.name", "searchText"], this.computeFilterLeadList);

    this.computeFilterLeadList();

    this.on(
      [
        "filterLeadList",
        "filterLeadList.company",
        "filterLeadList.email",
        "filterLeadList.emailStatus",
        "filterLeadList.name",
        "filterLeadList.phone",
        "filterLeadList.status",
        "hackVar",
        "leads",
        "loading",
        "selectAll",
        "selectedItems",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ApolloLeadsPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setSelectAll(val: boolean): void {
    let isValChanged: boolean = this.selectAll !== val;

    if (!isValChanged) {
      return;
    }

    this.selectAll = val;

    this.fire("selectAll", this);
  }
  public setSelectedItems(val: Array<LeadResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.selectedItems,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("selectedItems", this.selectedItems, val);

    this.selectedItems.clear();

    this.selectedItems.addAll(val);

    this.fire("selectedItems", this);
  }
  public addToSelectedItems(val: LeadResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.selectedItems.contains(val)) this.selectedItems.add(val);
    } else {
      this.selectedItems.remove(this.selectedItems.elementAt(index));

      this.selectedItems.add(val);
    }

    this.fire("selectedItems", this, val, true);

    this.updateObservable("selectedItems", null, val);
  }
  public removeFromSelectedItems(val: LeadResultItem): void {
    this.selectedItems.remove(val);

    this.fire("selectedItems", this, val, false);

    this.removeObservable("selectedItems", val);
  }
  public setLeads(val: Array<LeadResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: LeadResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: LeadResultItem): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public setHackVar(val: boolean): void {
    let isValChanged: boolean = this.hackVar !== val;

    if (!isValChanged) {
      return;
    }

    this.hackVar = val;

    this.fire("hackVar", this);
  }
  public setLoading(val: boolean): void {
    let isValChanged: boolean = this.loading !== val;

    if (!isValChanged) {
      return;
    }

    this.loading = val;

    this.fire("loading", this);
  }
  public setFilterLeadList(val: Array<LeadResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filterLeadList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("filterLeadList", this.filterLeadList, val);

    this.filterLeadList.clear();

    this.filterLeadList.addAll(val);

    this.fire("filterLeadList", this);
  }
  public addToFilterLeadList(val: LeadResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.filterLeadList.contains(val)) this.filterLeadList.add(val);
    } else {
      this.filterLeadList.remove(this.filterLeadList.elementAt(index));

      this.filterLeadList.add(val);
    }

    this.fire("filterLeadList", this, val, true);

    this.updateObservable("filterLeadList", null, val);
  }
  public removeFromFilterLeadList(val: LeadResultItem): void {
    this.filterLeadList.remove(val);

    this.fire("filterLeadList", this, val, false);

    this.removeObservable("filterLeadList", val);
  }
  public computeFilterLeadList = (): void => {
    try {
      this.setFilterLeadList(
        Array.from(
          this.leads
            .where((b) =>
              b.name.toLowerCase().contains(this.searchText.toLowerCase())
            )
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFilterLeadList : " + exception.toString()
      );

      this.setFilterLeadList([]);
    }
  };
  public setLeadDetail(val: LeadResultItem): void {
    let isValChanged: boolean = this.leadDetail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadDetail", this.leadDetail, val);

    this.leadDetail = val;

    this.fire("leadDetail", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              children: [
                ui.Container({
                  decoration: new ui.BoxDecoration({
                    border: new ui.Border({
                      right: new ui.BorderSide({
                        width: 1.5,
                        style: ui.BorderStyle.solid,
                        color: cStyle.c16,
                      }),
                    }),
                  }),
                  width: 350,
                  child: ApolloSearchFilterView({
                    onSearchName: (value) => {
                      this.onSearchHandler(value, this.d3eState);
                    },
                  }),
                  key: "0",
                  className: "x11 hc vc v",
                }),
                ScrollView2({
                  child: ui.Column({
                    children: [
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                        children: [
                          ui.Row({
                            children: [
                              ui.Container({
                                margin: ui.EdgeInsets.fromLTRB(
                                  0.0,
                                  10.0,
                                  0.0,
                                  0.0,
                                  new Map()
                                ),
                                width: 300,
                                child: SearchComponent({
                                  placeholder: "Search",
                                  onChanged: (value) => {
                                    this.onGlobalSearchHandler(
                                      value,
                                      this.d3eState
                                    );
                                  },
                                }),
                                key: "0",
                                className: "x03 hc",
                              }),
                            ],
                            key: "0",
                          }),
                          this.selectedItems.isNotEmpty
                            ? AddLeadsButtonWithState({
                                d3eState: this.d3eState,
                                _onAddLeadsButtonHandler:
                                  this.onAddLeadsButtonHandler,
                              })
                            : [],
                        ],
                        className: "x547 hc h",
                        key: "0",
                      }),
                      ui.Container({
                        height: 1,
                        margin: ui.EdgeInsets.symmetric({
                          horizontal: 0.0,
                          vertical: 15.0,
                          transitions: new Map(),
                        }),
                        child: Divider({}),
                        key: "1",
                        className: "xff3 hc vc h",
                      }),
                      this.leads.isEmpty && !this.loading
                        ? TextView({
                            data: "No Leads Found",
                            style: new ui.TextStyle({
                              fontWeight: ui.FontWeight.bold,
                            }),
                            className: "x09 hc",
                          })
                        : ui.Table({
                            defaultColumnWidth: new ui.FlexColumnWidth(1),
                            columnWidths: {
                              0: new ui.FixedColumnWidth(50),
                              1: new ui.FlexColumnWidth(1),
                              2: new ui.FlexColumnWidth(1),
                              3: new ui.FlexColumnWidth(1),
                              4: new ui.FlexColumnWidth(1),
                              5: new ui.FlexColumnWidth(1),
                              6: new ui.FlexColumnWidth(1),
                            },
                            children: [
                              ui.TableRow({
                                children: [
                                  ui.TableCell({
                                    verticalAlignment:
                                      ui.TableCellVerticalAlignment.middle,
                                    child: Checkbox({
                                      value: this.selectAll,
                                      activeColor: cStyle.c1,
                                      onChanged: (val) => {
                                        this.onChangedCheckbox2Handler(
                                          val,
                                          this.d3eState
                                        );
                                      },
                                      key: this.selectAll.toString(),
                                      className: "hc",
                                    }),
                                    className: "x3d hc",
                                    key: "0",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Name",
                                      className: "hc",
                                    }),
                                    className: "xdc4 hc",
                                    key: "1",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Company",
                                      className: "hc",
                                    }),
                                    className: "x33 hc",
                                    key: "2",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Phone",
                                      className: "hc",
                                    }),
                                    className: "x48 hc",
                                    key: "3",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Email",
                                      className: "hc",
                                    }),
                                    className: "x19 hc",
                                    key: "4",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Email Status",
                                      className: "hc",
                                    }),
                                    className: "x9e9 hc",
                                    key: "5",
                                  }),
                                  ui.TableCell({
                                    child: TextView({
                                      data: "Status",
                                      className: "hc",
                                    }),
                                    className: "x972 hc",
                                    key: "6",
                                  }),
                                ],
                                className: "x12 hc",
                                key: "0",
                              }),
                              !this.loading
                                ? (this.filterLeadList ?? []).expand(
                                    (forItem) => [
                                      ui.TableRow({
                                        children: [
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: Checkbox({
                                              value:
                                                this.selectedItems.contains(
                                                  forItem
                                                ),
                                              activeColor: cStyle.c1,
                                              onChanged: (val) => {
                                                this.onChangedCheckboxHandler(
                                                  val,
                                                  forItem,
                                                  this.d3eState
                                                );
                                              },
                                              key: this.selectedItems
                                                .contains(forItem)
                                                .toString(),
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "0",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: TextView({
                                              data: forItem.name,
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "1",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: TextView({
                                              data: forItem.company,
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "2",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: TextView({
                                              data: forItem.phone,
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "3",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: TextView({
                                              data: forItem.email,
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "4",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: TextView({
                                              data: forItem.emailStatus,
                                              className: "hc",
                                            }),
                                            className: "hc",
                                            key: "5",
                                          }),
                                          ui.TableCell({
                                            verticalAlignment:
                                              ui.TableCellVerticalAlignment
                                                .middle,
                                            child: ui.Row({
                                              mainAxisAlignment:
                                                ui.MainAxisAlignment.start,
                                              children: [
                                                ui.Container({
                                                  alignment:
                                                    ui.Alignment.center,
                                                  states: ui.joinStates(
                                                    {
                                                      "data-c0":
                                                        forItem.status.name ===
                                                        "New",
                                                      "data-c1":
                                                        forItem.status.name ===
                                                        "Inprogress",
                                                      "data-c2":
                                                        forItem.status.name ===
                                                        "Closed",
                                                      "data-c3":
                                                        forItem.status.name ===
                                                        "Contacted",
                                                    },
                                                    {}
                                                  ),
                                                  padding:
                                                    forItem.status.name ===
                                                    "New"
                                                      ? ui.EdgeInsets.symmetric(
                                                          {
                                                            horizontal: 20.0,
                                                            vertical: 5.0,
                                                            transitions:
                                                              new Map(),
                                                          }
                                                        )
                                                      : forItem.status.name ===
                                                        "Inprogress"
                                                      ? ui.EdgeInsets.symmetric(
                                                          {
                                                            horizontal: 20.0,
                                                            vertical: 5.0,
                                                            transitions:
                                                              new Map(),
                                                          }
                                                        )
                                                      : forItem.status.name ===
                                                        "Closed"
                                                      ? ui.EdgeInsets.symmetric(
                                                          {
                                                            horizontal: 20.0,
                                                            vertical: 5.0,
                                                            transitions:
                                                              new Map(),
                                                          }
                                                        )
                                                      : forItem.status.name ===
                                                        "Contacted"
                                                      ? ui.EdgeInsets.symmetric(
                                                          {
                                                            horizontal: 20.0,
                                                            vertical: 5.0,
                                                            transitions:
                                                              new Map(),
                                                          }
                                                        )
                                                      : null,
                                                  decoration:
                                                    forItem.status.name ===
                                                    "New"
                                                      ? new ui.BoxDecoration({
                                                          color: cStyle.c4,
                                                          borderRadius:
                                                            ui.BorderRadius.circular(
                                                              3.0
                                                            ),
                                                        })
                                                      : forItem.status.name ===
                                                        "Inprogress"
                                                      ? new ui.BoxDecoration({
                                                          color: cStyle.c5,
                                                          borderRadius:
                                                            ui.BorderRadius.circular(
                                                              3.0
                                                            ),
                                                        })
                                                      : forItem.status.name ===
                                                        "Closed"
                                                      ? new ui.BoxDecoration({
                                                          color: cStyle.c7,
                                                          borderRadius:
                                                            ui.BorderRadius.circular(
                                                              3.0
                                                            ),
                                                        })
                                                      : forItem.status.name ===
                                                        "Contacted"
                                                      ? new ui.BoxDecoration({
                                                          color: cStyle.c6,
                                                          borderRadius:
                                                            ui.BorderRadius.circular(
                                                              3.0
                                                            ),
                                                        })
                                                      : new ui.BoxDecoration(
                                                          {}
                                                        ),
                                                  child: TextView({
                                                    data: forItem.status.name,
                                                    style: new ui.TextStyle({
                                                      color: cStyle.c14,
                                                    }),
                                                    className: "x40 hc",
                                                  }),
                                                  className: "x2f hc",
                                                  key: "0",
                                                }),
                                                ui.Container({ key: "1" }),
                                              ],
                                              className: "x66 hc h",
                                            }),
                                            className: "x276 hc h",
                                            key: "6",
                                          }),
                                        ],
                                        onTap: (e) => {
                                          e.stopPropagation();

                                          this.onLeadSelectHandler(
                                            forItem,
                                            this.d3eState
                                          );
                                        },
                                        className: "xe0 hc h",
                                        key: forItem?.toString(),
                                      }),
                                    ]
                                  )
                                : [],
                            ],
                            className: "x62 hc h",
                          }),
                      this.loading
                        ? ui.Row({
                            mainAxisAlignment: ui.MainAxisAlignment.center,
                            crossAxisAlignment: ui.CrossAxisAlignment.center,
                            children: [
                              Loader({
                                backgroundColor: cStyle.c14,
                                valueColor: cStyle.c1,
                                key: "0",
                              }),
                              TextView({
                                data: "Loading...",
                                className: "x96f",
                                key: "1",
                              }),
                            ],
                            className: "x77 hc h",
                          })
                        : [],
                    ],
                    key: "1",
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "xf3 hc vc h v",
                  controller: this.d3eState.columnScrollController,
                }),
              ],
              className: "x57 hc vc h v",
              key: "0",
            }),
          ],
          className: "x24 hc vc h v",
          key: "0",
        }),
        false
          ? TextView({ data: this.hackVar.toString(), className: "xbb hc" })
          : [],
      ],
      className: ui.join(this.props.className, "ApolloLeadsPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onAddLeadsButtonHandler = async (
    d3eState: ApolloLeadsPageRefs
  ): Promise<void> => {
    if (this.selectedItems.isNotEmpty) {
      let result: boolean = await RPCServices.getApolloRPCService().saveLeads(
        this.selectedItems
      );

      if (result) {
        EventBus.get().fire(
          new SuccessMessage({ message: "Leads Imported Successfully" })
        );
      } else {
        EventBus.get().fire(
          new FailureMessage({ message: "Failed to import leads" })
        );
      }
    }
  };
  public onChangedCheckboxHandler = async (
    val: boolean,
    forItem: LeadResultItem,
    d3eState: ApolloLeadsPageRefs
  ): Promise<void> => {
    if (val) {
      if (!this.selectedItems.contains(forItem)) {
        this.selectedItems.add(forItem);

        // check if the lead is exist in the list or not

        let leads: Array<Lead> = (
          await Query.get().getFindApolloId(
            UsageConstants.QUERY_GETFINDAPOLLOID_APOLLOLEADSPAGE_EVENTHANDLERS_ONCHANGEDCHECKBOXHANDLER_BLOCK,
            new FindApolloIdRequest({ apolloId: forItem.apolloId })
          )
        ).items;

        if (leads.isNotEmpty) {
          EventBus.get().fire(
            new FailureMessage({ message: "Lead already exist" })
          );

          this.selectedItems.remove(forItem);
        }
      }
    } else {
      this.selectedItems.remove(forItem);
    }

    if (this.selectedItems.length === this.leads.length) {
      this.setSelectAll(true);
    } else {
      this.setSelectAll(false);
    }

    this.setHackVar(!this.hackVar);
  };
  public onChangedCheckbox2Handler = (
    val: boolean,
    d3eState: ApolloLeadsPageRefs
  ): void => {
    if (val) {
      this.setSelectedItems(this.leads);
    } else {
      this.setSelectedItems([]);
    }

    this.setSelectAll(val);
  };
  public onSearchHandler = async (
    value: SearchCriteria,
    d3eState: ApolloLeadsPageRefs
  ): Promise<void> => {
    this.setLoading(true);

    this.setLeads(await RPCServices.getApolloRPCService().searchLeads(value));

    if (this.leads.isNotEmpty) {
      this.setLoading(false);
    }

    if (this.leads.isEmpty) {
      this.setLoading(false);

      EventBus.get().fire(new FailureMessage({ message: "No Leads Found" }));
    }
  };
  public onGlobalSearchHandler = (
    value: string,
    d3eState: ApolloLeadsPageRefs
  ): void => {
    this.setSearchText(value);
  };
  public onLeadSelectHandler = (
    forItem: LeadResultItem,
    d3eState: ApolloLeadsPageRefs
  ): void => {
    this.setLeadDetail(forItem);

    this.showApolloDetailsPopup();
  };
  public onCloseHandler = (): void => {
    this.hideApolloDetailsPopup();
  };
  public dispose(): void {
    this.apolloDetailsPopupPopup?.dispose();

    super.dispose();
  }
  public showApolloDetailsPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.apolloDetailsPopupPopup?.dispose();

    this.apolloDetailsPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: ApolloLeadDetailsView({
          lead: this.leadDetail,
          onClose: () => {
            this.onCloseHandler();
          },
        }),
        className: "x9f hc vc",
      }),
    });

    this.apolloDetailsPopupPopup.showPopup(this.context);
  }
  public hideApolloDetailsPopup(): void {
    this.apolloDetailsPopupPopup?.dispose();
  }
  public get addLeadsButton() {
    return this.d3eState.addLeadsButton;
  }
}
export default function ApolloLeadsPage(props: ApolloLeadsPageProps) {
  return React.createElement(_ApolloLeadsPageState, {
    ..._ApolloLeadsPageState.defaultProps,
    ...props,
  });
}
