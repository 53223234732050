import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Env from "../classes/Env";
import Button from "./Button";
import Loader from "./Loader";
import LabelWithInputField from "./LabelWithInputField";
import CheckboxWithText from "./CheckboxWithText";
import LoginResult from "../classes/LoginResult";
import User from "../models/User";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ForgotPasswordButtonOnPressed = (d3eState: LoginPageRefs) => void;

type _LoginButtonOnPressed = (d3eState: LoginPageRefs) => void;

type _EmailFieldOnChanged = (text: string, d3eState: LoginPageRefs) => void;

type _PasswordFieldOnChanged = (text: string, d3eState: LoginPageRefs) => void;

type _CheckboxWithTextOnChanged = (
  text: boolean,
  d3eState: LoginPageRefs
) => void;

export interface LoginPageProps extends BaseUIProps {
  key?: string;
}
/// To store state data for LoginPage
class LoginPageRefs {
  public forgotPasswordButton: ForgotPasswordButtonState =
    new ForgotPasswordButtonState();
  public loginButton: LoginButtonState = new LoginButtonState();
}

interface LoginButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LoginPageRefs;
  _onLoginButtonHandler?: _LoginButtonOnPressed;
  loading: boolean;
}

class LoginButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _LoginButtonWithState extends ObservableComponent<LoginButtonWithStateProps> {
  loginButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LoginButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get loading(): boolean {
    return this.props.loading;
  }
  public get loginButton(): LoginButtonState {
    return this.props.d3eState.loginButton;
  }
  public get d3eState(): LoginPageRefs {
    return this.props.d3eState;
  }
  public get _onLoginButtonHandler(): _LoginButtonOnPressed {
    return this.props._onLoginButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("loginButton", null, this.loginButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["loading", "loginButton", "loginButton.", "loginButton.hover"],
      this.rebuild
    );
  }
  public loginButtonOnEnter(event): void {
    return this.loginButton.setHover(true);
  }
  public loginButtonOnExit(event): void {
    return this.loginButton.setHover(false);
  }
  public dispose(): void {
    this.loginButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.loginButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.loginButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.loginButton.disable,
        onPressed: () => {
          this._onLoginButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            this.loading
              ? Loader({ backgroundColor: cStyle.c14, valueColor: cStyle.c1 })
              : [],
            TextView({ data: "Login", className: "x943", key: "1" }),
          ],
          className: "xa8f hc h",
        }),
        onEnter: (event) => {
          this.loginButtonOnEnter(event);
        },
        onExit: (event) => {
          this.loginButtonOnExit(event);
        },
      }),
      className: "x92b7 hc h",
    });
  }
}
function LoginButtonWithState(props: LoginButtonWithStateProps) {
  return React.createElement(_LoginButtonWithState, props);
}

interface ForgotPasswordButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LoginPageRefs;
  _forgotPasswordHandler?: _ForgotPasswordButtonOnPressed;
}

class ForgotPasswordButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ForgotPasswordButtonWithState extends ObservableComponent<ForgotPasswordButtonWithStateProps> {
  forgotPasswordButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ForgotPasswordButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get forgotPasswordButton(): ForgotPasswordButtonState {
    return this.props.d3eState.forgotPasswordButton;
  }
  public get d3eState(): LoginPageRefs {
    return this.props.d3eState;
  }
  public get _forgotPasswordHandler(): _ForgotPasswordButtonOnPressed {
    return this.props._forgotPasswordHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "forgotPasswordButton",
      null,
      this.forgotPasswordButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "forgotPasswordButton",
        "forgotPasswordButton.",
        "forgotPasswordButton.hover",
      ],
      this.rebuild
    );
  }
  public forgotPasswordButtonOnEnter(event): void {
    return this.forgotPasswordButton.setHover(true);
  }
  public forgotPasswordButtonOnExit(event): void {
    return this.forgotPasswordButton.setHover(false);
  }
  public dispose(): void {
    this.forgotPasswordButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.forgotPasswordButton.disable,
      onPressed: () => {
        this._forgotPasswordHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Forgot Password ?",
        style: new ui.TextStyle({ fontSize: 14 }),
        className: "xb8c",
      }),
      onEnter: (event) => {
        this.forgotPasswordButtonOnEnter(event);
      },
      onExit: (event) => {
        this.forgotPasswordButtonOnExit(event);
      },
      className: "x5648",
    });
  }
}
function ForgotPasswordButtonWithState(
  props: ForgotPasswordButtonWithStateProps
) {
  return React.createElement(_ForgotPasswordButtonWithState, props);
}

class _LoginPageState extends ObservableComponent<LoginPageProps> {
  d3eState: LoginPageRefs = new LoginPageRefs();
  email: string = "";
  password: string = "";
  emailInfo: boolean = false;
  passwordInfo: boolean = false;
  errorMessages: string = "";
  showError: boolean = false;
  loading: boolean = false;
  isRememberMe: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LoginPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "email",
        "emailInfo",
        "isRememberMe",
        "loading",
        "password",
        "passwordInfo",
        "showError",
      ],
      this.rebuild
    );
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this.email !== val;

    if (!isValChanged) {
      return;
    }

    this.email = val;

    this.fire("email", this);
  }
  public setPassword(val: string): void {
    let isValChanged: boolean = this.password !== val;

    if (!isValChanged) {
      return;
    }

    this.password = val;

    this.fire("password", this);
  }
  public setEmailInfo(val: boolean): void {
    let isValChanged: boolean = this.emailInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.emailInfo = val;

    this.fire("emailInfo", this);
  }
  public setPasswordInfo(val: boolean): void {
    let isValChanged: boolean = this.passwordInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.passwordInfo = val;

    this.fire("passwordInfo", this);
  }
  public setErrorMessages(val: string): void {
    let isValChanged: boolean = this.errorMessages !== val;

    if (!isValChanged) {
      return;
    }

    this.errorMessages = val;

    this.fire("errorMessages", this);
  }
  public setShowError(val: boolean): void {
    let isValChanged: boolean = this.showError !== val;

    if (!isValChanged) {
      return;
    }

    this.showError = val;

    this.fire("showError", this);
  }
  public setLoading(val: boolean): void {
    let isValChanged: boolean = this.loading !== val;

    if (!isValChanged) {
      return;
    }

    this.loading = val;

    this.fire("loading", this);
  }
  public setIsRememberMe(val: boolean): void {
    let isValChanged: boolean = this.isRememberMe !== val;

    if (!isValChanged) {
      return;
    }

    this.isRememberMe = val;

    this.fire("isRememberMe", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.center,
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      children: [
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisSize: ui.MainAxisSize.min,
          children: [
            TextView({
              data: "Lead Management",
              style: new ui.TextStyle({
                color: cStyle.c1,
                fontSize: cStyle.tTextViewHeadlineTwoFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineTwoFontWeightOn,
              }),
              className: "x16b hc",
              key: "0",
            }),
            false
              ? ui.AssetImage({
                  path: "images/apple-touch-icon.png",
                  width: 180,
                  height: 180,
                  className: "xc73 hc vc",
                })
              : [],
            TextView({
              data: "Login",
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
              }),
              className: "x57f hc",
              key: "2",
            }),
            LabelWithInputField({
              name: "Email",
              placeHolder: "Enter Email Address",
              value: this.email,
              errors: this.emailInfo ? ["Please Enter Email Address"] : [],
              isRequired: true,
              onTap: () => {
                this.onTapEmailFiledHandler(this.d3eState);
              },
              onChanged: (text) => {
                this.emailFieldonChanged(text, this.d3eState);
              },
              className: "x5d6 hc h",
              key: "3",
            }),
            ui.Container({
              margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
              child: LabelWithInputField({
                name: "Password",
                placeHolder: "Enter Password",
                value: this.password,
                errors: this.passwordInfo ? ["Please Enter Password"] : [],
                obscureText: true,
                isRequired: true,
                onTap: () => {
                  this.onTapPasswordFiledHandler(this.d3eState);
                },
                onSubmitted: (value) => {
                  this.onSubmitHandler(value, this.d3eState);
                },
                onChanged: (text) => {
                  this.passwordFieldonChanged(text, this.d3eState);
                },
              }),
              key: "4",
              className: "x2a8 hc h",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                CheckboxWithText({
                  title: "Keep me signed in",
                  value: this.isRememberMe,
                  activeColor: cStyle.c1,
                  onChanged: (text) => {
                    this.checkboxWithTextonChanged(text, this.d3eState);
                  },
                  key: "0",
                }),
                ForgotPasswordButtonWithState({
                  d3eState: this.d3eState,
                  _forgotPasswordHandler: this.forgotPasswordHandler,
                  key: "1",
                }),
              ],
              className: "xaaab hc h",
              key: "5",
            }),
            this.showError
              ? TextView({
                  data: "Invalid authentication details. Please Enter valid details.",
                  textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                  style: new ui.TextStyle({
                    fontSize: 14,
                    color: cStyle.tTextViewErrorTextColorOn,
                  }),
                  className: "xfea hc",
                })
              : [],
            ui.Row({
              children: [
                LoginButtonWithState({
                  d3eState: this.d3eState,
                  _onLoginButtonHandler: this.onLoginButtonHandler,
                  loading: this.loading,
                  key: "0",
                }),
              ],
              className: "x9a2 hc h",
              key: "7",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                TextView({
                  data: "Version " + Env.get().buildVersion,
                  style: new ui.TextStyle({ fontSize: 14 }),
                  className: "xc6f",
                  key: "0",
                }),
              ],
              className: "x45f hc h",
              key: "8",
            }),
          ],
          className: "x6fc hc",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "LoginPage xb14 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapEmailFiledHandler = (d3eState: LoginPageRefs): void => {
    this.setEmailInfo(false);
  };
  public onTapPasswordFiledHandler = (d3eState: LoginPageRefs): void => {
    this.setPasswordInfo(false);
  };
  public authenticatingUser = async (): Promise<void> => {
    if (this.email.isEmpty) {
      this.setEmailInfo(true);
    }

    if (this.password.isEmpty) {
      this.setPasswordInfo(true);
    }

    this.setLoading(true);

    let result: LoginResult =
      await Query.get().loginManagerUserWithEmailAndPassword(
        UsageConstants.QUERY_LOGINMANAGERUSERWITHEMAILANDPASSWORD_LOGINPAGE_EVENTHANDLERS_AUTHENTICATINGUSER_BLOCK,
        { email: this.email.toLowerCase(), password: this.password }
      );

    if (result.success) {
      let user: User = result.userObject as User;

      user.setIsRememberMe(this.isRememberMe);

      let userResult: Result<User> = await user.save();

      this.navigator.pushRoutePage({ user: user });
    } else {
      this.setErrorMessages(result.failureMessage);

      if (this.errorMessages === result.failureMessage) {
        this.setShowError(true);
      }
    }

    this.setLoading(false);
  };
  public onLoginButtonHandler = (d3eState: LoginPageRefs): void => {
    this.authenticatingUser();
  };
  public onSubmitHandler = (value: string, d3eState: LoginPageRefs): void => {
    this.authenticatingUser();
  };
  public forgotPasswordHandler = (d3eState: LoginPageRefs): void => {
    // Test bundle upload

    this.navigator.pushForgotPasswordPage();
  };
  public emailFieldonChanged = (val: string, d3eState: LoginPageRefs): void => {
    this.setEmail(val);
  };
  public passwordFieldonChanged = (
    val: string,
    d3eState: LoginPageRefs
  ): void => {
    this.setPassword(val);
  };
  public checkboxWithTextonChanged = (
    val: boolean,
    d3eState: LoginPageRefs
  ): void => {
    this.setIsRememberMe(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get forgotPasswordButton() {
    return this.d3eState.forgotPasswordButton;
  }
  public get loginButton() {
    return this.d3eState.loginButton;
  }
}
export default function LoginPage(props: LoginPageProps) {
  return React.createElement(_LoginPageState, props);
}
