import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import ResultStatus from "../classes/ResultStatus";
import RecentConnectionItemProfileView from "./RecentConnectionItemProfileView";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import CallInteraction from "../classes/CallInteraction";
import IconButton from "./IconButton";
import Result from "../classes/Result";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import CallStatus from "../classes/CallStatus";
import DialPadView from "./DialPadView";
import ListWrapper from "../utils/ListWrapper";
import InteractionType from "../classes/InteractionType";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import CollectionUtils from "../utils/CollectionUtils";
import RecentCreatedLeads from "../classes/RecentCreatedLeads";
import D3EDisposable from "../rocket/D3EDisposable";
import EventBus from "../utils/EventBus";
import AddNewConnectionView from "./AddNewConnectionView";
import DateTime from "../core/DateTime";
import Interaction from "../models/Interaction";
import RecentCreatedLeadsRequest from "../models/RecentCreatedLeadsRequest";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _RecentConnectionViewOnLeadClick = (lead: Lead) => void;

export interface RecentConnectionViewProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
  onLeadClick: _RecentConnectionViewOnLeadClick;
}

class _RecentConnectionViewState extends ObservableComponent<RecentConnectionViewProps> {
  static defaultProps = {
    user: null,
    client: null,
    data: null,
    onLeadClick: null,
  };
  idController: ui.ScrollController = new ui.ScrollController();
  lead: Lead = null;
  selectedLead: Lead = null;
  request: RecentCreatedLeadsRequest = null;
  leadsList: RecentCreatedLeads = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  lastInteraction: Interaction = null;
  addConnectionPopupPopup: Popup;
  dialPadViewPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public lastInteractionDisposable: D3EDisposable;
  public constructor(props: RecentConnectionViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_RECENTCONNECTIONVIEW_LASTINTERACTION_SYNCHRONISE
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["request", "request.user"], this.computeLeadsList);

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.updateSyncProperty("data", this.props.data);

    this.on(["lead", "lead.lastInteraction"], this.computeLastInteraction);

    this.computeLastInteraction();

    this.on(
      ["data", "data.deviceReady", "leads", "selectedLead"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RecentConnectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this.lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lead", this.lead, val);

    this.lead = val;

    this.fire("lead", this);
  }
  public setSelectedLead(val: Lead): void {
    let isValChanged: boolean = this.selectedLead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedLead", this.selectedLead, val);

    this.selectedLead = val;

    this.fire("selectedLead", this);
  }
  public setRequest(val: RecentCreatedLeadsRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: RecentCreatedLeads): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    MessageDispatch.get().dispose(this.leadsList);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getRecentCreatedLeads(
          UsageConstants.QUERY_GETRECENTCREATEDLEADS_RECENTCONNECTIONVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public setLastInteraction(val: Interaction): void {
    let isValChanged: boolean = this.lastInteraction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lastInteraction", this.lastInteraction, val);

    this.lastInteractionDisposable?.dispose();

    this.lastInteraction = val;

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_RECENTCONNECTIONVIEW_LASTINTERACTION_SYNCHRONISE
    );

    this.fire("lastInteraction", this);
  }
  public computeLastInteraction = (): void => {
    try {
      this.setLastInteraction(this.lead.lastInteraction);
    } catch (exception) {
      console.log(
        " exception in computeLastInteraction : " + exception.toString()
      );

      this.setLastInteraction(null);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "Recent Connections",
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
              }),
              className: "x0b6",
              key: "0",
            }),
            ui.Row({
              children: [
                this.data.deviceReady
                  ? ui.Container({
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        2.0,
                        0.0,
                        0.0,
                        new Map()
                      ),
                      child: IconButton({
                        icon: MaterialIcons.dialpad,
                        tooltip: "Dial Pad",
                        size: 19,
                        onPressed: () => {
                          this.onDialPadButtonClick();
                        },
                      }),
                      className: "xbd6",
                    })
                  : [],
                IconButton({
                  icon: MaterialIcons.add,
                  tooltip: "Add Connection",
                  onPressed: () => {
                    this.onIconButtonClick();
                  },
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          className: "xa6b",
          key: "0",
        }),
        ui.Container({ className: "xab8 vc", key: "1" }),
        ui.ListView({
          controller: this.idController,
          children: [
            this.leads.expand((item) => [
              ui.Container({
                margin: ui.EdgeInsets.fromLTRB(0.0, 3.0, 0.0, 12.0, new Map()),
                child: RecentConnectionItemProfileView({
                  lead: item,
                  selectedLead: this.selectedLead,
                  onLeadClick: (lead) => {
                    this.onRecentLeadButtonPressed(lead, item);
                  },
                }),
                key: item?.ident,
                className: "xeb4",
              }),
            ]),
          ],
          className: "x917 vc v",
          key: "2",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RecentConnectionView xf36 vc v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(new RecentCreatedLeadsRequest({ user: this.user }));
  };
  public onIconButtonClick = (): void => {
    this.setLead(new Lead({ createdBy: this.user }));

    this.showAddConnectionPopup();
  };
  public onDialPadButtonClick = (): void => {
    this.showDialPadViewPopup();
  };
  public onRecentLeadButtonPressed = (lead: Lead, item: Lead): void => {
    this.onLeadClick(item);

    this.setSelectedLead(item);
  };
  public onDialCallHandle = async (
    callingLead: Lead,
    user: User
  ): Promise<void> => {
    this.hideDialPadViewPopup();

    let connectCall: boolean = await this.client.makeCall(
      this.user.twilioNumber,
      callingLead.phone,
      {
        onStatus: (s) => {
          this.onStatusChange(s, callingLead);
        },
        onFailure: (m, e) => {
          this.onConnectionFailure(m, e);
        },
      }
    );
  };
  public onStatusChange = async (
    status: string,
    callingLead: Lead
  ): Promise<void> => {
    if (this.lastInteraction === null) {
      this.setLastInteraction(
        new Interaction({
          lead: callingLead,
          fromNumber: this.user.twilioNumber,
          toNumber: callingLead.phone,
          callStatus: CallStatus.Initiated,
          type: InteractionType.Call,
          sid: this.client.callSid,
          isIncoming: false,
          handledBy: this.user,
        })
      );

      EventBus.get().fire(
        new CallInteraction({
          interaction: this.lastInteraction,
          data: this.data,
          lead: callingLead,
        })
      );

      let result: Result<Interaction> = await this.lastInteraction.save();

      if (result.status === ResultStatus.Success) {
      }
    } else if (this.lastInteraction !== null) {
      let st: CallStatus = CallStatus.Initiated;

      switch (status) {
        case "ringing": {
          st = CallStatus.Ringing;
          break;
        }

        case "completed": {
          st = CallStatus.Completed;
          break;
        }

        case "accepted": {
          {
            st = CallStatus.InProgress;

            this.lastInteraction.setStartTime(DateTime.now());
          }
          break;
        }

        case "failed": {
          st = CallStatus.Failed;
          break;
        }

        case "busy": {
          st = CallStatus.Busy;
          break;
        }

        case "open": {
          st = CallStatus.InProgress;
          break;
        }

        case "connecting": {
          st = CallStatus.Connecting;
          break;
        }

        case "initiated": {
          st = CallStatus.Initiated;
          break;
        }
        default: {
        }
      }

      this.lastInteraction.setCallStatus(st);
    }
  };
  public onConnectionFailure = (message: string, error: string): void => {
    EventBus.get().fire(new FailureMessage({ message: message }));
  };
  public get onLeadClick(): _RecentConnectionViewOnLeadClick {
    return this.props.onLeadClick;
  }
  public dispose(): void {
    this.lastInteractionDisposable?.dispose();

    MessageDispatch.get().dispose(this.leadsList);

    this.addConnectionPopupPopup?.dispose();

    this.dialPadViewPopupPopup?.dispose();

    super.dispose();
  }
  public showAddConnectionPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.addConnectionPopupPopup?.dispose();

    this.addConnectionPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: AddNewConnectionView({ lead: this.lead, user: this.user }),
        className: "x7f2 hc vc",
      }),
    });

    this.addConnectionPopupPopup.showPopup(this.context);
  }
  public showDialPadViewPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.dialPadViewPopupPopup?.dispose();

    this.dialPadViewPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        height: 450,
        width: 300,
        child: DialPadView({
          client: this.client,
          user: this.user,
          onCallConnect: (callingLead, user) => {
            this.onDialCallHandle(callingLead, user);
          },
        }),
        className: "x08d hc vc",
      }),
    });

    this.dialPadViewPopupPopup.showPopup(this.context);
  }
  public hideAddConnectionPopup(): void {
    this.addConnectionPopupPopup?.dispose();
  }
  public hideDialPadViewPopup(): void {
    this.dialPadViewPopupPopup?.dispose();
  }
}
export default function RecentConnectionView(props: RecentConnectionViewProps) {
  return React.createElement(_RecentConnectionViewState, {
    ..._RecentConnectionViewState.defaultProps,
    ...props,
  });
}
