import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import D3EDisposable from "../rocket/D3EDisposable";
import MaterialIcons from "../icons/MaterialIcons";
import LeadUtils from "../classes/LeadUtils";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import DateFormat from "../classes/DateFormat";
import Button from "./Button";
import Browser from "../classes/Browser";
import TextView from "./TextView";
import DFile from "../classes/DFile";
import Html from "./Html";
import IconView from "./IconView";
import MailMessage from "../models/MailMessage";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _AttachmentButtonOnPressed = (d3eState: _AttachmentState) => void;

export interface MailPreviewViewProps extends BaseUIProps {
  key?: string;
  message: MailMessage;
}
/// To store state data for MailPreviewView
class MailPreviewViewRefs {
  public attachmentState: Map<DFile, _AttachmentState> = new Map();
  public forAttachment(attachment: DFile): _AttachmentState {
    let res = this.attachmentState.get(attachment);

    if (res == null) {
      res = new _AttachmentState(this, attachment);

      this.attachmentState.set(attachment, res);
    }

    return res;
  }
}

interface AttachmentButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _AttachmentState;
  _onAttchmentButton?: _AttachmentButtonOnPressed;
  attachment: DFile;
}

class AttachmentButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AttachmentButtonWithState extends ObservableComponent<AttachmentButtonWithStateProps> {
  attachmentButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AttachmentButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get attachment(): DFile {
    return this.props.attachment;
  }
  public get attachmentButton(): AttachmentButtonState {
    return this.props.d3eState.attachmentButton;
  }
  public get d3eState(): _AttachmentState {
    return this.props.d3eState;
  }
  public get _onAttchmentButton(): _AttachmentButtonOnPressed {
    return this.props._onAttchmentButton;
  }
  public initState() {
    super.initState();

    this.updateObservable("attachmentButton", null, this.attachmentButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "attachment",
        "attachmentButton",
        "attachmentButton.",
        "attachmentButton.hover",
      ],
      this.rebuild
    );
  }
  public attachmentButtonOnEnter(event): void {
    return this.attachmentButton.setHover(true);
  }
  public attachmentButtonOnExit(event): void {
    return this.attachmentButton.setHover(false);
  }
  public dispose(): void {
    this.attachmentButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.attachmentButton.hover
          ? cStyle.tButtonAttachmentButtonPaddingOnHover
          : cStyle.tButtonAttachmentButtonPaddingOn,
        decoration: this.attachmentButton.hover
          ? cStyle.tButtonAttachmentButtonDecorationOnHover
          : cStyle.tButtonAttachmentButtonDecorationOn,
        disable: this.attachmentButton.disable,
        onPressed: () => {
          this._onAttchmentButton(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: this.attachment.name, className: "hc" }),
        onEnter: (event) => {
          this.attachmentButtonOnEnter(event);
        },
        onExit: (event) => {
          this.attachmentButtonOnExit(event);
        },
      }),
      className: "xcc6 hc",
    });
  }
}
function AttachmentButtonWithState(props: AttachmentButtonWithStateProps) {
  return React.createElement(_AttachmentButtonWithState, props);
}

class _AttachmentState {
  parent: MailPreviewViewRefs;
  attachment: DFile;
  attachmentButton: AttachmentButtonState = new AttachmentButtonState();
  public constructor(parent, attachment) {
    this.parent = parent;

    this.attachment = attachment;
  }
}

class _MailPreviewViewState extends ObservableComponent<MailPreviewViewProps> {
  static defaultProps = { message: null };
  d3eState: MailPreviewViewRefs = new MailPreviewViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public messageDisposable: D3EDisposable;
  public constructor(props: MailPreviewViewProps) {
    super(props);

    this.initState();
  }
  public get message(): MailMessage {
    return this.props.message;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForMessage();

    this.messageDisposable = MessageDispatch.get().syncObject(
      this.props.message,
      UsageConstants.QUERY_GETMAILMESSAGEBYID_MAILPREVIEWVIEW_MESSAGE_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("message", this.props.message);

    this.on(
      [
        "message",
        "message.attachments",
        "message.content",
        "message.hasAttachments",
        "message.inbound",
        "message.receivedDateTime",
        "message.recipientEmail",
        "message.recipientName",
        "message.senderName",
        "message.sentDateTime",
        "message.subject",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MailPreviewViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.message !== this.props.message) {
      this.updateObservable("message", prevProps.message, this.props.message);

      this.runFetchDataQueryForMessage();

      this.fire("message", this);
    }
  }
  public runFetchDataQueryForMessage = (): void => {
    if (this.message == null) {
      return;
    }

    Query.get().getMailMessageById(
      UsageConstants.QUERY_GETMAILMESSAGEBYID_MAILPREVIEWVIEW_MESSAGE_FETCHDATA,
      this.message.id
    );
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            TextView({
              data: this.message.subject,
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
              className: "xc8cf hc",
              key: "0",
            }),
            this.message.hasAttachments
              ? ui.Wrap({
                  spacing: 5,
                  children: [
                    IconView({
                      icon: MaterialIcons.attach_file,
                      className: "xbb2 hc",
                      key: "0",
                    }),
                    this.message.attachments.expand((attachment) => [
                      AttachmentButtonWithState({
                        d3eState: this.d3eState.forAttachment(attachment),
                        _onAttchmentButton: this.onAttchmentButton,
                        attachment: attachment,
                        key: attachment?.toString(),
                      }),
                    ]),
                  ],
                  className: "x4c4b hc",
                })
              : [],
          ],
          className: "xb70b hc",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Container({
                      decoration: new ui.BoxDecoration({
                        color: ui.HexColor.fromHexStr(
                          LeadUtils.getColorForFirstLetter(
                            this.message.senderName !== null
                              ? this.message.senderName
                              : this.message.recipientName
                          )
                        ),
                        borderRadius: ui.BorderRadius.circular(20.0),
                      }),
                      alignment: ui.Alignment.center,
                      child: TextView({
                        data:
                          this.message.senderName === null
                            ? this.message.recipientName.substring(0, 1)
                            : this.message.senderName.substring(0, 1),
                        textAlign: ui.TextAlign.center,
                        style: new ui.TextStyle({
                          color: cStyle.c14,
                          fontWeight: ui.FontWeight.bold,
                        }),
                        className: "x7af hc vc",
                      }),
                      className: "x0439 hc vc",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        TextView({
                          data: this.message.senderName,
                          className: "x768",
                          key: "0",
                        }),
                        TextView({
                          data: this.message.inbound
                            ? "To : " + this.message.recipientEmail
                            : "To : " + this.message.recipientEmail,
                          className: "xe09",
                          key: "1",
                        }),
                      ],
                      key: "1",
                    }),
                  ],
                  key: "0",
                }),
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    TextView({
                      data:
                        this.message.sentDateTime === null
                          ? new DateFormat("EEE, dd MMM y HH:mm").format(
                              this.message.receivedDateTime
                            )
                          : new DateFormat("EEE, dd MMM y HH:mm").format(
                              this.message.sentDateTime
                            ),
                      className: "x3fc",
                      key: "0",
                    }),
                  ],
                  key: "1",
                }),
              ],
              className: "x3d9 hc h",
              key: "0",
            }),
            ui.Container({
              child: Html({ data: this.message.content, className: "hc" }),
              className: "xe50 hc",
              key: "1",
            }),
          ],
          className: "x169 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(
        this.props.className,
        "MailPreviewView x96d hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onAttchmentButton = (d3eState: _AttachmentState): void => {
    Browser.download(d3eState.attachment);
  };
  public dispose(): void {
    this.messageDisposable?.dispose();

    super.dispose();
  }
}
export default function MailPreviewView(props: MailPreviewViewProps) {
  return React.createElement(_MailPreviewViewState, {
    ..._MailPreviewViewState.defaultProps,
    ...props,
  });
}
