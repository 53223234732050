import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import InfoMessage from "../classes/InfoMessage";
import Popup from "./Popup";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import UserRole from "../classes/UserRole";
import CallInteraction from "../classes/CallInteraction";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import TwilioCall from "../classes/TwilioCall";
import CallStatus from "../classes/CallStatus";
import TwilioClient from "../classes/TwilioClient";
import Duration from "../core/Duration";
import FailureMessage from "../classes/FailureMessage";
import SideMenuView from "./SideMenuView";
import InteractionsRequest from "../models/InteractionsRequest";
import PageRouter from "./PageRouter";
import PopupTargetController from "./PopupTargetController";
import D3EDisposable from "../rocket/D3EDisposable";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import Timer from "../utils/Timer";
import FailureMessageView from "./FailureMessageView";
import CallProgressView from "./CallProgressView";
import PageHeaderView from "./PageHeaderView";
import Interaction from "../models/Interaction";
import InfoMessageView from "./InfoMessageView";
import SuccessMessageView from "./SuccessMessageView";
import RPCServices from "../rpc/RPCServices";
import IncomingCallView from "./IncomingCallView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { Subscription } from "rxjs";
import { BuildContext } from "../classes/BuildContext";

export interface RoutePageProps extends BaseUIProps {
  key?: string;
  user: User;
}

class _RoutePageState extends ObservableComponent<RoutePageProps> {
  static defaultProps = { user: null };
  private _successMessageSubscription: Subscription;
  private _failureMessageSubscription: Subscription;
  private _infoMessageSubscription: Subscription;
  private _callInteractionSubscription: Subscription;
  message: string = "";
  client: TwilioClient = null;
  title: string = "";
  data: CallData = null;
  interaction: CallInteraction = null;
  enableHeaderCallProgress: boolean = false;
  activeCall: TwilioCall = null;
  successMessagePopup: Popup;
  failureMessagePopup: Popup;
  infoMessageViewPopup: Popup;
  callProgressPopupPopup: Popup;
  incommingCallViewPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public containerPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public userDisposable: D3EDisposable;
  public constructor(props: RoutePageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.userDisposable = MessageDispatch.get().syncObject(
      this.props.user,
      UsageConstants.SUBSCRIPTION_ONUSERCHANGE_ROUTEPAGE_USER_SYNCHRONISE
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();

    this._successMessageSubscription = EventBus.get()
      .on<SuccessMessage>(SuccessMessage)
      .subscribe((value) => this.onSuccessMessage(value));

    this._failureMessageSubscription = EventBus.get()
      .on<FailureMessage>(FailureMessage)
      .subscribe((value) => this.onFailureMessage(value));

    this._infoMessageSubscription = EventBus.get()
      .on<InfoMessage>(InfoMessage)
      .subscribe((value) => this.onInfoMessage(value));

    this._callInteractionSubscription = EventBus.get()
      .on<CallInteraction>(CallInteraction)
      .subscribe((value) => this.onCallInteraction(value));
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "client",
        "data",
        "enableHeaderCallProgress",
        "interaction",
        "title",
        "user",
        "user.company",
        "user.company.isLeadService",
        "user.role",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RoutePageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setMessage(val: string): void {
    let isValChanged: boolean = this.message !== val;

    if (!isValChanged) {
      return;
    }

    this.message = val;

    this.fire("message", this);
  }
  public setClient(val: TwilioClient): void {
    let isValChanged: boolean = this.client !== val;

    if (!isValChanged) {
      return;
    }

    this.client = val;

    this.fire("client", this);
  }
  public setTitle(val: string): void {
    let isValChanged: boolean = this.title !== val;

    if (!isValChanged) {
      return;
    }

    this.title = val;

    this.fire("title", this);
  }
  public setData(val: CallData): void {
    let isValChanged: boolean = this.data !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("data", this.data, val);

    this.data = val;

    this.fire("data", this);
  }
  public setInteraction(val: CallInteraction): void {
    let isValChanged: boolean = this.interaction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("interaction", this.interaction, val);

    this.interaction = val;

    this.fire("interaction", this);
  }
  public setEnableHeaderCallProgress(val: boolean): void {
    let isValChanged: boolean = this.enableHeaderCallProgress !== val;

    if (!isValChanged) {
      return;
    }

    this.enableHeaderCallProgress = val;

    this.fire("enableHeaderCallProgress", this);
  }
  public setActiveCall(val: TwilioCall): void {
    let isValChanged: boolean = this.activeCall !== val;

    if (!isValChanged) {
      return;
    }

    this.activeCall = val;

    this.fire("activeCall", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            this.user.role === UserRole.Manager
              ? ui.Container({
                  width: !this.user.company.isLeadService ? 72 : 190,
                  child: SideMenuView({
                    user: this.user,
                    company: this.user.company,
                    onMenuChanged: (menu) => {
                      this.onMenuClicked(menu);
                    },
                    states: ui.joinStates(
                      { "data-c0": !this.user.company.isLeadService },
                      {}
                    ),
                  }),
                  className: "x6d7 hc vc v",
                  states: ui.joinStates(
                    { "data-c0": !this.user.company.isLeadService },
                    {}
                  ),
                })
              : [],
            ui.Column({
              children: [
                PageHeaderView({
                  title: this.title,
                  data: this.data,
                  callInteraction: this.interaction,
                  enableCallView: this.enableHeaderCallProgress,
                  client: this.client,
                  user: this.user,
                  onConnect: () => {
                    this.onDeviceConnect();
                  },
                  onMaximizeCall: () => {
                    this.onMaximizeViewCallView();
                  },
                  className: "x879 hc h",
                  key: "0",
                }),
                ui.Row({
                  children: [
                    ui.Container({
                      d3eRef: this.containerPopupTargetController.handleRef,
                      className: "x279 hc vc h",
                      key: "0",
                    }),
                  ],
                  className: "x1c4 hc h",
                  key: "1",
                }),
                ui.Container({
                  child: PageRouter({
                    target: "main",
                    className: "x6af hc vc h v",
                  }),
                  className: "xa1f hc vc h v",
                  key: "2",
                }),
              ],
              className: "x4c2 hc vc h v",
              key: "1",
            }),
          ],
          className: "xa4d hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "RoutePage x729 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setClient(new TwilioClient(""));

    this.client.data = new CallData({ deviceReady: false });

    this.setData(this.client.data);

    if (this.user.twilioNumber !== null && this.user.twilioNumber.isNotEmpty) {
      this.deviceConnect();
    }

    new Timer(new Duration({ seconds: 1 }), () => {
      this.setTitle("Dashboard");

      if (this.user.role === UserRole.Manager) {
        this.navigator.pushDashboardPage({
          user: this.user,
          target: "main",
          replace: true,
          client: this.client,
          data: this.data,
        });
      } else {
        this.navigator.pushConnectionsPage({
          user: this.user,
          target: "main",
          replace: false,
          client: this.client,
          data: this.data,
        });
      }
    });
  };
  public onInfoMessage = (msg: InfoMessage): void => {
    this.setMessage(msg.message !== null ? msg.message : "TODO");

    this.showInfoMessageView({ autoClose: true, model: false });

    new Timer(new Duration({ seconds: 5 }), () => {
      this.hideInfoMessageView();
    });
  };
  public onSuccessMessage = (msg: SuccessMessage): void => {
    this.setMessage(msg.message);

    this.showSuccessMessage({ autoClose: true, model: false });

    new Timer(new Duration({ seconds: 5 }), () => {
      this.hideSuccessMessage();
    });
  };
  public onFailureMessage = (msg: FailureMessage): void => {
    this.setMessage(msg.message);

    this.showFailureMessage({ autoClose: true, model: false });

    new Timer(new Duration({ seconds: 5 }), () => {
      this.hideFailureMessage();
    });
  };
  public onCloseMessage = (): void => {
    this.hideFailureMessage();

    this.hideSuccessMessage();
  };
  public onCancelPressed = (): void => {
    this.onCloseMessage();
  };
  public onCancelPressed2 = (): void => {
    this.onCloseMessage();
  };
  public onCancelPressed3 = (): void => {
    this.onCloseMessage();
  };
  public onMenuClicked = (menu: string): void => {
    this.setTitle(menu);

    switch (menu) {
      case "Dashboard": {
        this.navigator.pushDashboardPage({
          user: this.user,
          target: "main",
          replace: false,
          client: this.client,
          data: this.data,
        });
        break;
      }

      case "Leads": {
        this.navigator.pushLeadManagementPage({
          user: this.user,
          target: "main",
          client: this.client,
          replace: false,
          data: this.data,
        });
        break;
      }

      case "Team": {
        this.navigator.pushNewTeamPage({
          user: this.user,
          target: "main",
          replace: false,
          client: this.client,
          data: this.data,
        });
        break;
      }

      case "Search Leads": {
        this.navigator.pushApolloLeadsPage({
          user: this.user,
          target: "main",
          replace: false,
        });
        break;
      }

      case "Connections": {
        this.navigator.pushConnectionsPage({
          user: this.user,
          target: "main",
          replace: false,
          client: this.client,
          data: this.data,
        });
        break;
      }

      case "Settings": {
        this.navigator.pushSettingsPage({
          target: "main",
          replace: false,
          company: this.user.company,
        });
        break;
      }

      case "Home": {
        this.navigator.pushSalesDashboardPage({
          user: this.user,
          target: "main",
          replace: false,
          client: this.client,
          data: this.data,
        });
        break;
      }

      case "Mails": {
        this.navigator.pushMailsPage({ target: "main", replace: false });
        break;
      }
      default: {
      }
    }
  };
  public deviceConnect = async (): Promise<void> => {
    this.client.data.setConnecting(true);

    if (!this.client.data.deviceReady) {
      let token: string = await RPCServices.getCallService().getAccessToken(
        this.user
      );

      this.client.setAccessToken(token);

      this.client.initialize({
        onSuccess: (s) => {
          this.onSuccessMsg(s);

          this.client.data.setConnecting(false);
        },
        onFailure: (m, error) => {
          this.onFailureMsg(m, error);

          this.client.data.setConnecting(false);
        },
        onIncomingCall: (call) => {
          this.onIncomingCallRecived(call);
        },
      });
    } else {
      this.client.disconnect();

      this.client.data.setConnecting(false);
    }
  };
  public onDeviceConnect = (): void => {
    if (this.user.twilioNumber === null || this.user.twilioNumber.isEmpty) {
      EventBus.get().fire(
        new FailureMessage({
          message:
            "You do not have a Twilio Number. Please contact your administrator.",
        })
      );

      return;
    }

    this.deviceConnect();
  };
  public onSuccessMsg = (s: string): void => {};
  public onFailureMsg = (m: string, error: string): void => {};
  public onCallInteraction = (callInteraction: CallInteraction): void => {
    this.setInteraction(callInteraction);

    this.showCallProgressPopup({ autoClose: false, float: true });
  };
  public onCloseViewCallView = (): void => {
    this.hideCallProgressPopup();
  };
  public onMinimizeViewCallView = (): void => {
    this.setEnableHeaderCallProgress(true);

    this.hideCallProgressPopup();
  };
  public onMaximizeViewCallView = (): void => {
    if (this.enableHeaderCallProgress) {
      this.showCallProgressPopup({ autoClose: false, float: true });

      this.setEnableHeaderCallProgress(false);
    }
  };
  public onIncomingCallRecived = async (call: TwilioCall): Promise<void> => {
    this.setActiveCall(call);

    let interactions: Array<Interaction> = (
      await Query.get().getInteractions(
        UsageConstants.QUERY_GETINTERACTIONS_ROUTEPAGE_EVENTHANDLERS_ONINCOMINGCALLRECIVED_BLOCK,
        new InteractionsRequest({ sid: call.callSid })
      )
    ).items;

    this.setData(
      new CallData({
        deviceReady: true,
        callSid: call.callSid,
        callStatus: CallStatus.Ringing,
        from: call.from,
        to: call.to,
      })
    );

    this.client.data = this.data;

    this.setInteraction(new CallInteraction({ data: this.data }));

    if (interactions.isNotEmpty) {
      this.interaction.setInteraction(interactions.first);

      this.interaction.setLead(interactions.first.lead);
    }

    //  check the microphone to be enabled

    let hasMicPermission: boolean = await this.client.checkMicPermission();

    if (!hasMicPermission) {
      EventBus.get().fire(
        new FailureMessage({
          message: "Please enable microphone to receive calls.",
        })
      );

      return;
    }

    this.showIncommingCallView({ autoClose: false, float: true });
  };
  public onAcceptIncommingCall = (): void => {
    if (this.activeCall !== null) {
      this.hideIncommingCallView();

      this.client.acceptIncomingCall(this.activeCall, {
        onStatus: (s) => {
          this.showCallProgressPopup({ autoClose: false, float: true });

          this.onSuccessMsg(s);
        },
        onFailure: (m, error) => {
          this.onFailureMsg(m, error);
        },
      });
    }
  };
  public onRejectIncommingCall = (): void => {
    if (this.activeCall !== null) {
      this.client.rejectIncomingCall(this.activeCall);

      this.hideIncommingCallView();
    }
  };
  public onCloseViewInCommingCallView = (): void => {
    this.hideIncommingCallView();
  };
  public dispose(): void {
    this.userDisposable?.dispose();

    this._successMessageSubscription.unsubscribe();

    this._failureMessageSubscription.unsubscribe();

    this._infoMessageSubscription.unsubscribe();

    this._callInteractionSubscription.unsubscribe();

    this.successMessagePopup?.dispose();

    this.failureMessagePopup?.dispose();

    this.infoMessageViewPopup?.dispose();

    this.callProgressPopupPopup?.dispose();

    this.incommingCallViewPopup?.dispose();

    super.dispose();
  }
  public showSuccessMessage(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.successMessagePopup?.dispose();

    let target: ui.Rect = this.containerPopupTargetController.getTarget(
      this.context
    );

    this.successMessagePopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Top,
      child: ui.Container({
        height: 50,
        margin: ui.EdgeInsets.fromLTRB(15.0, 90.0, 15.0, 0.0, new Map()),
        child: SuccessMessageView({
          msg: this.message,
          onClosePressed: () => {
            this.onCancelPressed();
          },
        }),
        className: "x067 hc vc",
      }),
      target: target,
    });

    this.successMessagePopup.showPopup(this.context);
  }
  public showFailureMessage(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.failureMessagePopup?.dispose();

    let target: ui.Rect = this.containerPopupTargetController.getTarget(
      this.context
    );

    this.failureMessagePopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Top,
      child: ui.Container({
        height: 50,
        margin: ui.EdgeInsets.fromLTRB(15.0, 90.0, 15.0, 0.0, new Map()),
        child: FailureMessageView({
          msg: this.message,
          onClosePressed: () => {
            this.onCancelPressed2();
          },
        }),
        className: "x75b hc vc",
      }),
      target: target,
    });

    this.failureMessagePopup.showPopup(this.context);
  }
  public showInfoMessageView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.infoMessageViewPopup?.dispose();

    let target: ui.Rect = this.containerPopupTargetController.getTarget(
      this.context
    );

    this.infoMessageViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Top,
      child: ui.Container({
        height: 50,
        margin: ui.EdgeInsets.fromLTRB(15.0, 90.0, 15.0, 0.0, new Map()),
        child: InfoMessageView({
          message: this.message,
          onClosePressed: () => {
            this.onCancelPressed3();
          },
        }),
        className: "xea1 hc vc",
      }),
      target: target,
    });

    this.infoMessageViewPopup.showPopup(this.context);
  }
  public showCallProgressPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.callProgressPopupPopup?.dispose();

    this.callProgressPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        height: 250,
        width: 350,
        child: CallProgressView({
          client: this.client,
          callInteraction: this.interaction,
          interaction: this.interaction.interaction,
          onCloseView: () => {
            this.onCloseViewCallView();
          },
          onMinimizeView: () => {
            this.onMinimizeViewCallView();
          },
        }),
        className: "x58a8 hc vc",
      }),
    });

    this.callProgressPopupPopup.showPopup(this.context);
  }
  public showIncommingCallView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.incommingCallViewPopup?.dispose();

    this.incommingCallViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        height: 270,
        width: 350,
        child: IncomingCallView({
          from: this.data.from,
          callInteraction: this.interaction,
          onAccept: () => {
            this.onAcceptIncommingCall();
          },
          onReject: () => {
            this.onRejectIncommingCall();
          },
          onCloseView: () => {
            this.onCloseViewInCommingCallView();
          },
        }),
        className: "xc3a hc vc",
      }),
    });

    this.incommingCallViewPopup.showPopup(this.context);
  }
  public hideSuccessMessage(): void {
    this.successMessagePopup?.dispose();
  }
  public hideFailureMessage(): void {
    this.failureMessagePopup?.dispose();
  }
  public hideInfoMessageView(): void {
    this.infoMessageViewPopup?.dispose();
  }
  public hideCallProgressPopup(): void {
    this.callProgressPopupPopup?.dispose();
  }
  public hideIncommingCallView(): void {
    this.incommingCallViewPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RoutePage(props: RoutePageProps) {
  return React.createElement(_RoutePageState, {
    ..._RoutePageState.defaultProps,
    ...props,
  });
}
